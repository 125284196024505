import Tag from "../../models/tag";
import { selectConfig } from "../../store/configSlice";
import { useAppSelector } from "../../store/hooks";

type TagBadgeProps = {
  tagId: number;
  parentTagId?: number;
}

export function TagLabel(props: TagBadgeProps) {
  const tags = useAppSelector(selectConfig).tags;
  const tag = tags?.find(t => t.id === props.tagId);
  // const parentTag = props.parentTagId !== undefined
  //   ? tags?.find(t => t.id === props.parentTagId)
  //   : undefined;
  return <>
    {tag?.parentTagId && <TagLabel tagId={tag.parentTagId} />}
    {tag?.parentTagId && <i className="fa-solid fa-chevrons-right mx-2"></i>}
    {tag?.label}
  </>;
}

export function TagBadge(props: TagBadgeProps) {
  return <div className="">
    <TagLabel tagId={props.tagId} />
  </div>
}