import { Link } from "react-router-dom";
import useLabel from "../util/useLabel";
import RecordsList, { RecordsListParams } from "../components/records/recordsList";
import { CustomizableEntity } from "../models/types";
import { useEffect } from "react";

export default function RecordsListScreen<TEntity extends CustomizableEntity>(props: RecordsListParams<TEntity>) {
  const entityType = props.type;
  const label = useLabel(entityType);
  
  useEffect(() => {
    document.title = label?.plural ?? "";
  }, [entityType, label?.plural]);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item"><a href="#/">{label?.plural}</a></li>
          <li className="breadcrumb-item active">List</li>
        </ul>
        <h1 className="page-header mb-0">{label?.plural}</h1>
      </div>
      {(props.canCreate ?? true) && <div className="ms-auto">
        <Link to={'create'} className="btn btn-success btn-rounded px-4 rounded-pill"><i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Create {label?.singular}</Link>
      </div>
      }
    </div>
    <RecordsList
      type={props.type}
      nameLabel={props.nameLabel}
      detailsRoute={props.detailsRoute}
      editRoute={props.editRoute}
      filters={props.filters}
      actions={props.actions}
      showName={props.showName}
      sort={props.sort}
      columns={props.columns}
    />
  </div>);
}