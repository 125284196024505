import AttributeValue from "./attributeValue";
import GradeUnit from "./gradeUnit";
import KnowledgeMapTypes from "./knowledgeMapTypes";
import Partner from "./partner";
import ProjectTag from "./projectTag";
import { CustomizableEntity } from "./types";

export default interface Project extends CustomizableEntity {
  id?: number;
  partnerId?: number;
  partner?: Partner;
  name?: string;
  knowledgeMapType?: KnowledgeMapTypes;
  unitEvaluations?: boolean;
  attributes?: AttributeValue[];
  isActive?: boolean;
  gradeUnits?: GradeUnit[];
  tags?: ProjectTag[];
}

export function ProjectFromApi(api: Project): Project {
  return {
    ...api,
    // startDate: parseDate(api.startDate),
    // executionDate: parseDate(api.executionDate),
    // rentCommencementDate: parseDate(api.rentCommencementDate),
    // originalTermEndDate: parseDate(api.originalTermEndDate),
    // terminationDate: parseDate(api.terminationDate),
  };
}