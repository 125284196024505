import { useAppSelector } from "../../store/hooks";

export function UserProfile() {
	const session = useAppSelector(state => state.session);

	return (<div>
		<ol className="breadcrumb float-xl-end">
			<li className="breadcrumb-item">User</li>
			<li className="breadcrumb-item">Profile</li>
			<li className="breadcrumb-item active">Edit</li>
		</ol>
		<h1 className="page-header">Edit Profile</h1>
		<div className="table-responsive form-inline profile-content">
			<table className="table table-profile align-middle">
				<thead>
					<tr>
						<th></th>
						<th>
							<h4>{session.fullName} <small>Role(s)</small></h4>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr className="border-top border-1">
						<td className="field">Username</td>
						<td>
							{session.userName}
						</td>
					</tr>
					<tr>
						<td className="field">First Name</td>
						<td>
							{session.firstName}
							<a href="#/" className="text-decoration-none fw-bold"><i className="fa fa-plus fa-fw"></i> Add First Name</a>
						</td>
					</tr>
					<tr className="border-bottom border-1">
						<td className="field">Last Name</td>
						<td>
							{session.lastName}
							<a href="#/" className="text-decoration-none fw-bold"><i className="fa fa-plus fa-fw"></i> Add Last Name</a>
						</td>
					</tr>

					<tr className="divider">
						<td colSpan={2}></td>
					</tr>
					<tr>
						<td className="field">Email</td>
						<td>
							{session.email && <i className="fa fa-envelope fa-lg me-5px"></i>} {session.email}
							<a href="#/" className="ms-5px text-decoration-none fw-bold"><i className="fa fa-plus fa-fw"></i> Edit</a>
						</td>
					</tr>
					<tr>
						<td className="field">Mobile</td>
						<td>
							{session.phoneNumber && <i className="fa fa-mobile fa-lg me-5px"></i>} {session.phoneNumber}
							<a href="#/" className="ms-5px text-decoration-none fw-bold"><i className="fa fa-plus fa-fw"></i> Edit</a>
						</td>
					</tr>
					<tr className="divider">
						<td colSpan={2}></td>
					</tr>
					<tr>
						<td className="field">Timezone</td>
						<td>
							<select className="form-select w-200px" name="region">
								<option value={session.timeZone}>{session.timeZone}</option>
							</select>
						</td>
					</tr>
					<tr className="divider">
						<td colSpan={2}></td>
					</tr>
					<tr className="highlight">
						<td className="field">&nbsp;</td>
						<td className="">
							<button type="submit" className="btn btn-primary w-150px">Update</button>
							<button type="submit" className="btn border-0 w-150px ms-5px">Cancel</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>);
}