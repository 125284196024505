import { useAppSelector } from "../../store/hooks";
import HeatmapReportScreen from "../reports/heatMapScreen";

export default function SharedProjectScreen() {
  const session = useAppSelector(state => state.session);
  return (<div>
    <p>
      Select a shared report from the menu to the left.
    </p>
  </div>);
}
