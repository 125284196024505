import { useEffect, useState } from "react";
import { Link, generatePath, useLocation, useNavigate, useParams } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import { SearchProps, api } from "../../store/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import EntityTypeLabel from "../../components/label/entityTypeLabel";
import RecordsList from "../../components/records/recordsList";
import Review from "../../models/review";
import { setTitle } from "../../util/useDocumentTitle";
import User from "../../models/user";
import { UsersEditRoute } from "./userEditScreen";
import { ProjectDetailsRoute } from "../projects/projectDetailsScreen";
import { ResourceDetailsRoute } from "../resources/resourceDetailsScreen";
import { format } from "../../helpers/format";
import { ReviewDetailsRoute } from "../reviews/reviewDetailsScreen";

export const UserDetailsRoute = "/settings/users/:id/details";

interface UserDetailsRouteParams {
  id?: string;
}

export default function UserDetailsScreen() {
  const navigate = useNavigate();
  const labels = useAppSelector(selectLabels);
  const label = labels.user;
  const editRoute = UsersEditRoute;
  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState<User>();
  const [error, setError] = useState<String>();
  //const [tab, setTab] = useState<EntityTypes>(EntityTypes.Project);
  const params = useParams<keyof UserDetailsRouteParams>();
  const location = useLocation();
  
  const tab = (): EntityTypes => {
    switch(location.hash) {
      case '#review':
        return EntityTypes.Review;
      case '#project':
      default:
        return EntityTypes.Project;
    }
  };

  
  useEffect(() => {
    // Load the attribute and set the form with the current values
    setIsLoading(true);

    api.users.get(params.id!)
      .then((record) => {
        setRecord(record);
        setTitle([`${label.singular} Details`, record.name!]);
      })
      .catch((reason) => {
        console.error(reason);
        setError("Unable to load " + label.singular + ` #${params.id}`);
      });
  }, [label.singular, params.id]);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">{record?.fullName()}</h1>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="..">{label.plural}</Link></li>
          <li className="breadcrumb-item active">Details</li>
        </ol>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      <div className="col-md-4">
        {record && <div className="card border-0 mb-4">
          <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">
            {label.singular} Details
            {editRoute && <Link to={generatePath(editRoute, { id: `${record?.id}` })} className="ms-auto text-decoration-none text-gray-500"><i className="fa fa-edit fa-lg me-2 ms-n2"></i> Edit</Link>}
          </div>
          <div className="card-body fw-bold">
            {record?.email && <h6 className="card-subtitle text-muted">Email</h6>}
            {record?.email && <p className="card-text">
              {record?.email}
            </p>}
            {record?.roles && <h6 className="card-subtitle text-muted">Role(s)</h6>}
            {record?.roles && <p className="card-text">
              {record?.roles.join(", ")}
            </p>}
          </div>
        </div>}
      </div>
      <div className="col-md-8">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a className={"nav-link " + (tab() === EntityTypes.Project ? "active" : "")} aria-current="page" href="#project">{labels.project.plural}</a>
          </li>
          <li className="nav-item">
            <a className={"nav-link " + (tab() === EntityTypes.Review ? "active" : "")} href="#review"><EntityTypeLabel entityType={EntityTypes.Review} /></a>
          </li>
        </ul>
        {tab() === EntityTypes.Project && record &&
          <div className="card border-0 mb-4">
            {record?.id && <RecordsList
              
              type={EntityTypes.Project}
              filters={
                [
                  { key: 'all', label: 'All', api: (search: SearchProps) => api.projects.forUser({ ...search, userId: record.id!, }) },
                ]}
              actions={(record: Review) => <>
                <button className="btn btn-sm btn-outline-primary mx-1" onClick={() => navigate(generatePath(ProjectDetailsRoute, { id: `${record.id}` }))}>Details</button>
              </>}
            />}
          </div>}
        {tab() === EntityTypes.Review && record &&
          <div className="card border-0 mb-4">
            {record?.id && <RecordsList
              showName={false}
              type={EntityTypes.Review}
              filters={
                [
                  { key: 'all', label: 'All', api: (search: SearchProps) => api.reviews.forUser({ ...search, userId: record.id!, }) },
                ]}
              columns={[
                {
                  label: labels.project.singular,
                  callback: (record: Review) => <td><Link to={generatePath(ProjectDetailsRoute, { id: `${record.projectId}` })}>{record?.projectName}</Link></td>
                },
                {
                  label: labels.resource.singular,
                  callback: (record: Review) => <td><Link to={generatePath(ResourceDetailsRoute, { id: `${record.resourceId}` })}>{record?.resourceName}</Link></td>
                },
                // {
                //   label: labels.reviewer.singular,
                //   sortKey: 'user',
                //   callback: (record) => <td>{record.userName}</td>,
                // },
                {
                  label: 'Created',
                  sortKey: 'created',
                  callback: (record) => <td>{format.ago(record.createdUtc!)}</td>,
                },
              ]}
              actions={(record: Review) => <>
                <button className="btn btn-sm btn-outline-primary mx-1" onClick={() => navigate(generatePath(ReviewDetailsRoute, { id: `${record.id}` }))}>Details</button>
              </>}
            />}
          </div>}
      </div>
    </div>
  </div>);
}
