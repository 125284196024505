import { useEffect, useState } from "react";
import { Link, generatePath, useParams } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import { api } from "../../store/api";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import RecordDetailsCard from "../../components/records/recordDetailsCard";
import { ProjectDetailsRoute } from "../projects/projectDetailsScreen";
import { UnitEvaluationEditRoute } from "./unitEvaluationEditScreen";
import Tag from "../../models/tag";
import UnitEvaluation from "../../models/unitEvaluation";
import ProjectReviewer from "../../models/projectReviewer";
import Project from "../../models/project";
import { UnitEvaluationsRoute } from "./unitEvaluationsScreen";
import GradeUnit from "../../models/gradeUnit";
import GradeTypes from "../../models/gradeTypes";

export const UnitEvaluationsDetailsRoute = "/unitEvaluations/:id";

interface UnitEvaluationsDetailsRouteParams {
  id?: string;
}

export default function UnitEvaluationsDetailsScreen() {
  const labels = useAppSelector(selectLabels);
  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState<UnitEvaluation>();
  const [gradeUnit, setGradeUnit] = useState<GradeUnit>();
  const [project, setProject] = useState<Project>();
  const [projectReviewer, setProjectReviewer] = useState<ProjectReviewer>();
  const [error, setError] = useState<String>();
  const params = useParams<keyof UnitEvaluationsDetailsRouteParams>();
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);


  useEffect(() => {
    if (gradeUnit?.projectId) {
      api.projects.get(gradeUnit?.projectId).then(setProject);
    }
  }, [gradeUnit?.projectId]);

  useEffect(() => {
    if (record?.gradeUnitId) {
      api.gradeUnit.get(record?.gradeUnitId).then(setGradeUnit);
    }
  }, [record?.gradeUnitId]);
  
  useEffect(() => {
    // Load the attribute and set the form with the current values
    setIsLoading(true);

    api.unitEvaluations.get(parseInt(params.id!))
      .then((record) => {
        setRecord(record);
        setIsLoading(false);
        // fetch resource details
        //api.reviewers.get(record.projectReviewerId!).then(setProjectReviewer);
      })
      .catch((reason) => {
        console.error(reason);
        setError("Unable to load " + labels.review.singular + ` #${params.id}`);
      });
  }, [labels.review.singular, params.id]);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">
          {labels.unitevaluation.singular}
          Grade {labels.grades[gradeUnit?.grade ?? GradeTypes.NA]}
          Unit {gradeUnit?.unit}
        </h1>
        <ul className="breadcrumb">
          {project?.id && <li className="breadcrumb-item" title={labels.project.singular}><Link to={generatePath(ProjectDetailsRoute, { id: `${project?.id}` })}>{project?.name}</Link></li>}
          <li className="breadcrumb-item"><Link to={generatePath(UnitEvaluationsRoute)}>{labels.unitevaluation.plural}</Link></li>
          <li className="breadcrumb-item">Grade {labels.grades[gradeUnit?.grade ?? GradeTypes.NA]} Unit {gradeUnit?.unit}</li>
          {/* {record?.projectReviewer?.name && <li className="breadcrumb-item">{record.projectReviewer.name}</li>}
          {record?.projectReviewer?.grade  && <li className="breadcrumb-item">Grade {labels.grades[record?.projectReviewer?.grade]}</li>} */}
          <li className="breadcrumb-item active">Details</li>
        </ul>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      <div className="col-md-4">
        <div className="card border-0 mb-4">
          <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">
            {labels.unitevaluation.singular} Summary
            <Link to={generatePath(UnitEvaluationEditRoute, { id: `${record?.id}` }) + '#review'} className="ms-auto text-decoration-none text-gray-500"><i className="fa fa-edit fa-lg me-2 ms-n2"></i> Edit</Link>
          </div>
          <div className="card-body fw-bold">
            <h6 className="card-subtitle text-muted">{labels.reviewer.singular}</h6>
            <p className="card-text">
              {record?.projectReviewer?.name}
            </p>
            <h6 className="card-subtitle text-muted">{labels.project.singular}</h6>
            <p className="card-text">
              <Link to={generatePath(ProjectDetailsRoute, { id: `${project?.id}` })}>{project?.name}</Link>
            </p>
            <h6 className="card-subtitle text-muted">Grade</h6>
            <p className="card-text">
              {labels.grades[gradeUnit?.grade ?? GradeTypes.NA]}
            </p>
            <h6 className="card-subtitle text-muted">Unit</h6>
            <p className="card-text">
              {gradeUnit?.unit}
            </p>
            
          </div>
        </div>
{/*         
        {resource && <RecordDetailsCard
          record={resource}
          detailsRoute={ResourceDetailsRoute}
          type={EntityTypes.Resource}
        />} */}
      </div>
      <div className="col-md-8">
        {record && <RecordDetailsCard
          editRoute={generatePath(UnitEvaluationEditRoute, { id: `${record?.id}` }) + '#unitEvaluation'}
          record={record}
          attributeValues={[
            ...record?.attributes ?? [],
          ]}
          type={EntityTypes.UnitEvaluation}
          colClassName="col-md-6"
        />}
      </div>
    </div>
  </div>);
}
