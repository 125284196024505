import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useRef, useState } from "react";
import { api } from "../../store/api";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { NewReferenceEntity } from "../reference/referenceDropdown";
import Resource from "../../models/resource";
import QuickViewModal from "../modal/quickViewModal";
import EntityTypes from "../../models/entityTypes";
import QuickAddModal from "../modal/quickAddModal";

type AddTranslationProps = {
  resource: Resource;
  onAdded?: (e: Resource) => void;
}

export default function AddTranslation(props: AddTranslationProps) {
  const labels = useAppSelector(selectLabels);
  const label = labels.projectResource;

  const [isEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<String>();
  const [resources, setResources] = useState<Resource[]>([]);
  const [selectedResource, setSelectedResource] = useState<Resource[]>([]);
  const [newOption, setNewOption] = useState<NewReferenceEntity>();
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [showViewResource, setShowViewResource] = useState<boolean>(false);

  const fetchRecord = async (): Promise<Resource> => new Promise<Resource>((resolve) => {
    resolve({
    });
  });

  const form = useForm<Resource>({
    defaultValues: fetchRecord,
  });

  const { register, handleSubmit, reset, formState: { errors }, setValue } = form;

  const onSubmit: SubmitHandler<Resource> = data => {
    console.log(data);
    setError(undefined);
    const request: Resource = {
      ...data,
      //projectId: props.project.id,
    };
    console.log(request);
    setIsSaving(true);
    api.resources.translations(props.resource).create(request)
      .then((record) => {
        // clear form
        reset();
        setIsSaving(false);
        if (props.onAdded) {
          props.onAdded(record);
        }
      })
      .catch((reason) => {
        setError("Unable to create/update " + label.singular + " translation: " + reason);
        setIsSaving(false);
      });
  };

  // useEffect(() => {
  //   setValue('grade', props.selectedGrade);
  // }, [props.selectedGrade, setValue]);

  return <FormProvider {...form}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row align-items-end">
        <div className="col">
          <label className="form-label">Translated {labels.resource.singular}</label>
          <div className="d-flex flex-row">
            <AsyncTypeahead
              className="flex-grow-1"
              options={resources}
              id="resourceId"
              useCache={false}
              labelKey={"name"}
              filterBy={['name']}
              allowNew={true}
              newSelectionPrefix="Create new: "
              selected={selectedResource}
              multiple={false}
              minLength={0}
              clearButton={true}
              delay={600}
              isLoading={isLoading}
              {...register(`id` as const, { required: true })}
              onChange={(selected) => {
                const selectedEntities = selected as Resource[];
                console.log('onChange', selected);
                if (selectedEntities.length > 0) {
                  const asCustomOption = selectedEntities[selectedEntities.length - 1] as NewReferenceEntity;
                  if (asCustomOption.customOption) {
                    console.warn('customOption', selectedEntities[selectedEntities.length - 1]);
                    setNewOption(asCustomOption);
                    setShowAddNew(true);
                    return;
                  }
                  // const selectedIds = selectedEntities!.map(s => s.id ?? '')
                  setSelectedResource(selectedEntities);
                  setValue('id', selectedEntities[0].id);
                  // field.onChange(selectedEntities);
                }
                else {
                  setSelectedResource([]);
                  setValue('id', undefined);
                  // field.onChange();
                }
              }
              }
              onSearch={function (search: string): void {
                setIsLoading(true);
                api.resources.search({ search }).then((results) => {
                  setResources(results.records);
                  setIsLoading(false);
                })
                  .catch((reason) => {
                    throw new Error(`Error searching for ${labels.resource.singular} : ${reason}`);
                  });
              }}
            />
            {selectedResource?.length === 1 && <button type="button" className="btn btn-sm" onClick={() => setShowViewResource(() => true)}><i className="fa fa-eye"></i></button>}
            <button type="button" className="btn btn-sm" onClick={() => setShowAddNew(() => true)}><i className="fa fa-plus"></i></button>
          </div>
          {selectedResource?.length === 1 && <QuickViewModal
            record={selectedResource[0]}
            entityType={EntityTypes.Resource}
            show={showViewResource}
            onCancel={() => setShowViewResource(false)}
          />}

          {errors.id && <div className="invalid-feedback">This field is required</div>}
        </div>
        {/* <div className="col-auto">
          <label className="form-label">Grade</label>
          <select className={"form-control " + (errors.grade ? 'is-invalid' : '')} {...register("grade", { required: true, valueAsNumber: true })}>
            <option></option>
            {props.grades.map((grade) => <option key={`grade-${grade}`} value={grade}>{labels.grades[grade]}</option>)}
          </select>
          {errors.grade && <div className="invalid-feedback">This field is required</div>}
        </div> */}
        <div className="col-auto">
          <button type="submit" className="btn btn-success" disabled={isSaving || isEditing || selectedResource.length === 0}>{isSaving && (
            <span className="spinner-border spinner-border-sm me-1"></span>
          )} Add Translation</button>
        </div>
      </div>
      {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    </form>
    <QuickAddModal
      show={showAddNew}
      entityType={EntityTypes.Resource}
      onCancel={() => setShowAddNew(false)}
      onSuccess={(added: Resource) => {
        console.warn('QuickAddModal.onSuccess', added);
        if (added) {
          debugger;
          setResources([added]);
          setSelectedResource([added]);
          setValue('id', added.id);

          if (addButtonRef.current) {
            addButtonRef.current.focus();
          }
        }
        setShowAddNew(false);
      }}
      initialData={newOption as Resource}
    />
  </FormProvider>;
}