import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import ProjectReviewer from "../../models/projectReviewer";
import { useEffect, useState } from "react";
import { api } from "../../store/api";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import User from "../../models/user";
import GradeTypes from "../../models/gradeTypes";
import Project from "../../models/project";
import { NewReferenceEntity } from "../reference/referenceDropdown";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { UsersCreateRoute } from "../../screens/settings/userEditScreen";

type AddProjectReviewerProps = {
  project: Project;
  grades: GradeTypes[];
  selectedGrade?: GradeTypes;
  onAdded?: (e: ProjectReviewer) => void;
}

export default function AddProjectReviewer(props: AddProjectReviewerProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const labels = useAppSelector(selectLabels);
  const label = labels.projectResource;

  const [isEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<String>();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User[]>([]);

  const fetchRecord = async (): Promise<ProjectReviewer> => new Promise<ProjectReviewer>((resolve) => {
    resolve({
    });
  });

  const form = useForm<ProjectReviewer>({
    defaultValues: fetchRecord,
  });

  const { register, handleSubmit, reset, formState: { errors }, setValue } = form;

  const onSubmit: SubmitHandler<ProjectReviewer> = data => {
    console.log(data);
    setError(undefined);
    const request: ProjectReviewer = {
      ...data,
      projectId: props.project.id,
    };
    console.log(request);
    setIsSaving(true);
    (isEditing ? api.reviewers.update(request) : api.reviewers.create(request))
      .then((record) => {
        // clear form
        reset();
        // reset({
        //   grade: data.grade,
        //   unit: data.unit,
        // });
        setIsSaving(false);
        if (props.onAdded) {
          props.onAdded(record);
        }
      })
      .catch((reason) => {
        setError("Unable to create/update " + label.singular + ": " + reason);
        setIsSaving(false);
      });
  };

  useEffect(() => {
    setValue('grade', props.selectedGrade);
  }, [props.selectedGrade, setValue]);

  return <FormProvider {...form}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row align-items-end">
        <div className="col">
          <label className="form-label">{labels.reviewer.singular} to Assign</label>
          <div className="d-flex flex-row">
            <AsyncTypeahead
              className="flex-grow-1"
              options={users}
              id="resourceId"
              useCache={false}
              labelKey={"name"}
              filterBy={['name']}
              allowNew={true}
              newSelectionPrefix="Invite new reviewer: "
              selected={selectedUser}
              multiple={false}
              minLength={0}
              delay={600}
              clearButton={true}
              isLoading={isLoading}
              {...register(`userId` as const, { required: true })}
              onChange={(selected) => {
                if (selected) {
                  setError(undefined);
                  const asCustomOption = selected[selected.length - 1] as NewReferenceEntity;
                  if (asCustomOption?.customOption) {
                    console.warn('customOption', selected[selected.length - 1]);
                    // TODO consider a qucik-Add User screen
                    navigate(generatePath(UsersCreateRoute), { state: {
                      returnUrl: location.pathname,
                    }});
                    return;
                  }
                }

                const selectedEntities = selected as User[];
                console.log('onChange', selected);
                if (selectedEntities.length > 0) {
                  // const selectedIds = selectedEntities!.map(s => s.id ?? '')
                  setSelectedUser(selectedEntities);
                  setValue('userId', selectedEntities[0].id);
                  // field.onChange(selectedEntities);
                }
                else {
                  setSelectedUser([]);
                  setValue('userId', undefined);
                  // field.onChange();
                }
              }
              }
              onSearch={function (search: string): void {
                setIsLoading(true);
                api.users.search({ search }).then((results) => {
                  setUsers(results.records);
                  setIsLoading(false);
                })
                  .catch((reason) => {
                    throw new Error(`Error searching for ${labels.reviewer.singular} : ${reason}`);
                  });
              }}
            />
            {/* {selectedResource?.length === 1 && <button type="button" className="btn btn-sm" onClick={(e) => setShowViewResource((val) => true)}><i className="fa fa-eye"></i></button>} */}
            {errors.userId && <div className="invalid-feedback">This field is required</div>}
          </div>
          {/* {selectedResource?.length === 1 && <QuickViewModal
                record={selectedResource[0]}
                entityType={EntityTypes.Resource}
                show={showViewResource}
                onCancel={() => setShowViewResource(false)}
              />} */}
        </div>
        <div className="col-auto">
          <label className="form-label">Grade</label>
          <select className={"form-control " + (errors.grade ? 'is-invalid' : '')} {...register("grade", { required: true, valueAsNumber: true })}>
            <option></option>
            {props.grades.map((grade) => <option key={`grade-${grade}`} value={grade}>{labels.grades[grade]}</option>)}
          </select>
          {errors.grade && <div className="invalid-feedback">This field is required</div>}
        </div>
        <div className="col-auto">
          <button type="submit" className="btn btn-success" disabled={isSaving || isEditing}>{isSaving && (
            <span className="spinner-border spinner-border-sm me-1"></span>
          )} Add {labels.reviewer.singular}</button>
        </div>
      </div>
      {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    </form>
  </FormProvider>;
}