import React, { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { api } from "../store/api";
import { useAppDispatch, useAppSelector } from "../store/hooks";
//import { identity, loginGoogle } from "../store/session/actions";
import { identity } from "../store/sessionSlice";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import LoginRequest from "../models/loginRequest";

export function Login() {
	const activeBg = '/img/login-bg/brendan-beale-PTG5LtXx9pY-unsplash.jpg';
	const session = useAppSelector(state => state.session);
	let [searchParams] = useSearchParams();
	const urlGoogleSignin = api.loginGoogleUrl(searchParams.get("return") ?? window.location.href);

	// const [inputs, setInputs] = useState({
	// 	email: '',
	// 	password: '',
	// });
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<String>();
	const [verificationSent, setVerificationSent] = useState<Date>();
	// const { email, password } = inputs;

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(identity());
	}, [dispatch]);

	const form = useForm<LoginRequest>();

	if (session.isLoggedIn && !session.loading) {
		return <Navigate to="/" />;
	}

	// reset login status
	//useEffect(() => {

	//	//dispatch(alertActions.clear());
	//	//if (loggedIn) {
	//	//	dispatch(logout());
	//	//}
	//}, []);

	// function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
	// 	const { name, value } = e.target;
	// 	if (isLoading) { return; }
	// 	setInputs((inputs) => ({ ...inputs, [name]: value }));
	// }

	// const login = (): boolean => {
	// 	if (!email) {
	// 		return false;
	// 	}
	// 	return true;
	// };

	// function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
	// 	e.preventDefault();
	// 	console.warn("handleSubmit")
	// 	setIsLoading(true);
	// 	if (login()) {

	// 	}
	// 	setIsLoading(false);
	// };


	const { register, handleSubmit, reset, formState: { errors }, control } = form;

	const requestLoginCode = (data: LoginRequest) => {
		setIsLoading(true);
		setError(undefined);

		// obtain verification code
		api.identity.loginCode(data)
			.then((response) => {
				setIsLoading(false);
				if (response.error) {
					setError(response.error);
				}
				if (response.sent) {
					setVerificationSent(response.sent);
				}
			})
			.catch((reason) => {
				setError("Unable to process login request: " + reason);
				setIsLoading(false);
			});
	}


	const verifyLoginCode = (data: LoginRequest) => {
		setIsLoading(true);
		setError(undefined);

		// obtain verification code
		api.identity.login(data)
			.then((response) => {
				if (response.error) {
					setIsLoading(false);
					setError(response.error);
				}
				if (response.success) {
					dispatch(identity());
				}
			})
			.catch((reason) => {
				setError("Unable to process login request: " + reason);
				setIsLoading(false);
			});
	}

	const onSubmit: SubmitHandler<LoginRequest> = data => {
		console.log(data);

		if (verificationSent !== undefined) {
			verifyLoginCode(data);
		}
		else {
			requestLoginCode(data);
		}
	};


	return (
		<React.Fragment>
			<div className="login login-v2 fw-bold">
				<div className="login-cover">
					<div className="login-cover-img" style={{ backgroundImage: 'url(' + activeBg + ')' }}></div>
					<div className="login-cover-bg"></div>
				</div>

				<div className="login-container">
					<div className="login-header">
						<div className="brand">
							<div className="d-flex align-items-center">
								<span className="logo"></span> <b>KnowledgeMaps</b> 2.0
							</div>
							<small>Johns Hopkins Institute for Education Policy</small>
						</div>
						<div className="icon">
							<i className="fa fa-lock"></i>
						</div>
					</div>

					<div className="login-content">
						<FormProvider {...form}>
							<form onSubmit={handleSubmit(onSubmit)}>
								{verificationSent === undefined && <div className="form-floating mb-20px">
									<input type="email" className={"form-control fs-13px h-45px border-0" + (errors.email ? 'is-invalid' : '')} placeholder="Email Address" autoComplete="email" {...register("email", { required: true })} />
									<label htmlFor="email" className="d-flex align-items-center text-gray-600 fs-13px">Email Address</label>
								</div>}
								{verificationSent !== undefined && <p>A verification code has been sent to you via email. Please check your email and click the link to login or supply the code below.</p>}
								{verificationSent !== undefined && <div className="form-floating mb-20px">
									<input type="string" className={"form-control fs-13px h-45px border-0" + (errors.verificationCode ? 'is-invalid' : '')} placeholder="verification code" {...register("verificationCode", { required: true })} />
									<label htmlFor="verificationCode" className="d-flex align-items-center text-gray-600 fs-13px">Verification Code</label>
								</div>}
								{error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
								<div className="mb-20px">
									<button type="submit" className="btn btn-success d-block w-100 h-45px btn-lg" disabled={isLoading}>
										{isLoading && (
											<span className="spinner-border spinner-border-sm me-1"></span>
										)}
										Sign in
									</button>
								</div>
								{/*<div className="text-gray-500">*/}
								{/*	Not a member yet? Click <Link to="/user/register-v3">here</Link> to register.*/}
								{/*</div>*/}
							</form>
						</FormProvider>
						<hr className="bg-gray-600 opacity-2" />
						<div className="mb-20px">
							<form method="post" action={urlGoogleSignin}>
								<button type="submit" className="btn bg-black btn-outline-success d-block w-100 h-45px btn-lg" disabled={isLoading}>
									<svg fill="currentColor" className="me-2" width="16" height="16" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24"><path d="M12.48 10.92v3.28h7.84c-.24 1.84-.853 3.187-1.787 4.133-1.147 1.147-2.933 2.4-6.053 2.4-4.827 0-8.6-3.893-8.6-8.72s3.773-8.72 8.6-8.72c2.6 0 4.507 1.027 5.907 2.347l2.307-2.307C18.747 1.44 16.133 0 12.48 0 5.867 0 .307 5.387.307 12s5.56 12 12.173 12c3.573 0 6.267-1.173 8.373-3.36 2.16-2.16 2.84-5.213 2.84-7.667 0-.76-.053-1.467-.173-2.053H12.48z"></path></svg>
									Sign in with Google
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>

			{/*<div className="login-bg-list clearfix">*/}
			{/*	<div className={'login-bg-list-item ' + (this.state.bg1 ? 'active ' : '')}><Link to="/user/login-v2" onClick={(e) => this.selectBg(e, 'bg1', '/assets/img/login-bg/login-bg-17.jpg')} style={{ backgroundImage: 'url(/assets/img/login-bg/login-bg-17.jpg)' }} className="login-bg-list-link"></Link></div>*/}
			{/*	<div className={'login-bg-list-item ' + (this.state.bg2 ? 'active ' : '')}><Link to="/user/login-v2" onClick={(e) => this.selectBg(e, 'bg2', '/assets/img/login-bg/login-bg-16.jpg')} style={{ backgroundImage: 'url(/assets/img/login-bg/login-bg-16.jpg)' }} className="login-bg-list-link"></Link></div>*/}
			{/*	<div className={'login-bg-list-item ' + (this.state.bg3 ? 'active ' : '')}><Link to="/user/login-v2" onClick={(e) => this.selectBg(e, 'bg3', '/assets/img/login-bg/login-bg-15.jpg')} style={{ backgroundImage: 'url(/assets/img/login-bg/login-bg-15.jpg)' }} className="login-bg-list-link"></Link></div>*/}
			{/*	<div className={'login-bg-list-item ' + (this.state.bg4 ? 'active ' : '')}><Link to="/user/login-v2" onClick={(e) => this.selectBg(e, 'bg4', '/assets/img/login-bg/login-bg-14.jpg')} style={{ backgroundImage: 'url(/assets/img/login-bg/login-bg-14.jpg)' }} className="login-bg-list-link"></Link></div>*/}
			{/*	<div className={'login-bg-list-item ' + (this.state.bg5 ? 'active ' : '')}><Link to="/user/login-v2" onClick={(e) => this.selectBg(e, 'bg5', '/assets/img/login-bg/login-bg-13.jpg')} style={{ backgroundImage: 'url(/assets/img/login-bg/login-bg-13.jpg)' }} className="login-bg-list-link"></Link></div>*/}
			{/*	<div className={'login-bg-list-item ' + (this.state.bg6 ? 'active ' : '')}><Link to="/user/login-v2" onClick={(e) => this.selectBg(e, 'bg6', '/assets/img/login-bg/login-bg-12.jpg')} style={{ backgroundImage: 'url(/assets/img/login-bg/login-bg-12.jpg)' }} className="login-bg-list-link"></Link></div>*/}
			{/*</div>*/}
		</React.Fragment>
	);
}