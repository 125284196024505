import AttributeValue from "./attributeValue";
import { CustomizableEntity } from "./types";

export interface IUser extends CustomizableEntity {
  id?: string;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  timeZone?: string;
  isActive: boolean;
  attributes?: AttributeValue[];
  roles?: string[];
  /// true if the user has chosen to create a new Customer using the AsyncTypeAhead control
  customOption?: boolean;
}

export default class User implements IUser {
  constructor(api?: IUser) {
    this.id = api?.id;
    this.email = api?.email ?? '';
    this.firstName = api?.firstName ?? '';
    this.lastName = api?.lastName ?? '';
    this.attributes = api?.attributes;
    this.customOption = api?.customOption;
    this.isActive = api?.isActive ?? true;
    this.name = `${this.firstName} ${this.lastName} (${this.email})`;
    this.timeZone = api?.timeZone;
    this.roles = api?.roles;
  }
  email: string;
  firstName: string;
  lastName: string;
  id?: string | undefined;
  isActive: boolean;
  attributes?: AttributeValue[] | undefined;
  customOption?: boolean | undefined;
  roles?: string[] | undefined;
  //description: string;
  name: string;
  timeZone?: string;
  fullName = (): string => `${this.firstName} ${this.lastName}`;

}
