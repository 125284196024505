import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { slideToggle } from '../composables/slideToggle';
//import { fetchCount } from './counterAPI';

export type Themes = 'red' | 'pink' | 'orange' | 'yellow' | 'lime' | 'green' | 'teal' | 'cyan' | 'blue' | 'purple' | 'indigo' | 'dark';
export interface ThemeState {
	appTheme: Themes;
	appDarkMode: boolean;
	appGradientEnabled: boolean;
	appHeaderNone: boolean;
	appHeaderFixed: boolean;
	appHeaderInverse: boolean;
	appHeaderMegaMenu: boolean;
	appHeaderLanguageBar: boolean;
	hasScroll: boolean;
	//handleSetAppHeaderNone: boolean;
	appSidebarNone: boolean;
	appSidebarWide: boolean;
	appSidebarLight: boolean;
	appSidebarMinify: boolean;
	appSidebarMobileToggled: boolean;
	appSidebarTransparent: boolean;
	appSidebarSearch: boolean;
	appSidebarFixed: boolean;
	appSidebarGrid: boolean;
	//handleSetAppSidebarNone: this.handleSetAppSidebarNone;
	//handleSetAppSidebarWide: this.handleSetAppSidebarWide;
	//handleSetAppSidebarLight: this.handleSetAppSidebarLight;
	//handleSetAppSidebarMinified: this.handleSetAppSidebarMinified;
	//handleSetAppSidebarTransparent: this.handleSetAppSidebarTransparent;
	//handleSetAppSidebarSearch: this.handleSetAppSidebarSearch;
	//handleSetAppSidebarFixed: this.handleSetAppSidebarFixed;
	//handleSetAppSidebarGrid: this.handleSetAppSidebarGrid;
	//toggleAppSidebarMinify: this.toggleAppSidebarMinify;
	//toggleAppSidebarMobile: this.toggleAppSidebarMobile;

	appContentNone: boolean;
	appContentClass: string;
	appContentFullHeight: boolean;
	//handleSetAppContentNone: this.handleSetAppContentNone;
	//handleSetAppContentClass: this.handleSetAppContentClass;
	//handleSetAppContentFullHeight: this.handleSetAppContentFullHeight;

	appTopMenu: boolean;
	appTopMenuMobileToggled: boolean;
	//toggleAppTopMenuMobile: this.toggleAppTopMenuMobile;
	//handleSetAppTopMenu: this.handleSetAppTopMenu;

	appSidebarTwo: boolean;
	//handleSetAppSidebarTwo: this.handleSetAppSidebarTwo;

	appSidebarEnd: boolean;
	appSidebarEndToggled: boolean;
	appSidebarEndMobileToggled: boolean;
	//toggleAppSidebarEnd: this.toggleAppSidebarEnd;
	//toggleAppSidebarEndMobile: this.toggleAppSidebarEndMobile;
	//handleSetAppSidebarEnd: this.handleSetAppSidebarEnd;

	//handleSetAppBoxedLayout: this.handleSetAppBoxedLayout;
	//handleSetAppDarkMode: this.handleSetAppDarkMode;
	//handleSetAppGradientEnabled: this.handleSetAppGradientEnabled;
	//handleSetAppTheme: this.handleSetAppTheme;

	//handleSetColor: this.handleSetColor;

	font: {
		family: string; // window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim();
		size: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-size').trim();
		weight: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim()
	};
	color: {
		componentColor: string; //window.getComputedStyle(document.body).getPropertyValue('--app-component-color').trim();
		componentBg: string; //window.getComputedStyle(document.body).getPropertyValue('--app-component-bg').trim();
		dark: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-dark').trim();
		light: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-light').trim();
		blue: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-blue').trim();
		indigo: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-indigo').trim();
		purple: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-purple').trim();
		pink: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-pink').trim();
		red: string; // window.getComputedStyle(document.body).getPropertyValue('--bs-red').trim();
		orange: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-orange').trim();
		yellow: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-yellow').trim();
		green: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-green').trim();
		success: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-success').trim();
		teal: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-teal').trim();
		cyan: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-cyan').trim();
		white: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-white').trim();
		gray: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray').trim();
		lime: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-lime').trim();
		gray100: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100').trim();
		gray200: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200').trim();
		gray300: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300').trim();
		gray400: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400').trim();
		gray500: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500').trim();
		gray600: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600').trim();
		gray700: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700').trim();
		gray800: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800').trim();
		gray900: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900').trim();
		black: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-black').trim();
		componentColorRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb').trim();
		componentBgRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--app-component-bg-rgb').trim();
		darkRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-dark-rgb').trim();
		lightRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-light-rgb').trim();
		blueRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-blue-rgb').trim();
		indigoRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-indigo-rgb').trim();
		purpleRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-purple-rgb').trim();
		pinkRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-pink-rgb').trim();
		redRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-red-rgb').trim();
		orangeRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-orange-rgb').trim();
		yellowRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-yellow-rgb').trim();
		greenRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-green-rgb').trim();
		successRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-success-rgb').trim();
		tealRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-teal-rgb').trim();
		cyanRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-cyan-rgb').trim();
		whiteRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-white-rgb').trim();
		grayRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-rgb').trim();
		limeRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-lime-rgb').trim();
		gray100Rgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100-rgb').trim();
		gray200Rgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200-rgb').trim();
		gray300Rgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300-rgb').trim();
		gray400Rgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400-rgb').trim();
		gray500Rgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500-rgb').trim();
		gray600Rgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600-rgb').trim();
		gray700Rgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700-rgb').trim();
		gray800Rgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800-rgb').trim();
		gray900Rgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900-rgb').trim();
		blackRgb: string; //window.getComputedStyle(document.body).getPropertyValue('--bs-black-rgb').trim()
	}
}

const initialState: ThemeState = {
	appTheme: 'teal',
	appDarkMode: false,
	appGradientEnabled: false,

	appHeaderNone: false,
	appHeaderFixed: true,
	appHeaderInverse: false,
	appHeaderMegaMenu: false,
	appHeaderLanguageBar: false,
	hasScroll: false,
	//handleSetAppHeaderNone: this.handleSetAppHeaderNone,
	//handleSetAppHeaderInverse: this.handleSetAppHeaderInverse,
	//handleSetAppHeaderLanguageBar: this.handleSetAppHeaderLanguageBar,
	//handleSetAppHeaderMegaMenu: this.handleSetAppHeaderMegaMenu,
	//handleSetAppHeaderFixed: this.handleSetAppHeaderFixed,

	appSidebarNone: false,
	appSidebarWide: false,
	appSidebarLight: false,
	appSidebarMinify: false,
	appSidebarMobileToggled: false,
	appSidebarTransparent: false,
	appSidebarSearch: false,
	appSidebarFixed: true,
	appSidebarGrid: false,
	//handleSetAppSidebarNone: this.handleSetAppSidebarNone,
	//handleSetAppSidebarWide: this.handleSetAppSidebarWide,
	//handleSetAppSidebarLight: this.handleSetAppSidebarLight,
	//handleSetAppSidebarMinified: this.handleSetAppSidebarMinified,
	//handleSetAppSidebarTransparent: this.handleSetAppSidebarTransparent,
	//handleSetAppSidebarSearch: this.handleSetAppSidebarSearch,
	//handleSetAppSidebarFixed: this.handleSetAppSidebarFixed,
	//handleSetAppSidebarGrid: this.handleSetAppSidebarGrid,
	//toggleAppSidebarMinify: this.toggleAppSidebarMinify,
	//toggleAppSidebarMobile: this.toggleAppSidebarMobile,

	appContentNone: false,
	appContentClass: '',
	appContentFullHeight: false,
	//handleSetAppContentNone: this.handleSetAppContentNone,
	//handleSetAppContentClass: this.handleSetAppContentClass,
	//handleSetAppContentFullHeight: this.handleSetAppContentFullHeight,

	appTopMenu: false,
	appTopMenuMobileToggled: false,
	//toggleAppTopMenuMobile: this.toggleAppTopMenuMobile,
	//handleSetAppTopMenu: this.handleSetAppTopMenu,

	appSidebarTwo: false,
	//handleSetAppSidebarTwo: this.handleSetAppSidebarTwo,

	appSidebarEnd: false,
	appSidebarEndToggled: false,
	appSidebarEndMobileToggled: false,
	//toggleAppSidebarEnd: this.toggleAppSidebarEnd,
	//toggleAppSidebarEndMobile: this.toggleAppSidebarEndMobile,
	//handleSetAppSidebarEnd: this.handleSetAppSidebarEnd,

	//handleSetAppBoxedLayout: this.handleSetAppBoxedLayout,
	//handleSetAppDarkMode: this.handleSetAppDarkMode,
	//handleSetAppGradientEnabled: this.handleSetAppGradientEnabled,
	//handleSetAppTheme: this.handleSetAppTheme,

	//handleSetColor: this.handleSetColor,

	font: {
		family: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim(),
		size: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-size').trim(),
		weight: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim()
	},
	color: {
		componentColor: window.getComputedStyle(document.body).getPropertyValue('--app-component-color').trim(),
		componentBg: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg').trim(),
		dark: window.getComputedStyle(document.body).getPropertyValue('--bs-dark').trim(),
		light: window.getComputedStyle(document.body).getPropertyValue('--bs-light').trim(),
		blue: window.getComputedStyle(document.body).getPropertyValue('--bs-blue').trim(),
		indigo: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo').trim(),
		purple: window.getComputedStyle(document.body).getPropertyValue('--bs-purple').trim(),
		pink: window.getComputedStyle(document.body).getPropertyValue('--bs-pink').trim(),
		red: window.getComputedStyle(document.body).getPropertyValue('--bs-red').trim(),
		orange: window.getComputedStyle(document.body).getPropertyValue('--bs-orange').trim(),
		yellow: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow').trim(),
		green: window.getComputedStyle(document.body).getPropertyValue('--bs-green').trim(),
		success: window.getComputedStyle(document.body).getPropertyValue('--bs-success').trim(),
		teal: window.getComputedStyle(document.body).getPropertyValue('--bs-teal').trim(),
		cyan: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan').trim(),
		white: window.getComputedStyle(document.body).getPropertyValue('--bs-white').trim(),
		gray: window.getComputedStyle(document.body).getPropertyValue('--bs-gray').trim(),
		lime: window.getComputedStyle(document.body).getPropertyValue('--bs-lime').trim(),
		gray100: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100').trim(),
		gray200: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200').trim(),
		gray300: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300').trim(),
		gray400: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400').trim(),
		gray500: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500').trim(),
		gray600: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600').trim(),
		gray700: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700').trim(),
		gray800: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800').trim(),
		gray900: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900').trim(),
		black: window.getComputedStyle(document.body).getPropertyValue('--bs-black').trim(),
		componentColorRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb').trim(),
		componentBgRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg-rgb').trim(),
		darkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-dark-rgb').trim(),
		lightRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-light-rgb').trim(),
		blueRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-blue-rgb').trim(),
		indigoRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo-rgb').trim(),
		purpleRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-purple-rgb').trim(),
		pinkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-pink-rgb').trim(),
		redRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-red-rgb').trim(),
		orangeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-orange-rgb').trim(),
		yellowRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow-rgb').trim(),
		greenRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-green-rgb').trim(),
		successRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-success-rgb').trim(),
		tealRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-teal-rgb').trim(),
		cyanRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan-rgb').trim(),
		whiteRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-white-rgb').trim(),
		grayRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-rgb').trim(),
		limeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-lime-rgb').trim(),
		gray100Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100-rgb').trim(),
		gray200Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200-rgb').trim(),
		gray300Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300-rgb').trim(),
		gray400Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400-rgb').trim(),
		gray500Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500-rgb').trim(),
		gray600Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600-rgb').trim(),
		gray700Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700-rgb').trim(),
		gray800Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800-rgb').trim(),
		gray900Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900-rgb').trim(),
		blackRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-black-rgb').trim()
	}
};



export const themeSlice = createSlice({
	name: 'theme',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		toggleAppSidebarMinify: (state) => { state.appSidebarMinify = !state.appSidebarMinify; },
		toggleAppSidebarMobile: (state) => { state.appSidebarMobileToggled = !state.appSidebarMobileToggled; },
		handleSetAppSidebarNone: (state, action: PayloadAction<boolean>) => { state.appSidebarNone = action.payload; },
		handleSetAppSidebarMinified: (state, action: PayloadAction<boolean>) => { state.appSidebarMinify = action.payload; },
		handleSetAppSidebarWide: (state, action: PayloadAction<boolean>) => { state.appSidebarWide = action.payload; },
		handleSetAppSidebarLight: (state, action: PayloadAction<boolean>) => { state.appSidebarLight = action.payload; },
		handleSetAppSidebarTransparent: (state, action: PayloadAction<boolean>) => { state.appSidebarTransparent = action.payload; },
		handleSetAppSidebarSearch: (state, action: PayloadAction<boolean>) => { state.appSidebarSearch = action.payload; },
		handleSetAppSidebarFixed: (state, action: PayloadAction<boolean>) => {
			if (action.payload === true && !state.appHeaderFixed) {
				alert('Default Header with Fixed Sidebar option is not supported. Proceed with Fixed Header with Fixed Sidebar.');
				state.appHeaderFixed = true;
			}
			state.appSidebarFixed = action.payload;
		},
		handleSetAppSidebarGrid: (state, action: PayloadAction<boolean>) => { state.appSidebarGrid = action.payload; },
		toggleAppSidebarEnd: (state) => { state.appSidebarEndToggled = !state.appSidebarEndToggled; },
		toggleAppSidebarEndMobile: (state) => { state.appSidebarEndMobileToggled = !state.appSidebarEndMobileToggled; },
		handleSetAppSidebarEnd: (state, action: PayloadAction<boolean>) => { state.appSidebarEnd = action.payload; },
		handleSetAppContentNone: (state, action: PayloadAction<boolean>) => { state.appContentNone = action.payload; },
		handleSetAppContentClass: (state, action: PayloadAction<string>) => { state.appContentClass = action.payload; },
		handleSetAppContentFullHeight: (state, action: PayloadAction<boolean>) => { state.appContentFullHeight = action.payload; },
		handleSetAppHeaderNone: (state, action: PayloadAction<boolean>) => { state.appHeaderNone = action.payload; },
		handleSetAppHeaderFixed: (state, action: PayloadAction<boolean>) => {
			if (action.payload === false && state.appSidebarFixed) {
				alert('Default Header with Fixed Sidebar option is not supported. Proceed with Default Header with Default Sidebar.');
				state.appSidebarFixed = false;
			}
			state.appHeaderFixed = action.payload;
		},
		handleSetAppHeaderInverse: (state, action: PayloadAction<boolean>) => { state.appHeaderInverse = action.payload; },
		handleSetAppHeaderMegaMenu: (state, action: PayloadAction<boolean>) => { state.appHeaderMegaMenu = action.payload; },
		handleSetAppHeaderLanguageBar: (state, action: PayloadAction<boolean>) => { state.appHeaderLanguageBar = action.payload; },
		handleSetAppTopMenu: (state, action: PayloadAction<boolean>) => { state.appTopMenu = action.payload; },
		toggleAppTopMenuMobile: (state) => { slideToggle(document.querySelector('.app-top-menu')); },
		handleSetAppSidebarTwo: (state, action: PayloadAction<boolean>) => { state.appSidebarTwo = action.payload; },
		handleSetAppBoxedLayout: (state, action: PayloadAction<boolean>) => {
			if (action.payload === true) {
				document.body.classList.add('boxed-layout');
			} else {
				document.body.classList.remove('boxed-layout');
			}
		},
		handleSetAppDarkMode: (state, action: PayloadAction<boolean>) => {
			if (action.payload === true) {
				document.body.classList.add('dark-mode');
			} else {
				document.body.classList.remove('dark-mode');
			}
			state.appDarkMode = action.payload;
			//this.handleSetColor();
		},
		handleSetAppGradientEnabled: (state, action: PayloadAction<boolean>) => { state.appGradientEnabled = action.payload; },
		handleSetFont: (state) => {
			state.font = {
				family: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim(),
				size: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-size').trim(),
				weight: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim()
			};
		},
		handleSetColor: (state) => {
			state.color = {
				componentColor: window.getComputedStyle(document.body).getPropertyValue('--app-component-color').trim(),
				componentBg: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg').trim(),
				dark: window.getComputedStyle(document.body).getPropertyValue('--bs-dark').trim(),
				light: window.getComputedStyle(document.body).getPropertyValue('--bs-light').trim(),
				blue: window.getComputedStyle(document.body).getPropertyValue('--bs-blue').trim(),
				indigo: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo').trim(),
				purple: window.getComputedStyle(document.body).getPropertyValue('--bs-purple').trim(),
				pink: window.getComputedStyle(document.body).getPropertyValue('--bs-pink').trim(),
				red: window.getComputedStyle(document.body).getPropertyValue('--bs-red').trim(),
				orange: window.getComputedStyle(document.body).getPropertyValue('--bs-orange').trim(),
				yellow: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow').trim(),
				green: window.getComputedStyle(document.body).getPropertyValue('--bs-green').trim(),
				success: window.getComputedStyle(document.body).getPropertyValue('--bs-success').trim(),
				teal: window.getComputedStyle(document.body).getPropertyValue('--bs-teal').trim(),
				cyan: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan').trim(),
				white: window.getComputedStyle(document.body).getPropertyValue('--bs-white').trim(),
				gray: window.getComputedStyle(document.body).getPropertyValue('--bs-gray').trim(),
				lime: window.getComputedStyle(document.body).getPropertyValue('--bs-lime').trim(),
				gray100: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100').trim(),
				gray200: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200').trim(),
				gray300: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300').trim(),
				gray400: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400').trim(),
				gray500: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500').trim(),
				gray600: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600').trim(),
				gray700: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700').trim(),
				gray800: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800').trim(),
				gray900: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900').trim(),
				black: window.getComputedStyle(document.body).getPropertyValue('--bs-black').trim(),
				componentColorRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb').trim(),
				componentBgRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg-rgb').trim(),
				darkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-dark-rgb').trim(),
				lightRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-light-rgb').trim(),
				blueRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-blue-rgb').trim(),
				indigoRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo-rgb').trim(),
				purpleRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-purple-rgb').trim(),
				pinkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-pink-rgb').trim(),
				redRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-red-rgb').trim(),
				orangeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-orange-rgb').trim(),
				yellowRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow-rgb').trim(),
				greenRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-green-rgb').trim(),
				successRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-success-rgb').trim(),
				tealRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-teal-rgb').trim(),
				cyanRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan-rgb').trim(),
				whiteRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-white-rgb').trim(),
				grayRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-rgb').trim(),
				limeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-lime-rgb').trim(),
				gray100Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100-rgb').trim(),
				gray200Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200-rgb').trim(),
				gray300Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300-rgb').trim(),
				gray400Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400-rgb').trim(),
				gray500Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500-rgb').trim(),
				gray600Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600-rgb').trim(),
				gray700Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700-rgb').trim(),
				gray800Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800-rgb').trim(),
				gray900Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900-rgb').trim(),
				blackRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-black-rgb').trim()
			};
		},
		handleSetAppTheme: (state, action: PayloadAction<Themes>) => {
			var newTheme = 'theme-' + action.payload;
			for (var x = 0; x < document.body.classList.length; x++) {
				if ((document.body.classList[x]).indexOf('theme-') > -1 && document.body.classList[x] !== newTheme) {
					document.body.classList.remove(document.body.classList[x]);
				}
			}
			document.body.classList.add(newTheme);
			state.appTheme = action.payload;
		},
	},
});

export const {
	toggleAppSidebarMinify,
	toggleAppSidebarMobile,
	handleSetAppSidebarNone,
	handleSetAppSidebarMinified,
	handleSetAppSidebarWide,
	handleSetAppSidebarLight,
	handleSetAppSidebarTransparent,
	handleSetAppSidebarSearch,
	handleSetAppSidebarFixed,
	handleSetAppSidebarGrid,
	toggleAppSidebarEnd,
	toggleAppSidebarEndMobile,
	handleSetAppSidebarEnd,
	handleSetAppContentNone,
	handleSetAppContentClass,
	handleSetAppContentFullHeight,
	handleSetAppHeaderNone,
	handleSetAppHeaderFixed,
	handleSetAppHeaderInverse,
	handleSetAppHeaderMegaMenu,
	handleSetAppHeaderLanguageBar,
	handleSetAppTopMenu,
	toggleAppTopMenuMobile,
	handleSetAppSidebarTwo,
	handleSetAppBoxedLayout,
	handleSetAppDarkMode,
	handleSetAppGradientEnabled,
	handleSetFont,
	handleSetColor,
	handleSetAppTheme,
} = themeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const selectCount = (state: RootState) => state.counter.value;
export const selectTheme = (state: RootState) => state.theme;


export default themeSlice.reducer;
