import { ReactNode } from "react";
import { selectSession } from "../../store/sessionSlice";
import { useAppSelector } from "../../store/hooks";
import { PermissionTypes } from "../../models/permissionTypes";
import { usePermissions } from "../../util/usePermissions";


type RequiresRoleProps = {
  role: string | string[];
  children?: ReactNode | ReactNode[];
};

/// Renders the child/children only if the user is a member of one of the specified Roles
export function RequiresRole(props: RequiresRoleProps) {
  const session = useAppSelector(selectSession);
  const requiredRoles = Array.isArray(props.role) ? props.role : [props.role];
  if (requiredRoles) {
    if (requiredRoles.some((requiredRole) => ((session.roles?.findIndex((userRole) => userRole === requiredRole) ?? -1) >= 0))) {
      return <>{props.children}</>;
    }
  }
  return null;
}


type RequiresPermissionProps = {
  permission: PermissionTypes | PermissionTypes[];
  children?: ReactNode | ReactNode[];
}

export function RequiresPermission(props: RequiresPermissionProps) {
  const { hasPermission } = usePermissions();
  const requiredPermission = Array.isArray(props.permission) ? props.permission : [props.permission];
  if (requiredPermission.some((permission) => hasPermission(permission))) {
    return <>{props.children}</>;
  }
  return null;
}