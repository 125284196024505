import { useCallback, useState } from "react";
import Tag, { tagOrAncestorIsUserManaged } from "../../models/tag";

type TagSelectorProps = {
  tags: Tag[];
  tag: Tag;
  onAdd: (tag: Tag) => void;
  // onRemove: (tag: Tag) => void;
  // onAdd: (tagId: number) => void;
  onRemove: (tagId: number) => void;
  hightlight?: string;
  selectedTagIds: number[];
  onQuickAdd: (parentTag: Tag) => void;
};

function getHighlightedText(text: string, highlight: string) {
  // Split text on highlight term, include term itself into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return <span>{parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? <u className="">{part}</u> : part)}</span>;
}

export function TagSelector(props: TagSelectorProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { tag, tags, selectedTagIds, onQuickAdd } = props;
  const subTags = tags?.filter(t => t.parentTagId === tag.id).sort((t1, t2) => (t1.sortOrder ?? 0) - (t2.sortOrder ?? 0));

  const isChildSelected = useCallback((tag: Tag): boolean => {
    if (selectedTagIds.includes(tag.id ?? -1)) {
      return true;
    }
    return tags?.filter(t => t.parentTagId === tag.id).some((t) => isChildSelected(t)) ?? false;
  }, [selectedTagIds, tags]);
  
  const canAddSubtag = tagOrAncestorIsUserManaged(tags, tag);

  const isSelected = selectedTagIds.includes(tag.id ?? -1);
  const childIsSelected = isChildSelected(tag);
  const canAdd =
    !isSelected
    && !childIsSelected
    // searchTerm is null, or is in the tag label
    && ((tag.label?.toLowerCase().includes(props.hightlight ?? "")) ?? true);
  const canExpand = ((subTags?.length ?? 0) > 0) || canAddSubtag;

  const onAddSubtag = (parentTag: Tag) => {
    console.log('onAddSubtag', onQuickAdd, parentTag);
    if (onQuickAdd) {
      onQuickAdd(parentTag);
    }
  };

  return <>
    <div className="mt-2">
      {/* {(subTags?.length ?? 0) > 0  ? <button type="button" className="btn btn-sm btn-outline-dark px-1 py-0 me-1" onClick={(e) => setIsExpanded(cur => !cur)}>
        {!isExpanded && <i className="fa-solid fa-fw fa-plus"></i>}
        {isExpanded && <i className="fa-solid fa-fw fa-minus"></i>}
      </button> : <button type="button" className="btn btn-sm px-1 py-0 me-1"><i className="fa-solid fa-fw"></i></button>} */}
      <button type="button" className="btn btn-sm  px-1 py-0" onClick={canExpand ? (e) => setIsExpanded(cur => !cur) : undefined}>
        {!isExpanded && (canExpand ? <i className="fa-solid fa-fw fa-caret-right"></i> : <i className="fa-solid fa-fw"></i>)}
        {isExpanded && <i className="fa-solid fa-fw fa-caret-down"></i>}
        {props.hightlight ? getHighlightedText(tag.label ?? "", props.hightlight) : tag.label}
      </button>
      {(tag.description?.length ?? 0) > 1 && <i className="fa-solid fa-circle-info" title={tag.description}></i>}
      {canAdd && <button onClick={(e) => props.onAdd(tag)} type="button" className="btn btn-sm btn-outline-success ms-2 px-1 py-0" title={"Tag w/ " + tag.label}>Tag</button>}
      {!isSelected && childIsSelected && <button type="button" className="btn btn-sm btn-gray-500 ms-2 px-1 py-0" title={tag.label + " is included via a selection below"}>incl.</button>}
      {isSelected && <button onClick={(e) => props.onRemove(tag.id!)} type="button" className="btn btn-sm btn-outline-warning ms-2 px-1 py-0" title={"Remove " + tag.label}>Remove</button>}
      {/* {canAddSubtag && <span className="ms-4"><button className="btn btn-sm btn-outline-secondary px-1 py-0"><i className="fas fa-plus"></i> Create Sub-Tag</button></span>} */}
    </div>
    <div className="ms-4">
      {(isExpanded || (props.hightlight?.length ?? 0) > 0) && subTags?.map((subTag) => <TagSelector key={`subtag_${subTag.id}`} onQuickAdd={onQuickAdd} selectedTagIds={props.selectedTagIds} tags={tags} tag={subTag} onAdd={props.onAdd} onRemove={props.onRemove} hightlight={props.hightlight} />)}
      {isExpanded && canAddSubtag && <div className="mt-2"><button type="button" onClick={(e) => onAddSubtag(tag)} className="btn btn-sm px-1 py-0 text-primary text-decoration-underline"><i className="fa-fw fa-solid fa-plus"></i>New {tag.label} sub-tag</button></div>}
    </div>
  </>;
}