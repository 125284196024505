import { Fragment, ReactNode, useEffect, useState } from "react";
import EntityTypes from "../../models/entityTypes";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Modal } from "react-bootstrap";
import EntityTypeLabel from "../label/entityTypeLabel";
import { CustomizableEntities } from "../../models/types";
import { Panel, PanelBody } from "../panel/panel";
import { AttributeValueSummary } from "../attribute/attributeValueSummary";
import { fetchAttributes, selectConfig } from "../../store/configSlice";

type QuckViewModalProps<T extends CustomizableEntities> = {
  show: boolean;
  entityType: EntityTypes;
  onCancel?: () => void;
  onSuccess?: (entity: CustomizableEntities) => void;
  record?: T;
  footer?: ReactNode | ReactNode[];
}

export default function QuckViewModal<T extends CustomizableEntities>(props: QuckViewModalProps<T>) {
  const dispatch = useAppDispatch();
  const config = useAppSelector(selectConfig);
  const attributes = config.attributes[props.entityType];
  useEffect(() => { dispatch(fetchAttributes(props.entityType)) }, [dispatch, props.entityType]);
  
  return <Modal show={props.show} onHide={() => props.onCancel && props.onCancel()} size="lg">
    <Modal.Header closeButton>
      <Modal.Title><EntityTypeLabel entityType={props.entityType} singlular />: {props.record?.name}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
        <Panel className="card border-0" isLoading={attributes === undefined}>
          <PanelBody className="card-body">
            {attributes?.map((attribute) => {
              const attributeValue = props.record?.attributes?.find((t) => t?.id === attribute.id);
              if (attributeValue) {
                return <Fragment key={`attribute-${attribute.id}`}>
                  <h6 className="card-subtitle text-muted">{attribute.name}</h6>
                  <p className="card-text">
                    <AttributeValueSummary attribute={attribute} attributeValues={props.record?.attributes} />
                  </p>
                </Fragment>;
              }
              return <div></div>; // no value
            })}
            {props.entityType === EntityTypes.Resource && <div className="row">
              <div className="col">
                
              </div>
            </div>}
          </PanelBody>
        </Panel>
      </div>
    </Modal.Body>
    <Modal.Footer>
      {props.footer}
      <button type="button" className="btn btn-primary w-100px me-5px" onClick={() => props.onCancel && props.onCancel()}>Close</button>
    </Modal.Footer>
  </Modal>
}