import { SearchProps, api } from "../../store/api";
import EntityTypes from "../../models/entityTypes";
import RecordsList from "../records/recordsList";
import { Link, generatePath, useNavigate } from "react-router-dom";
import ProjectResource from "../../models/projectResource";
import useLabel from "../../util/useLabel";
import { ProjectDetailsRoute } from "../../screens/projects/projectDetailsScreen";
import { PartnerDetailsRoute } from "../../screens/partners/partnerDetailsScreen";

type ResourceProjectListProps = {
  resourceId: number;
}


export function ResourceProjectList(props: ResourceProjectListProps) {
  
  //const partnerLabel = useLabel(EntityTypes.Partner);
  const projectLabel = useLabel(EntityTypes.Project);
  return <RecordsList
    showName={false}
    type={EntityTypes.ProjectResource}
    filters={[
      { key: 'all', label: 'All', api: (search: SearchProps) => api.projectResources.forResource({...search, resourceId: props.resourceId, }) },
    ]}
    columns={[
      // {
      //   label: partnerLabel.singular,
      //   callback: (record: ProjectResource) => <td><Link to={generatePath(PartnerDetailsRoute, { id: `${record.project?.partnerId}` })}>{record?.project?.partner?.name}</Link></td>
      // },
      {
        label: projectLabel.singular,
        callback: (record: ProjectResource) => <td><Link to={generatePath(ProjectDetailsRoute, { id: `${record.project?.id}` })}>{record?.project?.name}</Link></td>
      },
      {
        label: "Grade",
        headerClassNames: 'text-end',
        callback: (record: ProjectResource) => <td className="text-end">{record?.gradeUnit?.grade}</td>
      },
      {
        label: "Unit",
        headerClassNames: 'text-end',
        callback: (record: ProjectResource) => <td className="text-end">{record?.gradeUnit?.unit}</td>
      }
    ]}
    // actions={(record: ProjectResource) => <>
    //   <button className="btn btn-sm btn-outline-primary mx-1" onClick={() => navigate(generatePath(ProjectDetailsRoute, { id: `${record.projectId}` }))}>{projectLabel.singular}</button>
    // </>}
    
    //navigate(generatePath(ProjectEditRoute, { id: `${record.id}` }))
  />;
}