import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { selectTheme } from '../../store/themeSlice';

export function Content() {
	const theme = useAppSelector(selectTheme);
	return (
		<div className={"app-content " + theme.appContentClass}>
			<Outlet />
		</div>
	);
}