enum EntityTypes {
	Custom = 0,
	Partner = 1,
	Contact = 2,
	Project = 3,
	Resource = 4,
	User = 5,
	ProjectResource = 6,
	Reviewer = 7,
	Review = 8,
	Tag = 9,
	ReviewTag = 10,
	UnitEvaluation = 11,
	GradeUnit = 12,
};

export default EntityTypes;
export type EntityTypeKeys = EntityTypes.Partner | EntityTypes.Project;

export const RelatedEntityTypes = (type: EntityTypes): EntityTypes[] => {
	switch (type) {
		case EntityTypes.Review:
		return [EntityTypes.Resource,
			EntityTypes.ProjectResource,];
		default:
			return [];
	}
};

export const AllEntityTypes = [
	EntityTypes.Partner,
	EntityTypes.Contact,
	EntityTypes.Resource,
	EntityTypes.Project,
	EntityTypes.ProjectResource,
	EntityTypes.User,
	EntityTypes.Reviewer,
	EntityTypes.Review,
	EntityTypes.UnitEvaluation,
];