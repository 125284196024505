enum GradeTypes
{
	PreK = -2,
	K = -1,
	NA = 0,
	Grade1 = 1,
	Grade2 = 2,
	Grade3 = 3,
	Grade4 = 4,
	Grade5 = 5,
	Grade6 = 6,
	Grade7 = 7,
	Grade8 = 8,
	Grade9 = 9,
	Grade10 = 10,
	Grade11 = 11,
	Grade12 = 12,
};

export default GradeTypes;

export const AllGrades = [
	GradeTypes.PreK,
	GradeTypes.K,
	GradeTypes.Grade1,
	GradeTypes.Grade2,
	GradeTypes.Grade3,
	GradeTypes.Grade4,
	GradeTypes.Grade5,
	GradeTypes.Grade6,
	GradeTypes.Grade7,
	GradeTypes.Grade8,
	GradeTypes.Grade9,
	GradeTypes.Grade10,
	GradeTypes.Grade11,
	GradeTypes.Grade12,
	GradeTypes.NA,
];
