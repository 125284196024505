enum KnowledgeMapTypes {
	Undefined = 0,
	ELA = 1,
	SocailStudies = 2,
	Math = 4,
	Science = 8,
};

export default KnowledgeMapTypes;

export const AllKnowledgeMapTypes = [
	KnowledgeMapTypes.ELA,
	KnowledgeMapTypes.SocailStudies,
];
