enum AttributeTypes
{
	None = 0,
		String = 1,
		Text = 2,
		Date = 3,
		Choice = 4,
		MultipleChoice = 5,
		YesNo = 6,
		Completion = 7,
		NumberWhole = 8,
		NumberDecimal = 9,
		// Suite = 30,
		// MultipleSuites = 31,
		Contact = 32,
		MultipleContacts = 33,
};

export default AttributeTypes;