import { Link } from "react-router-dom"
import { useAppSelector } from "../../store/hooks";
import { ROUTE as SettingsFieldsScreenRoute  } from "./fieldsScreen";
import { ROUTE as SettingsLabelsScreenRoute } from "./labelsScreen";
import { selectLabels } from "../../store/labelsSlice";

export const ROUTE = "/settings";
export function SettingsScreen() {
	const labels = useAppSelector(selectLabels);
	// Get list of attributes+settings

	return (
		<div>
			<ol className="breadcrumb float-xl-end">
				<li className="breadcrumb-item active">Settings</li>
			</ol>
			<h1 className="page-header">System Settings</h1>
			<div className="row">
				<div className="col-4">
					<h4>Customization</h4>
					<ul className="list-group">
						<Link className="list-group-item d-flex justify-content-between align-items-center" to={SettingsFieldsScreenRoute}>
							Custom Fields
							<span className="badge bg-primary">3</span>
						</Link>
						<Link className="list-group-item d-flex justify-content-between align-items-center" to={SettingsLabelsScreenRoute}>
							Labels
							<span className="badge bg-primary">{Object.keys(labels).length}</span>
						</Link>
					</ul>
				</div>
			</div>
		</div>
	);
}