import { useEffect, useRef, useState } from "react";
import { Link, generatePath, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import { api } from "../../store/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import RecordDetailsCard from "../../components/records/recordDetailsCard";
import ProjectResource from "../../models/projectResource";
import { ProjectDetailsRoute } from "../projects/projectDetailsScreen";
import Resource from "../../models/resource";
import Review from "../../models/review";
import { FormProvider, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";
import { Panel, PanelBody } from "../../components/panel/panel";
import { AttributeValueEditor } from "../../components/attribute/attributeValueEditor";
import { fetchAttributes, selectConfig } from "../../store/configSlice";
import { setReviewTab } from "../../store/uiSlice";
import Tag, { tagHasAncestorWithId, tagHasDescendantwithId, tagIsAncestor } from "../../models/tag";
import { TagBrowser } from "../../components/tags/tagBrowser";
import { TagBadge } from "../../components/tags/tagBadge";
import { ReviewDetailsRoute } from "./reviewDetailsScreen";
import KnowledgeMapTypes from "../../models/knowledgeMapTypes";
import Project from "../../models/project";
import { conditionMatch, hasValue } from "../../models/attributeCondition";
import AttributeValue from "../../models/attributeValue";
import { ResourceDetailsRoute } from "../resources/resourceDetailsScreen";
import { setTitle } from "../../util/useDocumentTitle";
import { ConfirmNavigation } from "../../components/modal/confirmNavigation";
import { Loading } from "../../components/records/loading";
import ReviewListGroup from "../../components/review/reviewListGroup";
import ReviewTag from "../../models/reviewTag";

export const ReviewEditRoute = "/reviews/:id/edit";

interface ReviewEditRouteParams {
  id?: number;
  projectResourceId?: string;
}

export default function ReviewEditScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const ui = useAppSelector(state => state.ui.review);
  const labels = useAppSelector(selectLabels);
  const tags = useAppSelector(selectConfig).tags;
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [hasAddedTags, setHasAddedTags] = useState(false);
  const [projectResourceId, setProjectResourceId] = useState<number>();
  const [projectResource, setProjectResource] = useState<ProjectResource>();
  const [resourceId, setResourceId] = useState<number>();
  const [resource, setResource] = useState<Resource>();
  const [projectId, setProjectId] = useState<number>();
  const [project, setProject] = useState<Project>();
  const [error, setError] = useState<String>();
  const params = useParams<keyof ReviewEditRouteParams>();
  const [searchParams] = useSearchParams();

  const [showExistingReviews, setShowExistingReviews] = useState(true);
  const [sourceReview, setSourceReview] = useState<Review>();
  const [existingReviews, setExistingReviews] = useState<Review[]>();

  const config = useAppSelector(selectConfig);
  const attributes = config.attributes[EntityTypes.Review];
  useEffect(() => { dispatch(fetchAttributes(EntityTypes.Review)) }, [dispatch]);
  const [hideAttributeIds, setHideAttributeIds] = useState<number[]>([]);
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);

  // navigate to the tab for the URL hash e.g. #tag
  useEffect(() => {
    switch (location.hash) {
      case '#review':
        dispatch(setReviewTab({ tab: EntityTypes.Review }))
        break;
      case '#tag':
      default:
        dispatch(setReviewTab({ tab: EntityTypes.Tag }))
        break;
    }
  }, [dispatch, location.hash]);

  // Form
  const [defaultValue, setDefaultValue] = useState<Review>();

  useEffect(() => {
    // Load the attribute and set the form with the current values
    if (params.id) {
      setIsEditing(true);
      setIsLoading(true);
      api.reviews.get(parseInt(params.id))
        .then((record) => {
          setDefaultValue(record);
          setIsLoading(false);
          setResourceId(record.resourceId);
          setProjectResourceId(record.projectResourceId);
        })
        .catch((reason) => {
          console.error(reason);
          setError("Unable to load " + labels.projectResource.singular + ` #${params.id}`);
        });
    }
    if (searchParams.get('projectResourceId')) {
      setProjectResourceId(parseInt(searchParams.get('projectResourceId')!));
    }
  }, [labels.project.singular, labels.projectResource.singular, params.id, searchParams]);

  useEffect(() => {
    if (projectId) {
      api.projects.get(projectId).then(setProject);
    }
  }, [projectId]);

  // Load the Resource
  useEffect(() => {
    if (resourceId) {
      api.resources.get(resourceId).then(setResource);
    }
  }, [resourceId]);

  useEffect(() => {
    if (projectResourceId) {
      api.projectResources.get(projectResourceId)
        .then((record) => {
          setProjectResource(record);
          setResourceId(record.resourceId);
          setProjectId(record.gradeUnit?.projectId);
        })
        .catch((reason) => {
          console.error(reason);
          setError("Unable to load " + labels.projectResource.singular + ` #${params.projectResourceId}`);
        });
    }
  }, [labels.projectResource.singular, params.projectResourceId, projectResourceId]);

  useEffect(() => {
    if (projectResource) {
      if (!isEditing) {
        // not editing (creating new review)
        // load existing reviews that can be adolpted
        api.projectResources.clonableReviews(projectResource.id!).then(setExistingReviews);
      }
      else {
        setExistingReviews([]);
      }
    }
    else {
      setExistingReviews(undefined);
    }
  }, [isEditing, projectResource]);

  const form = useForm<Review>({
    //defaultValues: fetchRecord,
  });

  const { handleSubmit, reset, formState: { errors, isDirty, isSubmitSuccessful }, control } = form;

  const { fields, replace } = useFieldArray({
    name: "attributes",
    keyName: "attributeId",
    control
  });

  const onSubmit: SubmitHandler<Review> = data => {
    console.log(data);
    const request: Review = {
      ...data,
      resourceId: resource?.id,
      sourceId: sourceReview?.id,
      projectResourceId: projectResource?.id ?? data.projectResourceId,
      tags: tags?.filter(t => selectedTagIds.includes(t.id ?? 0))?.map(t => ({ tagId: t.id! })) ?? [], // selectedTags.map((t) => ({ tagId: t.id! })),
      attributes: data.attributes?.map((attrib) => {
        //debugger;
        return {
          id: attrib.id,
          boolValue: attrib.boolValue,
          dateValue: attrib.dateValue,
          intValue: attrib.intValue,
          stringValue: attrib.stringValue,
          choicesValue: (attrib.choicesValue ?
            (Array.isArray(attrib.choicesValue) ? attrib.choicesValue : [attrib.choicesValue])
            : undefined
          ),
        };
      })
    };
    console.log(request);
    //debugger;
    setIsLoading(true);
    (isEditing ? api.reviews.update(request) : api.reviews.create(request))
      .then((updated) => {
        setIsLoading(false);
        setError(undefined);
        const path = isEditing ? '' : '#created';
        navigate(generatePath(ReviewDetailsRoute, { id: `${updated.id}` }) + path);
      })
      .catch((reason) => {
        setError("Unable to save " + labels.review.singular + ": " + reason);
        setIsLoading(false);
      });
  };

  // onLoad: set defaultValue with fetched record (if editing) or default
  useEffect(() => {
    if (params.id) {
      setIsEditing(true);
      api.reviews.get(parseInt(params.id!))
        .then((record) => {
          setDefaultValue(record);
        })
        .catch((reason) => {
          console.error(reason);
          setError("Unable to load " + labels.review.singular + ` #${params.id}`);
        });
    }
    else {
      setDefaultValue({ isActive: true, name: '' });
    }
  }, [dispatch, labels.review.singular, params.id, searchParams]);

  // Initialize Form with Attributes and defaultValue
  useEffect(() => {
    if (attributes && defaultValue) {
      reset({
        ...defaultValue,
        attributes: attributes.map((attrib) => {
          if (defaultValue?.attributes) {
            const attributeValue = defaultValue?.attributes?.find((t) => t?.id === attrib.id);
            if (attributeValue) {
              // console.warn('replacing field', attributeValue);
              return attributeValue;
            }
          }
          return {
            id: attrib.id,
          }
        }),
      }, {

      });
      setSelectedTagIds(defaultValue.tags?.map(t => t.tagId) ?? []);
    }
  }, [attributes, replace, defaultValue, reset]);

  const watchAttributes = useWatch({
    control: control,
    name: `attributes`
  });

  useEffect(() => {
    // Process conditional fields
    if (attributes && resource && project && projectResource) {
      const attributeValues: AttributeValue[] = [
        ...watchAttributes ?? [],
        ...project?.attributes ?? [],
        ...projectResource?.attributes ?? [],
        ...resource?.attributes ?? [],
      ];

      setHideAttributeIds(attributes.filter((attr) => {
        let show = (attr.visibleDefault ?? true);
        if (attr.hideIf) {
          // console.log('hideIf', attr);
          if (attr.hideIf.some((cond) => conditionMatch(cond, attributeValues))) {
            show = false;
          }
        }
        if (!show && attr.showIf) {

          if (attr.showIf.some((cond) => conditionMatch(cond, attributeValues))) {
            show = true;
          }
          // console.log('showIf', attr, show);
        }
        if (!show && hasValue(attr, attributeValues)) {
          // Show attributes that have a value, but are supposed to be hidden (e.g. they were migrated with a value)
          show = true;
        }
        return !show;
      }).map((attr) => attr.id!));
    }
  }, [attributes, project, projectResource, resource, watchAttributes]);


  const onAddTag = (tag: Tag) => {
    console.error('onAddTag', tag);
    setHasAddedTags(true);
    if (tags) {
      if (tag) {
        setSelectedTagIds((existing) => {
          const filtered = existing.filter((tagId) => {
            const existingTag = tags.find(t => t.id === tagId);
            if (existingTag) {
              return !tagIsAncestor(tags ?? [], tag, existingTag)
            }
            return false;
          })
          return [...filtered, tag.id!];
        });
        // const filterExistingTagIds = selectedTagIds.filter((t) => {
        //   const existingTag = tags?.find(t => t.id === tagId);
        // })
        // setSelectedTagIds((existing) => [...existing.filter(t => !tagIsAncestor(tags ?? [], tag, t)), tag]);
      }

    }

  };
  //const onRemoveTag = (tagId: number) => setSelectedTagIds((exist) => exist.filter(t => t.id !== tag.id));
  const onRemoveTag = (tagId: number) => {
    setHasAddedTags(true);
    setSelectedTagIds((exist) => exist.filter(t => t !== tagId));
  };


  useEffect(() => {
    setTitle([
      `${isEditing ? 'Edit' : 'Create'} ${labels.review.singular}`,
      project?.name,
      resource?.name]);
  }, [isEditing, labels.review.singular, project?.name, resource]);

  const [selectedTagsY, setSelectedTagsY] = useState<number>();
  const refSelectedTags = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (refSelectedTags.current) {
      const boundingRect = refSelectedTags.current.getBoundingClientRect();
      console.log('Selected Tags Rect', boundingRect);
      setSelectedTagsY(boundingRect.y);
    }
  }, [])

  const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (selectedTagsY) {
        setIsScrollValueMoreThanHeaderHeight(window.scrollY >= selectedTagsY);
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll);
  }, [selectedTagsY]);

  const reviewTagsForProject = (reviewTags?: ReviewTag[]) => {
    if (reviewTags && tags && project) {
      const knowledgeMapType = project.knowledgeMapType ?? KnowledgeMapTypes.Undefined;
      const availableTagIds = project?.tags?.map(t => t.tagId) ?? [];
      const filteredTags = tags
        // filter tags by Knowledge Maps Type
        .filter((tag, index, array) => tag.isActive && tag.knowledgeMapTypes && ((tag.knowledgeMapTypes & knowledgeMapType) === knowledgeMapType))
        // filter by availableTags
        .filter((tag, index, array) => availableTagIds === undefined || (
          tagHasDescendantwithId(array, tag, availableTagIds)
          || tagHasAncestorWithId(array, tag, availableTagIds)
        ))?.map((ft) => ft.id)
        ?? [];
        return reviewTags.filter((reviewTag) => filteredTags.includes(reviewTag.tagId));
    }
    //         // filter tags by Knowledge Maps Type
    //     ?.filter((reviewTag, index, array) => tag.isActive && tag.knowledgeMapTypes && ((tag.knowledgeMapTypes & props.knowledgeMapType) === props.knowledgeMapType))
    // // filter by availableTags
    // ?.filter((tag, index, array) => props.availableTagIds === undefined || (
    //   tagHasDescendantwithId(array, tag, props.availableTagIds)
    //   || tagHasAncestorWithId(array, tag, props.availableTagIds)
    // ))
    // ?? [],

    return reviewTags ?? [];
  };

  const onUseReview = (review: Review) => {
    setIsLoading(true);
    setShowExistingReviews(false); // no longer show the existing reviews panel

    // Reset the default value with the tags + attribute values that are clonable
    const clonedReview = {
      // TODO filter tags to those this project supports
      // tags
      //   // filter tags by Knowledge Maps Type
      //   .filter((tag, index, array) => tag.isActive && tag.knowledgeMapTypes && ((tag.knowledgeMapTypes & props.knowledgeMapType) === props.knowledgeMapType))
      //   // filter by availableTags
      //   .filter((tag, index, array) => props.availableTagIds === undefined || (
      //     tagHasDescendantwithId(array, tag, props.availableTagIds)
      //     || tagHasAncestorWithId(array, tag, props.availableTagIds)
      //   ))
      //   ?? [];
      tags: reviewTagsForProject(review.tags),
      attributes: review.attributes?.filter((reviewAttribute, index) => attributes?.find((attribute) => attribute.id === reviewAttribute.id)?.isAdoptable),
    };
    console.log('onUseReview', clonedReview);
    setDefaultValue(clonedReview);
    // TODO link to source Review for tracking the clone/adopt
    setSourceReview(review);
    setIsLoading(false);
  }

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">{projectResource?.name}</h1>
        <ul className="breadcrumb">
          {projectResource?.gradeUnit?.projectId && <li className="breadcrumb-item"><Link to={generatePath(ProjectDetailsRoute, { id: `${projectResource?.gradeUnit?.projectId}` })}>{projectResource?.project?.name}</Link></li>}
          <li className="breadcrumb-item">{labels.review.plural}</li>
          {isEditing && <li className="breadcrumb-item active">Edit</li>}
          {!isEditing && <li className="breadcrumb-item active">Create</li>}
        </ul>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <ConfirmNavigation hasUnsavedChanges={(isDirty || hasAddedTags) && !isSubmitSuccessful} />
    <div className="row">
      <div className="col-md-4">
        <Loading isLoading={projectResource === undefined}>Loading {labels.projectResource.singular}</Loading>

        {projectResource && <RecordDetailsCard
          record={projectResource}
          type={EntityTypes.ProjectResource}
          displayAttributes={[
            { label: labels.project.singular, callback: (record: Review) => <Link to={generatePath(ProjectDetailsRoute, { id: `${record?.projectId}` })}>{record?.projectName}</Link>, },
            { label: 'Knowledge Map Type(s)', callback: (record: Review) => <div>{labels.knowledgeMapTypes[project?.knowledgeMapType ?? KnowledgeMapTypes.Undefined]}</div>, },
          ]}
        />}
        
        <Loading isLoading={existingReviews === undefined}> Checking for existing {labels.review.plural}</Loading>
        {showExistingReviews && existingReviews && existingReviews.length > 0 && <div className="card border-warning mb-3">
          <div className="card-header bg-warning d-flex flex-row justify-content-between">
            <h3 className="mb-0">Existing {labels.review.plural}</h3>
            <button className="btn btn-lg mb-0" onClick={() => setShowExistingReviews(false)}><i className="fa-solid fa-times"></i></button>
          </div>
          <div className="card-body">
            <p>The {labels.review.plural} listed below were submitted for this {labels.resource.singular} (or a translation) and can be utilized in your {labels.review.singular} by clicking below.</p>
          </div>
          <ReviewListGroup reviews={existingReviews} onUseReview={onUseReview} />
        </div>}
        {sourceReview && <div className="card border-warning mb-3">
          <div className="card-header d-flex flex-row justify-content-between">
            <h3 className="mb-0">Source {labels.review.singular}</h3>
            {/* <button className="btn btn-lg mb-0" onClick={() => setShowExistingReviews(false)}><i className="fa-solid fa-times"></i></button> */}
          </div>
          <div className="card-body">
            <p>The {labels.review.singular} listed below is being used as the basis for this {labels.review.singular}</p>
          </div>
          <ReviewListGroup reviews={[sourceReview]} />
        </div>}

        <Loading isLoading={resource === undefined}>Loading {labels.resource.singular}</Loading>

        {resource && <RecordDetailsCard
          record={resource}
          type={EntityTypes.Resource}
          detailsRoute={ResourceDetailsRoute}
        />}

      </div>
      <div className="col-md-8">
        
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                {/* <Link to="#tag" className={"nav-link " + (ui.tab === EntityTypes.Tag ? "active" : "")} aria-current="page">{labels.tag.plural} {selectedTagIds.length > 0 && <span className="badge bg-warning">{selectedTagIds.length}</span>}</Link> */}
                <button type="button" className={"nav-link " + (ui.tab === EntityTypes.Tag ? "active" : "")} aria-current="page" onClick={(e) => dispatch(setReviewTab({ tab: EntityTypes.Tag }))}>{labels.tag.plural} {selectedTagIds.length > 0 && <span className="badge bg-warning">{selectedTagIds.length}</span>}</button>
              </li>
              <li className="nav-item">
                {/* <Link to="#review" className={"nav-link " + (ui.tab === EntityTypes.Review ? "active" : "")} aria-current="page">{labels.review.singular}</Link> */}
                <button type="button" className={"nav-link " + (ui.tab === EntityTypes.Review ? "active" : "")} aria-current="page" onClick={(e) => dispatch(setReviewTab({ tab: EntityTypes.Review }))}>{labels.review.singular}</button>
              </li>
            </ul>
            {ui.tab === EntityTypes.Tag && <Panel className="card border-0" isLoading={isLoading}>
              <PanelBody className="card-body">
                {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
                {selectedTagIds.length === 0 && <div className="alert alert-warning">No {labels.tag.plural} have been associated with this {labels.review.singular}. Add some from the Available {labels.tag.plural} below.</div>}
                <div className={"card sticky-top mb-5" + (isScrollValueMoreThanHeaderHeight ? " shadow" : "")} ref={refSelectedTags} style={{ maxHeight: '50vh' }}>
                  <h4 className="card-header">Selected Tag(s)</h4>
                  <div className="card-body" style={{ overflow: 'auto' }}>
                    <ul>
                      {selectedTagIds.map((tagId) => <li key={tagId} className="d-flex flex-row mb-1">
                        <TagBadge key={tagId} tagId={tagId} />
                        <button type="button" className="ms-1 px-1 py-0 btn btn-sm btn-outline-warning" onClick={(e) => onRemoveTag(tagId)}>Remove</button>
                      </li>)}
                    </ul>
                  </div>
                </div>
                <Loading isLoading={project?.tags === undefined}>Loading {labels.tag.plural}</Loading>
                {project?.tags && <TagBrowser
                  availableTagIds={project?.tags?.map(t => t.tagId) ?? []}
                  selectedTagIds={selectedTagIds}
                  onAdd={onAddTag}
                  onRemove={onRemoveTag}
                  knowledgeMapType={project?.knowledgeMapType ?? KnowledgeMapTypes.Undefined}
                />}
                <div className="row mb-0">
                  <div className="pt-2 text-end">
                    <button type="button" onClick={(e) => dispatch(setReviewTab({ tab: EntityTypes.Review }))} className="btn btn-outline-primary w-100px me-5px">Next</button>
                  </div>
                </div>
              </PanelBody>
            </Panel>}
            {ui.tab === EntityTypes.Review && <Panel className="card border-0" isLoading={isLoading}>
              <PanelBody className="card-body">
                {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">{labels.resource.singular}</label>
                  <div className="col-md-9">
                    <input type="text" className="form-control read-only" defaultValue={resource?.name} readOnly />
                  </div>
                </div>

                {fields?.map((field, index) => {
                  const attribute = attributes?.find(attrib => attrib.id === field.id);
                  if (attribute) {
                    //console.log('field', attribute.name, field);
                    const show = attribute.visibleDefault ?? true;
                    if (!hideAttributeIds.includes(attribute.id!)) {
                      return (<div key={field.id} className={"row mb-15px collapse" + (show ? " show" : " show") + (attribute.isRequired ? " required" : "")}>
                        <label className={"form-label col-form-label col-md-3 required attribute-" + attribute.id}>{attribute.name}</label>
                        <div className="col-md-9">
                          <AttributeValueEditor attribute={attribute} errors={errors} index={index} watch={watchAttributes} />
                        </div>
                      </div>
                      );
                    }
                    // console.log('hidden attribute ', attribute);
                  }
                  return <div key={field.id}></div>;
                }
                )}
                <div className="row mb-0">
                  <div className="pt-2 text-center">
                    <button type="submit" className="btn btn-primary w-100px me-5px">Save</button>
                    <button type="button" onClick={() => navigate(-1)} className="btn btn-default w-100px">Cancel</button>
                  </div>
                </div>
              </PanelBody>
            </Panel>}


          </form>
        </FormProvider>
      </div>
    </div>
  </div>);
}
