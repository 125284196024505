import React from 'react';
import { Link } from 'react-router-dom';
import DropdownLanguage from './dropdown/language.jsx';
import DropdownProfile from './dropdown/profile';
import DropdownMegaMenu from './dropdown/mega.jsx';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectTheme, toggleAppSidebarEnd, toggleAppSidebarEndMobile, toggleAppSidebarMobile, toggleAppTopMenuMobile } from '../../store/themeSlice';
import { selectSession } from '../../store/sessionSlice';

function Environment() {
	const session = useAppSelector(selectSession);
	if (session.environment) {
		switch(session.environment) {
			case 'Production':
				return <div className='ms-3 badge bg-info'>{session.environment}</div>;
			case 'Testing':
				return <div className='ms-3 badge bg-success'>{session.environment}</div>;
			case 'Staging':
				return <div className='ms-3 badge bg-warning'>{session.environment}</div>;
			default:
				return <div className='ms-3 badge bg-danger'>{session.environment}</div>;
		}
	}
	return <></>;
}

export default function Header() {
	const theme = useAppSelector(selectTheme);
	const dispatch = useAppDispatch();

	return (
		<div id="header" className={'app-header ' + (theme.appHeaderInverse ? 'app-header-inverse' : '')}>
			<div className="navbar-header">
				{theme.appSidebarTwo && (
					<button type="button" className="navbar-mobile-toggler" onClick={() => dispatch(toggleAppSidebarEndMobile())}>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
					</button>
				)}
				<Link to="/" className="navbar-brand"><span className="navbar-logo"></span> <b>Knowledge Maps</b>  v2.0 <Environment /></Link>
				
				

				{theme.appHeaderMegaMenu && (
					<button type="button" className="navbar-mobile-toggler" data-bs-toggle="collapse" data-bs-target="#top-navbar">
						<span className="fa-stack fa-lg text-inverse">
							<i className="far fa-square fa-stack-2x"></i>
							<i className="fa fa-cog fa-stack-1x"></i>
						</span>
					</button>
				)}
				{theme.appTopMenu && !theme.appSidebarNone && (
					<button type="button" className="navbar-mobile-toggler" onClick={() => dispatch(toggleAppTopMenuMobile())}>
						<span className="fa-stack fa-lg text-inverse">
							<i className="far fa-square fa-stack-2x"></i>
							<i className="fa fa-cog fa-stack-1x"></i>
						</span>
					</button>
				)}
				{theme.appSidebarNone && theme.appTopMenu && (
					<button type="button" className="navbar-mobile-toggler" onClick={() => dispatch(toggleAppTopMenuMobile())}>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
					</button>
				)}
				{!theme.appSidebarNone && (
					<button type="button" className="navbar-mobile-toggler" onClick={() => dispatch(toggleAppSidebarMobile())}>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
					</button>
				)}
			</div>

			{theme.appHeaderMegaMenu && (
				<DropdownMegaMenu />
			)}

			<div className="navbar-nav">
				{/* <SearchForm /> */}
				{/* <DropdownNotification /> */}

				{theme.appHeaderLanguageBar && (
					<DropdownLanguage />
				)}

				<DropdownProfile />

				{theme.appSidebarTwo && (
					<div className="navbar-divider d-none d-md-block"></div>
				)}

				{theme.appSidebarTwo && (
					<div className="navbar-item d-none d-md-block">
						<Link to="/" onClick={() => dispatch(toggleAppSidebarEnd())} className="navbar-link icon">
							<i className="fa fa-th"></i>
						</Link>
					</div>
				)}
			</div>
		</div>
	)

}
