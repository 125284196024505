import { ReactNode, useCallback, useEffect, useState } from "react";
import { SearchApi } from "../../store/api";
import EntityTypes from "../../models/entityTypes";
import { Link, generatePath } from "react-router-dom";
import useLabel from "../../util/useLabel";
import Paginated from "../../models/paginated";
import useDebounce from "../../util/useDebounce";
import { Panel, PanelBody } from "../panel/panel";
import { AttributeValueSummary } from "../attribute/attributeValueSummary";
import { CustomizableEntity } from "../../models/types";
import EntityTypeLabel from "../label/entityTypeLabel";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setRecordListFilter } from "../../store/uiSlice";
import { fetchAttributes, selectConfig } from "../../store/configSlice";
import { format } from "../../helpers/format";



export interface RecordFilter<TEntity> {
  key: string;
  label?: string;
  api: SearchApi<TEntity>;
}

interface RecordsListColumn<TEntity> {
  beforeAttributes?: boolean;
  label: string;
  headerClassNames?: string;
  callback: (record: TEntity) => ReactNode | ReactNode[];
  sortKey?: string;
}

export interface RecordsListParams<TEntity> {
  nameLabel?: string;
  showSearch?: boolean;
  showName?: boolean;
  showIfEmpty?: boolean;
  showAttributes?: boolean;
  type: EntityTypes;
  detailsRoute?: string;
  editRoute?: string;
  filters: RecordFilter<TEntity>[];
  canCreate?: boolean;
  columns?: RecordsListColumn<TEntity>[];
  actions?: (record: TEntity) => ReactNode | ReactNode[];
  sort?: string;
  sortDescending?: boolean;
  defaultSearch?: string;
  searchPlaceholder?: string;
}

export default function RecordsList<TEntity extends CustomizableEntity>(props: RecordsListParams<TEntity>) {
  const ui = useAppSelector(state => state.ui.recordList);
  const dispatch = useAppDispatch();
  const config = useAppSelector(selectConfig);
  const attributes = config.attributes[props.type]?.filter(attrib => attrib.isShownDefault);
  useEffect(() => { dispatch(fetchAttributes(props.type)) }, [dispatch, props.type]);

  const entityType = props.type;
  const label = useLabel(entityType);
  const showIfEmpty = props.showIfEmpty ?? true;

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<String>();
  const [filter, setFilter] = useState<RecordFilter<TEntity>>();
  const [searchTerm, setSearchTerm] = useState<string>(props.defaultSearch ?? "");
  const [prevSearchTerm, setPrevSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 800);

  const [paginated, setPaginated] = useState<Paginated<TEntity>>();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState(props.sort);
  const [sortDescending, setsortDescending] = useState(props.sortDescending);

  useEffect(() => {
    if (props.filters.length > 0) {
      if (ui[props.type]) {
        setFilter(props.filters.find(filter => filter.key === ui[props.type]?.selectedFilter) ?? props.filters[0]);
      }
      else {
        dispatch(setRecordListFilter(props.type, props.filters[0].key));
      }
    }
  }, [dispatch, props.filters, props.type, ui]);

  // const [filteredRecords, setFilteredRecords] = useState<RecordType[]>();
  // const loadAttributes = useCallback(() => {
  //   // Get the custom attributes to show as columns
  //   api.account.attributes.forEntity(entityType).then((attributes) => {
  //     setAttributes(attributes
  //       .filter((attrib) => attrib.isShownDefault && attrib.isActive)
  //     );
  //   });
  // }, [entityType]);

  const loadRecords = useCallback(() => {
    console.log("searching", filter, debouncedSearchTerm, page, perPage);
    if (page <= 0) {
      setError('Invalid page');
      return;
    }
    if (filter) {
      setIsLoading(true);
      filter?.api({ search: debouncedSearchTerm, page, perPage, sort, sortDescending })
        .then((records) => {
          setError(undefined);
          if (prevSearchTerm !== searchTerm) {
            setPage(1);
            setPrevSearchTerm(searchTerm);
          }
          setPaginated(records);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.error(e);
          setError("Unable to load " + label?.plural);
          setIsLoading(false);
        });
    }

    // do not include searchTerm or prevSearchTerm in order to support debounce
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, filter, label?.plural, page, perPage, sort, sortDescending]);

  useEffect(() => {
    loadRecords();
  }, [filter, debouncedSearchTerm, loadRecords]);

  const endRecordNumber = ((page - 1) * (paginated?.perPage ?? 0)) + (paginated?.records.length ?? 0);
  const totalRecords = paginated?.recordsTotal ?? 0;

  const onColumnClick = (column: RecordsListColumn<TEntity>): React.MouseEventHandler | undefined => {
    if (column.sortKey) {
      return () => {
        if (sort === column.sortKey) {
          setsortDescending((cur) => !(cur ?? false));
        }
        else {
          setSort(column.sortKey);
          setsortDescending(undefined);
        }
      };
    }
    return undefined;
  };

  if (!showIfEmpty && (0 === (paginated?.recordsTotal ?? 0))) {
    return <></>;
  }

  return (
    <Panel className="card border-0" isLoading={isLoading}>
      <PanelBody className="card-body">
        {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
        {(props.showSearch ?? true) && <div className="input-group mb-3">
          {filter?.label && <button className="btn btn-white dropdown-toggle" type="button" data-bs-toggle="dropdown"><span className="d-none d-md-inline">{filter.label ?? <EntityTypeLabel entityType={props.type} />}</span>{props.filters.length > 1 && <b className="caret"></b>}</button>}
          <div className="dropdown-menu">
            {props.filters && props.filters.map((thisFilter) => <button key={`filter-${thisFilter.key ?? thisFilter.label}`} className="dropdown-item" onClick={() => dispatch(setRecordListFilter(props.type, thisFilter.key))}>{thisFilter.label}</button>)}
          </div>
          <div className="flex-fill position-relative">
            <div className="input-group">
              <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{ zIndex: 10 }}>
                <i className="fa fa-search opacity-5"></i>
              </div>
              <input type="text" className="form-control px-35px bg-light" placeholder={props.searchPlaceholder ?? "Search " + label?.plural + "..."} onChange={(event) => setSearchTerm(event.target.value)} value={searchTerm} />
            </div>
          </div>
        </div>}
        {showIfEmpty && (paginated?.recordsTotal ?? 0) === 0 && <p className="text-center">
          There are no {label?.plural} that match your search/filter.
        </p>}
        {(paginated?.recordsTotal ?? 0) > 0 && <><div className="overflow-x-auto"><table className="table table-hover table-panel text-nowrap align-middle mb-0">
          <thead>
            <tr>
              {(props.showName ?? true) && <th>{props.nameLabel ?? "Name"}</th>}
              {props.columns && props.columns.filter((c) => c.beforeAttributes ?? false).map((col) => <th key={col.label} className={col.headerClassNames} onClick={onColumnClick(col)}>
                {col.label}
                {sort && col.sortKey === sort && (sortDescending ? <i className="ms-2 fas fa-caret-down"></i> : <i className="ms-2 fas fa-caret-up"></i>)}
              </th>)}
              {(props.showAttributes ?? true) && attributes?.map((attrib) => <th key={attrib.id}>{attrib.name}</th>)}
              {props.columns && props.columns.filter((c) => !(c.beforeAttributes ?? false)).map((col) => <th key={col.label} className={col.headerClassNames} onClick={onColumnClick(col)}>
                {col.label}
                {sort && col.sortKey === sort && (sortDescending ? <i className="ms-2 fas fa-caret-down"></i> : <i className="ms-2 fas fa-caret-up"></i>)}
              </th>)}
              {props.actions && <th>Action(s)</th>}
            </tr>
          </thead>
          <tbody>
            {paginated?.records.map(record => <tr key={`${record.id}`}>
              {(props.showName ?? true) && (props.detailsRoute ? <td><Link to={generatePath(props.detailsRoute, { id: `${record.id}` })}>{record.name}</Link> {record?.isActive === false && <span className="badge bg-danger">Inactive</span>}</td> : <td>{record.name} {record?.isActive === false && <span className="badge bg-danger">Inactive</span>}</td>)}
              {props.columns && props.columns.filter((c) => (c.beforeAttributes ?? false)).map((col) => col.callback(record))}
              {(props.showAttributes ?? true) && attributes?.map((attrib) => <td key={attrib.id}><AttributeValueSummary attribute={attrib} attributeValues={record.attributes} /></td>)}
              {props.columns && props.columns.filter((c) => !(c.beforeAttributes ?? false)).map((col) => col.callback(record))}
              {props.actions && <td>
                {props.actions(record)}
              </td>}
            </tr>)}
          </tbody>
        </table>
        </div>
          <div className="mt-1 d-flex flex-row align-items-center justify-content-around">
            <div>
              {format.number(1 + ((page - 1) * (paginated?.perPage ?? 0)))} &ndash; {format.number(endRecordNumber)} of {format.number(paginated?.recordsTotal ?? 0)} total
            </div>
            <div className="d-flex flex-row align-items-center">
              <div className="input-group input-group-sm">
                <span className="input-group-text" id="inputGroup-sizing-sm">Page</span>
                <button className="btn btn-outline-secondary btn-sm" disabled={page <= 1} onClick={() => setPage((page) => page - 1)}><i className="fa fa-angle-left"></i></button>
                <input type="text" min={1} step={1} className="form-control text-center w-50px" value={page} onChange={(e) => {
                  const newPage = parseInt(e.currentTarget.value);
                  if (newPage) {
                    setPage(newPage);
                  }
                }} />
                <button className="btn btn-outline-secondary btn-sm" disabled={endRecordNumber >= totalRecords} onClick={() => setPage((page) => page + 1)}><i className="fa fa-angle-right"></i></button>
              </div>
            </div>
            <div className="d-flex flex-row align-items-center">
              <div className="input-group input-group-sm">
                <span className="input-group-text" id="inputGroup-sizing-sm">Rows per page:</span>
                <select value={perPage} className="form-control form-control-sm" onChange={(e) => setPerPage(parseInt(e.currentTarget.value))}><option>10</option><option>50</option><option>100</option></select>
              </div>
            </div>
          </div>
        </>}
      </PanelBody>
    </Panel>
  );
}