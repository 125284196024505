import { useEffect, useState } from "react";
import AsyncTypeahead from "react-bootstrap-typeahead/types/components/AsyncTypeahead";
import { useController, useFormContext } from "react-hook-form";
import Partner from "../../models/partner";
import { api } from "../../store/api";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import 'react-bootstrap-typeahead/css/Typeahead.css';

type PartnerDropdownProps = {
  isRequired?: boolean;
  clearButton?: boolean;
  multiple?: boolean;
  allowNew?: boolean;
}
interface PartnerRefencingEntity {
  partner?: Partner;
}

export function PartnerDropdown(props: PartnerDropdownProps) {
  const labels = useAppSelector(selectLabels);
  const { register } = useFormContext<PartnerRefencingEntity>();
  const { field } = useController({
    name: 'partner',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Partner[]>([]);
  const [selected, setSelected] = useState<Partner[]>([]);

  useEffect(() => {
    console.log("field.value", field.value);
    if (field.value) {
      setOptions([]);
      setSelected([field.value]);
    }
  }, [field.value]);

  return (<div>
    <AsyncTypeahead
      allowNew={props.allowNew ?? false}
      newSelectionPrefix={`Create new ${labels.partner.singular}: `}
      //emptyLabel={`Select a ${labels.customer.singular}`}
      // defaultSelected={[field.]}
      options={options}
      id="partnerDropDown"
      labelKey={"name"}
      filterBy={['name']}
      selected={selected}
      minLength={0}
      multiple={props.multiple ?? false}
      isLoading={isLoading}
      clearButton={props.clearButton ?? true}
      {...register(`partner` as const, { required: props.isRequired })}
      onChange={(selected) => {
        console.warn('onChange', selected);
        const selectedCustomers = selected as Partner[];
        setSelected(selectedCustomers);
        if (selectedCustomers.length > 0) {
          field.onChange(selectedCustomers[0]);
        }
        else {
          field.onChange();
        }
      }
      }
      onSearch={function (search: string): void {
        setIsLoading(true);
        api.partners.search({search}).then((partners) => {
          setOptions(partners.records);
          setIsLoading(false);
        })
          .catch((reason) => {
            throw new Error("Error searching for " + labels.partner.plural + ": " + reason);
          });
      }}
    />
  </div>);
}