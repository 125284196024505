import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { logout } from "../../../store/sessionSlice";
//import { logout } from "../../../store/session/actions";

export default function DropdownProfile() {
	const dispatch = useAppDispatch();
	const session = useAppSelector(state => state.session);

	return (
		<div className="navbar-item navbar-user dropdown">
			<a href="#/" className="navbar-link dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown">
				<i className="fa fa-user-circle"></i>
				<span className="ps-2">
					<span className="d-none d-md-inline">{session.fullName}</span>
					<b className="caret"></b>
				</span>
			</a>
			<div className="dropdown-menu dropdown-menu-end me-1">
				<Link to="/user/profile" className="dropdown-item">Edit Profile</Link>
				<a href="#/" className="dropdown-item">Settings</a>
				<div className="dropdown-divider"></div>
				<button onClick={() => dispatch(logout())} className="dropdown-item">Log Out</button>
			</div>
		</div>
	);

};
