import { useEffect, useState } from "react";
import { Link, generatePath, useLocation, useParams } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import { api } from "../../store/api";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import RecordDetailsCard from "../../components/records/recordDetailsCard";
import Review from "../../models/review";
import { ReviewsRoute } from "./reviewsScreen";
import { ProjectDetailsRoute } from "../projects/projectDetailsScreen";
import { ResourceDetailsRoute } from "../resources/resourceDetailsScreen";
import { ReviewEditRoute } from "./reviewEditScreen";
import Resource from "../../models/resource";
import { selectConfig } from "../../store/configSlice";
import Tag from "../../models/tag";
import ReviewTag from "../../models/reviewTag";
import { TagBadge } from "../../components/tags/tagBadge";
import { setTitle } from "../../util/useDocumentTitle";
import { RequiresPermission } from "../../components/role/requiresRole";
import { PermissionTypes } from "../../models/permissionTypes";
import { usePermissions } from "../../util/usePermissions";
import { selectSession } from "../../store/sessionSlice";

export const ReviewDetailsRoute = "/reviews/:id";

interface ReviewDetailsRouteParams {
  id?: string;
}

export default function ReviewDetailsScreen() {
  const tags = useAppSelector(selectConfig).tags;
  const labels = useAppSelector(selectLabels);
  const [isLoading, setIsLoading] = useState(true);
  const [newlyCreated, setNewlyCreated] = useState(false);
  const [record, setRecord] = useState<Review>();
  const [resource, setResource] = useState<Resource>();
  const [error, setError] = useState<String>();
  const params = useParams<keyof ReviewDetailsRouteParams>();
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [canEdit, setCanEdit] = useState(false);
  const { hasPermission, isMine } = usePermissions();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      switch (location.hash) {
        case '#created':
          setNewlyCreated(true);
          break;
      }
    }
  }, [location.hash]);
  
  useEffect(() => {
    // Load the attribute and set the form with the current values
    setIsLoading(true);

    api.reviews.get(parseInt(params.id!))
      .then((record) => {
        setRecord(record);
        // fetch resource details
        api.resources.get(record.resourceId!).then(setResource);
      })
      .catch((reason) => {
        console.error(reason);
        setError("Unable to load " + labels.review.singular + ` #${params.id}`);
      });
  }, [labels.review.singular, params.id]);

  useEffect(() => {
    if (tags) {
      setSelectedTags(record?.tags?.map((reviewTag: ReviewTag) => tags!.find(t => reviewTag.tagId === t.id)!) ?? []);
    }
  }, [record?.tags, tags]);

  useEffect(() => {
    setTitle([`${labels.review.singular} Details`, record?.projectName, resource?.name]);
  }, [labels.review.singular, record, resource]);

  useEffect(() => {
    if (record) {
      setCanEdit((isMine(record) && hasPermission(PermissionTypes.ReviewEditMine))
        || hasPermission(PermissionTypes.ReviewEditAll));
    }
  }, [hasPermission, isMine, record]);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">{record?.resourceName} {record?.isActive === false && <span className="badge bg-danger">DISABLED</span>}</h1>
        <ul className="breadcrumb">
          <li className="breadcrumb-item"><Link to={generatePath(ReviewsRoute)}>{labels.review.plural}</Link></li>
          {record?.projectId && <li className="breadcrumb-item"><Link to={generatePath(ProjectDetailsRoute, { id: `${record?.projectId}` })}>{record?.projectName}</Link></li>}
          <li className="breadcrumb-item active">Details</li>
        </ul>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      <div className="col-md-4">
        <div className="card border-0 mb-4">
          <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">
            {labels.review.singular} Summary
            {canEdit && <Link to={generatePath(ReviewEditRoute, { id: `${record?.id}` }) + '#review'} className="ms-auto text-decoration-none text-gray-500"><i className="fa fa-edit fa-lg me-2 ms-n2"></i> Edit</Link>}
          </div>
          <div className="card-body fw-bold">
            <h6 className="card-subtitle text-muted">{labels.reviewer.singular}</h6>
            <p className="card-text">
              {record?.userName}
            </p>
            <h6 className="card-subtitle text-muted">{labels.project.singular}</h6>
            <p className="card-text">
              <Link to={generatePath(ProjectDetailsRoute, { id: `${record?.projectId}` })}>{record?.projectName}</Link>
            </p>

          </div>
        </div>

        {resource && <RecordDetailsCard
          record={resource}
          detailsRoute={ResourceDetailsRoute}
          type={EntityTypes.Resource}
        />}
      </div>
      <div className="col-md-8">
        {newlyCreated && <div className="alert alert-success">
          <strong>{labels.review.singular} complete!</strong> &nbsp;
          <Link to={generatePath(ProjectDetailsRoute, { id: record?.projectId?.toString() ?? '' })+'#todo'}>
            You can now return to the {record?.projectName} {labels.project.singular} and continue your work.
          </Link>
          </div>}
        {record && <RecordDetailsCard
          editRoute={generatePath(ReviewEditRoute, { id: `${record?.id}` }) + '#review'}
          record={record}
          attributeValues={[
            ...resource?.attributes ?? [],
          ]}
          type={EntityTypes.Review}
          colClassName="col-md-6"
        />}
        {selectedTags && <div className="card border-0 mb-4">
          <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">
            {labels.tag.plural}
            <Link to={generatePath(ReviewEditRoute, { id: `${record?.id}` }) + '#tag'} className="ms-auto text-decoration-none text-gray-500"><i className="fa fa-edit fa-lg me-2 ms-n2"></i> Edit</Link>
          </div>
          <div className="card-body fw-bold">
            {selectedTags.map((tag) => <li className="d-flex flex-row mb-1" key={tag.id}>
              <TagBadge key={tag.id} tagId={tag.id!} />
            </li>)}
          </div>
        </div>}
      </div>
    </div>
  </div>);
}
