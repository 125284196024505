import React, { useEffect, useState } from 'react';
import { useResolvedPath, useMatch, NavLink, useLocation, matchPath } from 'react-router-dom';
import { selectTheme } from '../../store/themeSlice';
import { useAppSelector } from '../../store/hooks';
import { Menu } from '../../store/menu';
import { selectMenu } from '../../store/menuSlice';
import { selectSession } from '../../store/sessionSlice';
import EntityTypeLabel from '../label/entityTypeLabel';

type NavItemProps = {
	menu: Menu;
	isExpanded?: boolean;
};



export function NavItem(props: NavItemProps) {
	const {
		menu
	} = props;
	const session = useAppSelector(selectSession);
	const location = useLocation();
	const resolved = useResolvedPath(menu.path ?? '');

	const match = useMatch({ path: resolved.pathname ?? '' });
	const match2 = matchPath({ path: menu.path ?? '', end: false, }, location.pathname);
	const [isMatch, setIsMatch] = useState(false);
	const [isExpanded, setIsExpanded] = useState(props.isExpanded ?? false);

	useEffect(() => {
		const isPathMatch = (menu: Menu): boolean => {
			if (menu.path) {
				if (match) {
					console.log('path match', menu, match, match2);
					return true;
				}
				return false;
			}
			else if (menu.children) {
				return menu.children.find((child) => isPathMatch(child)) !== undefined;
			}
			return false;
		}

		if (menu.path) {
			if (match || match2) {
				setIsMatch(true);
			}
			else {
				setIsMatch(false);
			}
		}
		else if (menu.children) {
			setIsMatch(menu.children.find((child) => isPathMatch(child)) !== undefined);
		}
	}, [location, match, match2, menu.children, menu.path]);

	let icon = menu.icon && <div className="menu-icon"><i className={menu.icon}></i></div>;
	let img = menu.img && <div className="menu-icon-img"><img src={menu.img} alt="" /></div>;
	let caret = (menu.children && !menu.badge) && <div className="menu-caret"></div>;
	let label = menu.label && <span className="menu-label ms-5px">{menu.label}</span>;
	let badge = menu.badge && <div className="menu-badge">{menu.badge}</div>;
	let highlight = menu.highlight && <i className="fa fa-paper-plane text-theme"></i>;
	let title = menu.title && <div className="menu-text">{menu.title} {label} {highlight}</div>;
	if (menu.entityType) {
		title = <div className="menu-text"><EntityTypeLabel entityType={menu.entityType} /> {label} {highlight}</div>;
	}

	if (menu.roles) {
		if (!menu.roles.every((requiredRole) =>
			(session.roles?.findIndex((userRole) => userRole === requiredRole) ?? -1) >= 0)
		) {
			return (<div></div>);
		}
	}

	return (
		<div className={'menu-item' + ((isMatch || isExpanded) ? ' active' : '') + (menu.children ? ' has-sub' : '')}>
			{menu.path
				? <NavLink className="menu-link" to={menu.path} relative="route">
					{img} {icon} {title}{caret} {badge}
				</NavLink>
				: <a href='#body' className={'menu-link' + ((isMatch || isExpanded) ? ' active' : '') + (menu.children ? ' has-sub' : '')} onClick={(e) => { e.preventDefault(); setIsExpanded(!isExpanded); }}>{img} {icon} {title}{caret} {badge}</a>}

			{menu.children && (
				<div className="menu-submenu">
					{menu.children.map((submenu, i) => (
						<NavItem key={i} menu={submenu} />
					))}
				</div>
			)}
		</div>
	);
}

export default function SidebarNav() {
	const theme = useAppSelector(selectTheme);
	const menu = useAppSelector(selectMenu);
	const handleSidebarSearch = (e: React.KeyboardEvent) => {
		throw new Error('Function not implemented.');
	}

	return (
		<div className="menu">
			{theme.appSidebarSearch && (
				<div className="menu-search mb-n3">
					<input type="text" className="form-control" placeholder="Sidebar menu filter..." onKeyUp={handleSidebarSearch} />
				</div>
			)}
			<div className="menu-header">Navigation</div>
			{menu.map((menu, i) => (
				<NavItem key={i} menu={menu} />
			))}
		</div>
	);
}

//export default SidebarNav;