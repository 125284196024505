import { useState } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom"
import { api } from "../../store/api";
import { useAppSelector } from "../../store/hooks";
import { Panel, PanelBody } from "../../components/panel/panel";
import labelsSlice, { LabelValue, selectLabels } from "../../store/labelsSlice";

export const ROUTE = "/settings/labels";

interface LabelFormParams {
	id: string;
}
function LabelForm(props: LabelFormParams) {
	const navigate = useNavigate();
	const labels = useAppSelector(selectLabels);
	const [isEditing, setIsEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [defaultValue, setDefaultValue] = useState<LabelValue>();
	const [error, setError] = useState<String>();
	const [success, setSuccess] = useState<String>();
	const params = useParams<keyof LabelFormParams>();
	const labelType = props.id.toLowerCase() as keyof typeof labelsSlice;

	const fetchRecord = async (): Promise<LabelValue> => new Promise<LabelValue>((resolve) => {
		
		resolve(labels[labelType]);
		setIsEditing(true);
		setIsLoading(false);
	});
	const form = useForm<LabelValue>({
		defaultValues: fetchRecord,
	});

	const { register, handleSubmit, reset, formState: { errors }, control } = form;


	const onSubmit: SubmitHandler<LabelValue> = data => {
		const request: LabelValue = {
			singular: data.singular,
			plural: data.plural,
			};
		setIsLoading(true);
		api.settings.labels.update(labelType, request)
			.then((record) => {
				//navigate(PartnersRoute);
				setIsLoading(false);
				setError(undefined);
				setSuccess("Label updated!");
			})
			.catch((reason) => {
				setError("Unable to save label: " + reason);
				setIsLoading(false);
				setSuccess(undefined);
			});
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Panel className="card border-0" isLoading={isLoading}>
					<PanelBody className="card-body">
						<h4 className="mb-0">{props.id}</h4>
						{error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
						{success && <div className="alert alert-success"><strong>Success!</strong> {success}</div>}
						<div className="row mb-15px">
							<label className="form-label col-form-label col-md-3">Singular</label>
							<div className="col-md-9">
								<input type="text" className={"form-control mb-5px " + (errors.singular ? 'is-invalid' : '')} {...register("singular", { required: true })} />
								{errors.singular && <div className="invalid-feedback">This field is required</div>}
							</div>
						</div>
						<div className="row mb-15px">
							<label className="form-label col-form-label col-md-3">Plural</label>
							<div className="col-md-9">
								<input type="text" className={"form-control mb-5px " + (errors.plural ? 'is-invalid' : '')} {...register("plural", { required: true })} />
								{errors.plural && <div className="invalid-feedback">This field is required</div>}
							</div>
						</div>
						<div className="row mb-0">
							<div className="offset-md-3 col-md-9 pt-2">
								<button type="submit" className="btn btn-primary w-100px me-5px">Save</button>
								<button type="button" onClick={() => navigate(-1)} className="btn btn-default w-100px">Cancel</button>
							</div>
						</div>
					</PanelBody>
				</Panel>
			</form>
		</FormProvider>
	)
}

export function LabelsScreen() {
	return (
		<div>
			<ol className="breadcrumb float-xl-end">
				<li className="breadcrumb-item"><Link to="..">Settings</Link></li>
				<li className="breadcrumb-item active">Labels</li>
			</ol>

			<h1 className="page-header">Edit Labels</h1>
			<div className="row">
				<div className="col-md-6">
					<LabelForm id="Partner" />
				</div>
				<div className="col-md-6">
					<LabelForm id="Project" />
				</div>
				<div className="col-md-6">
					<LabelForm id="Contact" />
				</div>
				<div className="col-md-6">
					<LabelForm id="Resource" />
				</div>
				<div className="col-md-6">
					<LabelForm id="User" />
				</div>
				<div className="col-md-6">
					<LabelForm id="UnitEvaluation" />
				</div>
			</div>
		</div>
	);
}