import { generatePath, useNavigate } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import { api, SearchProps } from "../../store/api";
import RecordsListScreen from "../recordsListScreen";
import { ResourceDetailsRoute } from "./resourceDetailsScreen";
import useLabel from "../../util/useLabel";
import Resource from "../../models/resource";

export const ResourcesRoute = "/resources";

export default function ResourcesScreen() {
  const navigate = useNavigate();
  const label = useLabel(EntityTypes.Resource);

  return <RecordsListScreen
    type={EntityTypes.Resource}
    detailsRoute={ResourceDetailsRoute}
    filters={[
      { key: 'active', label: 'Active', api: (search: SearchProps) => api.resources.search({ ...search, active: true }) },
      { key: 'mine', label: `My ${label.plural}`, api: api.resources.searchMine },
      //{ key: 'inactive', label: `Inactive ${label.plural}`, api: api.resources.search },
      { key: 'inactive', label: 'Inactive', api: (search: SearchProps) => api.resources.search({ ...search, active: false }) },
      { key: 'all', label: `All ${label.plural}`, api: (search: SearchProps) => api.resources.search({ ...search, active: null }) },
    ]}
    // columns={[
    //   {
    //     beforeAttributes: true,
    //     label: "Status",
    //     callback: (record: Resource) => <td>{record.isActive ? 'Active' : <span className="badge bg-danger">Inactive</span>}</td>,
    //   },
    // ]}
    actions={(record) => <>
      <button className="btn btn-sm btn-outline-primary mx-1" onClick={() => navigate(generatePath(ResourceDetailsRoute, { id: `${record.id}` }))}>Details</button>
    </>}
    />;
}