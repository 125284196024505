
export default interface Tag {
  id?: number;
  knowledgeMapTypes?: number;
  label?: string;
  description?: string;
  parentTagId?: number;
  parentTag?: Tag;
  level?: number;
  isActive?: boolean;
  usersCanAddSubTags?: boolean;
  sortOrder?: number;
}

export const tagIsDescendant = (tags: Tag[], tag: Tag, child: Tag): boolean => {
  if (child.parentTagId === tag.id) {
    return true;
  }
  return tags?.filter(t => t.parentTagId === tag.id).some((t) => tagIsDescendant(tags, t, child)) ?? false;
};

export const tagHasDescendantwithId = (tags: Tag[], tag: Tag, anyTagWithId: number[]): boolean => {
  if (anyTagWithId.includes(tag.id ?? -1)) {
    return true;
  }
  const subTags = tags?.filter(t => t.parentTagId === tag.id) ?? [];
  return subTags?.some((t) => tagHasDescendantwithId(tags, t, anyTagWithId));
};
export const tagHasAncestorWithId = (tags: Tag[], tag: Tag, anyTagWithId: number[]): boolean => {
  if (anyTagWithId.includes(tag.id ?? -1)) {
    return true;
  }
  const parentTags = tags?.filter(t => t.id === tag.parentTagId) ?? [];
  return parentTags?.some((t) => tagHasAncestorWithId(tags, t, anyTagWithId));
};

export const tagIsAncestor = (tags: Tag[], tag: Tag, parent: Tag): boolean => {
  if (parent.id === tag.parentTagId) {
    return true;
  }
  return tags?.filter(t => t.id === tag.parentTagId).some((t) => tagIsAncestor(tags, t, parent)) ?? false;
};

export const tagOrAncestorIsUserManaged = (tags: Tag[], tag: Tag): boolean => {
  if (tag.usersCanAddSubTags) {
    return true;
  }
  if (tag.parentTagId) {
    const parentTag = tags.find(t => t.id === tag.parentTagId);
    if (parentTag) {
      return tagOrAncestorIsUserManaged(tags, parentTag);
    }
  }
  return false;
}