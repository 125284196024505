import React from 'react';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SidebarMinifyBtn from './sidebar-minify-btn';
import SidebarProfile from './sidebar-profile';
import SidebarNav from './sidebar-nav';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectTheme, toggleAppSidebarMobile } from '../../store/themeSlice';

export default function Sidebar() {
	const theme = useAppSelector(selectTheme);
	const dispatch = useAppDispatch();

	return (
		<React.Fragment>
			<div id="sidebar" className={'app-sidebar ' + (theme.appSidebarTransparent ? 'app-sidebar-transparent' : '') + (theme.appSidebarGrid ? 'app-sidebar-grid' : '')}>
				<PerfectScrollbar className="app-sidebar-content" options={{ suppressScrollX: true }}>
					{!theme.appSidebarSearch && (<SidebarProfile />)}
					<SidebarNav />
					<SidebarMinifyBtn />
				</PerfectScrollbar>
			</div>
			<div className="app-sidebar-bg"></div>
			<div className="app-sidebar-mobile-backdrop"><Link to="/" onClick={() => dispatch(toggleAppSidebarMobile())} className="stretched-link"></Link></div>
		</React.Fragment>
	);
}