import React, { ReactNode } from 'react';

const PanelState = React.createContext({
	isLoading: false,
});

type PanelProps = {
	isLoading?: boolean;
	className?: string;
	children?: ReactNode | ReactNode[];
};
export function Panel(props: PanelProps) {
	const isLoading = props.isLoading ?? false;
	return (
		<PanelState.Provider value={{isLoading}}>
			<div className={"panel panel-inverse " + (props.className ?? '') + (isLoading ? ' panel-loading' : '')}>
				{props.children}
			</div>
		</PanelState.Provider>
	);
}

export function PanelBody(props: PanelProps) {
	return (
		<PanelState.Consumer>
			{({isLoading}) => 
			<div className={"panel-body " + (props.className ?? '')}>
					{props.children}
					{isLoading && <div className="panel-loader">
						<span className="spinner spinner-sm"></span>
					</div>}
			</div>
}
		</PanelState.Consumer>
	);
}

type PanelHeaderProps = {
	isLoading?: boolean;
	className?: string;
	children?: ReactNode | ReactNode[];
	noButton?: boolean;
};
export function PanelHeader(props: PanelHeaderProps) {
	return (
		<div className={'panel-heading ' + props.className}>
			<h4 className="panel-title">{props.children}</h4>
			{/* {(!props.noButton &&
				<PanelState.Consumer>
					{() => (
						<div className="panel-heading-btn">
							<button className="btn btn-xs btn-icon btn-circle btn-default" onClick={toggleExpanded}><i className="fa fa-expand"></i></button>&nbsp;&nbsp;
							<button className="btn btn-xs btn-icon btn-circle btn-success" onClick={toggleReload}><i className="fa fa-redo"></i></button>&nbsp;&nbsp;
							<button className="btn btn-xs btn-icon btn-circle btn-warning" onClick={toggleCollapse}><i className="fa fa-minus"></i></button>&nbsp;&nbsp;
							<button className="btn btn-xs btn-icon btn-circle btn-danger" onClick={toggleRemove}><i className="fa fa-times"></i></button>
						</div>
					)}
				</PanelState.Consumer>
			)} */}
		</div>
	);
} 

export function PanelFooter(props: PanelProps) {
	return <div className={`panel-footer ${props.className}`}>{props.children}</div>;
}
