import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { config } from '../config';
import GradeTypes from '../models/gradeTypes';
import KnowledgeMapTypes from '../models/knowledgeMapTypes';

export interface LabelValue {
	singular: string;
	plural: string;
}
type GradeLabels = {
	[key in GradeTypes]: string;
};
type KnowledgeMapLabels = {
	[key in KnowledgeMapTypes]: string;
};
export interface LabelsState {
	partner: LabelValue;
	contact: LabelValue;
	project: LabelValue;
	resource: LabelValue;
	user: LabelValue;
	review: LabelValue;
	projectResource: LabelValue;
	reviewer: LabelValue;
	tag: LabelValue;
	grades: GradeLabels;
	knowledgeMapTypes: KnowledgeMapLabels;
	unitevaluation: LabelValue;
	//tagLevel: [N]: string;
}

const initialState: LabelsState = {
	partner: {
		singular: 'Partner',
		plural: 'Partners',
	},
	contact: {
		singular: 'Contact',
		plural: 'Contacts',
	},
	project: {
		singular: 'Project',
		plural: 'Projects',
	},
	resource: {
		singular: 'Text',
		plural: 'Texts',
	},
	user: {
		singular: 'User',
		plural: 'Users',
	},
	review: {
		singular: 'Review',
		plural: 'Reviews',
	},
	reviewer: {
		singular: 'Reviewer',
		plural: 'Reviewers',
	},
	projectResource: {
		singular: 'Project Resource',
		plural: 'Project Resources',
	},
	unitevaluation: {
		singular: 'Unit Evaluation',
		plural: 'Unit Evaluations',
	},
	tag: {
		singular: 'Tag',
		plural: 'Tags',
	},
	grades: {
		[GradeTypes.PreK]: 'PK',
		[GradeTypes.K]: 'K',
		[GradeTypes.Grade1]: '1',
		[GradeTypes.Grade2]: '2',
		[GradeTypes.Grade3]: '3',
		[GradeTypes.Grade4]: '4',
		[GradeTypes.Grade5]: '5',
		[GradeTypes.Grade6]: '6',
		[GradeTypes.Grade7]: '7',
		[GradeTypes.Grade8]: '8',
		[GradeTypes.Grade9]: '9',
		[GradeTypes.Grade10]: '10',
		[GradeTypes.Grade11]: '11',
		[GradeTypes.Grade12]: '12',
		[GradeTypes.NA]: 'N/A',
	},
	knowledgeMapTypes: {
		[KnowledgeMapTypes.ELA]: 'English Language Arts',
		[KnowledgeMapTypes.Undefined]: 'Undefined',
		[KnowledgeMapTypes.SocailStudies]: 'Social Studies',
		[KnowledgeMapTypes.Math]: 'Math',
		[KnowledgeMapTypes.Science]: 'Science'
	},
};

export const labelsSlice = createSlice({
	name: 'labels',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
	},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: (builder) => {
		builder
			.addCase(fetchLabels.pending, (state) => {
				//state.status = 'loading';
			})
			.addCase(fetchLabels.fulfilled, (state, action) => {
				console.warn('fetchLabels.fulfilled', action.payload);
				return {
					...state,
					...action.payload,
				};
			})
			.addCase(fetchLabels.rejected, (state) => {
				//state.status = 'failed';
			});
	},
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectLabels = (state: RootState) => state.labels;

export const fetchLabels = createAsyncThunk('labels/fetch', async () => {
	const authHeader = new Headers();
	authHeader.append("Content-Type", "application/json");
	const requestOptions: RequestInit = {
		method: "GET",
		headers: authHeader,
		credentials: 'include',
	};
	const response = await fetch(`${config.apiUrl}/v1/labels`, requestOptions);
	return (await response.json()) as LabelsState;
});

export default labelsSlice.reducer;
