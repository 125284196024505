import { Middleware } from "redux"
import { RootState } from "../store"
import * as signalR from "@microsoft/signalr"; 
import { identity } from "../store/sessionSlice";

// class Realtime {
//   private connection: signalR.HubConnection;
//   public events: (onMessageReceived: (username: string, message: string) => void) => void;
//   static instance: Realtime;
//   constructor() {
//     this.connection = new signalR.HubConnectionBuilder()
//       .withUrl(config.realtimeUrl)
//       .withAutomaticReconnect()
//       .build();
//     this.connection.start().catch(err => document.write(err));
//     this.events = (onMessageReceived) => {
//       this.connection.on("messageReceived", (username, message) => {
//         onMessageReceived(username, message);
//       });
//     };
//   }
//   public newMessage = (messages: string) => {
//     this.connection.send("newMessage", "foo", messages).then(x => console.log("sent"))
//   }
//   public static getInstance(): Realtime {
//     if (!Realtime.instance)
//       Realtime.instance = new Realtime();
//     return Realtime.instance;
//   }
// }

export const realtimeMiddleware = (url:string): Middleware<
  {}, // Most middleware do not modify the dispatch return value
  RootState
> => {
  let connection: signalR.HubConnection;

  return storeApi => next => action => {
    const state = storeApi.getState() // correctly typed as RootState
    console.log('realtimeMiddleware', action);
    switch (action.type) {
      case 'session/identity/fulfilled':
        connection = new signalR.HubConnectionBuilder()
          .withUrl(url)
          .withAutomaticReconnect()
          //.configureLogging(signalR.LogLevel.Trace)
          .build();
        connection.start()
          .catch(err => console.error("Realtime error: ", err));
        connection.on("dispatch", (type, action) => {
          console.log('onDispatch', type, action);

          storeApi.dispatch({
            type,
            payload: action,
          });
        });
        break;
    }
    return next(action);
  }
} 

// export const realtimeMiddleware = (url: String) => {
//   let socket;
//   let echo;

//   return storeAPI => next => action => {
//     switch (action.type) {
//       // Connect after user is authenticated
//       case 'user/login/fulfilled': {
//         socket = socketIOClient(`${url}:3010/socket`);

//         // Create a new Echo class instance
//         echo = new Echo({
//           host: `${url}:6001`,
//           broadcaster: 'socket.io',
//           client: socketIOClient,
//           auth: {
//             headers: {
//               Authorization: axios.defaults.headers['Authorization'],
//             },
//           },
//         });

//         // Connect and listen
//         echo.private('transport.1').listen('.order-pending', ev => {
//           storeAPI.dispatch(getData(ev));
//         });
//         break;
//       }

//       // Send data
//       case 'socket/sendData': {
//         socket.emit('order-pending', action.payload);
//         break;
//       }

//       // Disconnect
//       case 'socket/disconnect': {
//         echo.disconnect();
//         break;
//       }
//     }
//     return next(action);
//   };
// };