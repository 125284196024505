import { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Resource from "../../models/resource";
import { NewReferenceEntity } from "../reference/referenceDropdown";
import { api } from "../../store/api";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";

type ResourceCloneTypeaheadProps = {
  onSelected: (resource: Resource) => void;
};

export default function ResourceCloneTypeahead(props: ResourceCloneTypeaheadProps) {
  const labels = useAppSelector(selectLabels);
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState<Resource[]>([]);
  const [selectedResource, setSelectedResource] = useState<Resource[]>([]);
  
  return <AsyncTypeahead
    className="flex-grow-1"
    options={resources}
    id="resourceId"
    useCache={false}
    labelKey={"name"}
    filterBy={['name']}
    allowNew={false}
    newSelectionPrefix="Create new: "
    selected={selectedResource}
    multiple={false}
    minLength={0}
    clearButton={true}
    delay={600}
    // onInputChange={(text, e) => setResourceSearchTerm(text)}
    isLoading={isLoading}
    onChange={(selected) => {
      const selectedEntities = selected as Resource[];
      console.log('onChange', selected);
      if (selectedEntities.length > 0) {
        const asCustomOption = selectedEntities[selectedEntities.length - 1] as NewReferenceEntity;
        if (asCustomOption.customOption) {
          console.warn('customOption', selectedEntities[selectedEntities.length - 1]);
          //setNewOption(asCustomOption);
          //setShowAddNew(true);
          return;
        }
        // const selectedIds = selectedEntities!.map(s => s.id ?? '')
        setSelectedResource(selectedEntities);
        props.onSelected(selectedEntities[0]);
        // setValue('resourceId', selectedEntities[0].id);
        // field.onChange(selectedEntities);
      }
      else {
        setSelectedResource([]);
        //setValue('resourceId', undefined);
        // field.onChange();
      }
    }
    }
    onSearch={function (search: string): void {
      setIsLoading(true);
      // setResourceSearchTerm(search);
      api.resources.search({ search }).then((results) => {
        setResources(results.records);
        setIsLoading(false);
      })
        .catch((reason) => {
          throw new Error(`Error searching for ${labels.resource.singular} : ${reason}`);
        });
    }}
  />;
}