import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import Account from "../../models/account";
import { api } from "../../store/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectTheme } from "../../store/themeSlice";
import { AbsoluteRoute as HomeScreenRoute } from "../homeScreen";

export const ROUTE = "/accounts";

export function AccountsScreen() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const theme = useAppSelector(selectTheme);
	const [accounts, setAccounts] = useState<Account[]>();
	const loadAccounts = useCallback(() => {
		api.accounts.get()
			.then((accounts) => {
				//if (accounts.length === 1) {
				//	dispatch(switchToAccount(accounts[0]));
				//	navigate(HomeScreenRoute);
				//}
				setAccounts(accounts);
			})
			.catch((e: Error) => {
				console.error(e);
			});
		//throw new Error("Function not implemented.");
	}, []);

	const handleSelectAccount = (account: Account) => {
		// dispatch(switchToAccount(account));
		navigate(HomeScreenRoute(account.id!));
	};

	// Load the user's list of accounts
	useEffect(() => {
		loadAccounts();
	}, [loadAccounts]);


	return (
		<div>
			<ol className="breadcrumb float-xl-end">
				<li className="breadcrumb-item active">Your Accounts</li>
			</ol>
			<h1 className="page-header mb-0">Select an Account</h1>
			<div>
				<div>
					<div className="col-xl-12 col-lg-6">
						<div className="mb-10px mt-10px fs-10px">
							<b className="text-dark">YOUR ACCOUNTS</b>
						</div>
						<div className={'widget-list rounded mb-4 ' + (theme.appDarkMode ? 'dark-mode ' : '')}>
							{accounts?.map((account) => <button key={account.id} onClick={() => handleSelectAccount(account)} className="btn d-flex flex-row align-items-center text-start w-100">
								<div className="flex-grow-1">
									<h4 className="widget-list-title">{account.name}</h4>
									<p>{account.description}</p>
								</div>
								<div className="">
									<i className="fa fa-angle-right fa-lg text-gray-500"></i>
								</div>
							</button>)}
							{(accounts?.length ?? 0) === 0 && <div className="text-center p-4">
								You do not have access to any accounts. Please contact your administrator.
							</div>}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

