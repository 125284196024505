import { LabelValue, LabelsState, selectLabels } from '../store/labelsSlice';
import { useAppSelector } from '../store/hooks';
import EntityTypes from '../models/entityTypes';

export const labelFor = (labels: LabelsState, entityType: EntityTypes): LabelValue => {
  switch (entityType) {
    case EntityTypes.ProjectResource:
      return labels.projectResource;
    case EntityTypes.Project:
      return labels.project;
    case EntityTypes.Partner:
      return labels.partner;
    case EntityTypes.Contact:
      return labels.contact;
    case EntityTypes.Resource:
      return labels.resource;
    case EntityTypes.User:
      return labels.user;
    case EntityTypes.Review:
      return labels.review;
    case EntityTypes.Reviewer:
      return labels.reviewer;
    case EntityTypes.Tag:
      return labels.tag;
    case EntityTypes.UnitEvaluation:
      return labels.unitevaluation;
  }
  throw new Error("Invalid Entity Type");

}

function useLabel(entityType: EntityTypes): LabelValue {
  const labels = useAppSelector(selectLabels);
  return labelFor(labels, entityType);
}

export default useLabel;
