import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import Project from "../../models/project";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { api } from "../../store/api";
import { useEffect, useRef, useState } from "react";
import EntityTypeLabel from "../../components/label/entityTypeLabel";
import EntityTypes from "../../models/entityTypes";
import ProjectGrade from "../../models/projectGrade";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import * as Highcharts from 'highcharts';
import highchartsHeatmap from "highcharts/modules/heatmap";
import highchartsExporting from "highcharts/modules/exporting";
import highchartsDrilldown from "highcharts/modules/drilldown";
import HighchartsReact from "highcharts-react-official";
import Tag from "../../models/tag";
import GradeTypes from "../../models/gradeTypes";
import { setTitle } from "../../util/useDocumentTitle";
import { Link, generatePath, useSearchParams } from "react-router-dom";
import { ProjectDetailsRoute } from "../projects/projectDetailsScreen";
import { RequiresPermission } from "../../components/role/requiresRole";
import { PermissionTypes } from "../../models/permissionTypes";
import { selectSession } from "../../store/sessionSlice";

export const HeatmapReportRoute = "/reports/heatmap";
type HeatmapReportScreenProps = {
  projectId?: number;
  project?: Project;
}

export default function HeatmapReportScreen(props: HeatmapReportScreenProps) {
  const labels = useAppSelector(selectLabels);
  const [searchParams, setSearchParams] = useSearchParams();
  const projectIdStr = searchParams.get('project');
  const session = useAppSelector(selectSession);

  // highchartsTreemap(Highcharts);
  // highchartsTreegraph(Highcharts);
  highchartsExporting(Highcharts);
  highchartsDrilldown(Highcharts);

  const [hideSelector, setHideSelector] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState<Project>();
  const [projects, setProjects] = useState<Project[] | undefined>([]);
  const [allDomains, setAllDomains] = useState(true);
  const [allGrades, setAllGrades] = useState(true);
  const [availableDomains, setAvailableDomains] = useState<Tag[]>();
  const [selectedDomains, setSelectedDomains] = useState<Tag[]>();
  const [domains, setDomains] = useState<Tag[]>();
  // const [grade, setGrade] = useState<ProjectGrade>();
  const [grades, setGrades] = useState<ProjectGrade[]>([]);
  const [selectedGrades, setSelectedGrades] = useState<GradeTypes[]>();

  const [error, setError] = useState<String>();

  useEffect(() => {
    console.log('setting project', props.project);
    if (props.project) {
      setProject(props.project);
    }
  }, [props.project]);

  useEffect(() => {
    if (projectIdStr) {
      api.projects.get(parseInt(projectIdStr)).then((project) => {
        setProject(project);
      });
    }
  }, [projectIdStr, props.projectId]);

  useEffect(() => {
    if (project) {
      api.project(project.id!).grades().then(setGrades);
      setSelectedDomains([]);
      setSelectedGrades([]);
      api.reports.heatmap(project!.id!).domains().then(setAvailableDomains);
    }
    else {
      setAvailableDomains(undefined);
      setSelectedDomains(undefined);
      setDomains(undefined);
      // setGrades([]);
    }
  }, [project]);

  const onGenerateReport = (): React.MouseEventHandler | undefined => {
    //setIsLoading(true);
    if (availableDomains) {
      if (allDomains) {
        setDomains(availableDomains);
      }
      else {
        setDomains(selectedDomains);
      }
      // api.reports.heatmap(project!.id!).domains().then((domains) => {
      //   setDomains(domains);
      //   setIsLoading(false);
      // });
    }
    else {
      setDomains(undefined);
    }
    return undefined;
  };


  // Title
  useEffect(() => {
    setTitle([
      project ? project.name : '',
      `Heatmap Report`,
    ]);
  }, [project]);

  const toggleSelectedDomain = (tag: Tag) => {
    if (selectedDomains) {
      if (selectedDomains.find(t => t.id == tag.id)) {
        // remove
        setSelectedDomains(selectedDomains.filter(t => t.id != tag.id));
      }
      else {
        setSelectedDomains([...selectedDomains, tag]);
      }
    }
    else {
      setSelectedDomains([tag]);
    }
  };

  const toggleSelectedGrade = (grade: ProjectGrade) => {
    if (selectedGrades) {
      if (selectedGrades.find(t => t === grade.grade)) {
        // remove
        setSelectedGrades(selectedGrades.filter(t => t !== grade.grade));
      }
      else {
        setSelectedGrades([...selectedGrades, grade.grade]);
      }
    }
    else {
      setSelectedGrades([grade.grade]);
    }
  };

  const canGenerateReport = ((availableDomains?.length ?? 0) > 0)
    && (allDomains || (selectedDomains?.length ?? 0) > 0)
    && (allGrades || (selectedGrades?.length ?? 0) > 0);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">Heatmap Reporting</h1>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">Reports</li>
          {!hideSelector && project && (session.shared === undefined) && <li className="breadcrumb-item"><Link to={generatePath(ProjectDetailsRoute, { id: `${project?.id}` })} >{project.name}</Link></li>}
          <li className="breadcrumb-item active">Heatmap Report</li>
        </ul>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    {hideSelector && <div className="col">
      <button className="p-0 btn" onClick={() => setHideSelector(false)}><i className="fas fa-gear"></i></button>
    </div>}

    <div className="row">
      {!hideSelector && <div className="col">
        <div className="card border-0 mb-4">
          <div className="card-header bg-none d-flex flex-row justify-content-between align-items-center">
            <h6>Report Parameters</h6>
            <button className="p-0 btn" onClick={() => setHideSelector(true)}><i className="fas fa-eye-slash"></i></button>
          </div>
          <div className="card-body">
            <div className="row mb-15px">
              <RequiresPermission permission={PermissionTypes.ProjectSearch}>
                <label className="form-label col-form-label col-xl-3 text-nowrap"><EntityTypeLabel entityType={EntityTypes.Project} singlular /></label>
                <div className="col-xl-9">
                  <AsyncTypeahead
                    options={projects ?? []}
                    //disabled={partner === undefined}
                    id="projectDropDown"
                    labelKey={"name"}
                    filterBy={['name']}
                    selected={project ? [project] : undefined}
                    minLength={0}
                    isLoading={projects === undefined}
                    clearButton={true}
                    onSearch={function (search: string): void {
                      setProjects(undefined);
                      api.projects.search({ search }).then((result) => {
                        setProjects(result.records);
                      })
                        .catch((reason) => {
                          throw new Error("Error searching for " + labels.project.plural + ": " + reason);
                        });
                    }}
                    onChange={(selected) => {
                      console.warn('onChange', selected);
                      const selectedProjects = selected as Project[];
                      if (selectedProjects.length > 0) {
                        setProject(selectedProjects[0]);
                      }
                      else {
                        setProject(undefined);
                      }
                    }}
                  />
                </div>
              </RequiresPermission>
              {project && <div className="row">
                <label className="form-label col-form-label col-xl-3 text-nowrap">Domain(s)</label>
                <div className="col-form-label col-xl-9">
                  {availableDomains === undefined && <p className="col-form-label"><i className="fa-solid fa-spin fa-spinner"></i> Loading domains for project...</p>}
                  {availableDomains && <div className="form-check form-switch mb-2">
                    <input className="form-check-input" type="checkbox" id="allDomains" checked={allDomains} onChange={() => setAllDomains(!allDomains)} />
                    {allDomains ? <label className="form-check-label" htmlFor="allDomains">All Domains</label> : <label className="form-check-label" htmlFor="allDomains">Selected Domain(s)</label>}
                  </div>}

                  {!allDomains && availableDomains?.map((available) => <div key={available.id} className="form-row my-1">
                    <div className="form-check ms-auto mb-0">
                      <input type="checkbox" id={`domain-${available.id}`} className="form-check-input" checked={selectedDomains?.includes(available) ?? true} onChange={() => toggleSelectedDomain(available)} />
                      <label className="form-check-label" htmlFor={`domain-${available.id}`}>{available.label}</label>
                    </div>
                  </div>)}
                </div>
              </div>}
              {project && <div className="row mb-15px">
                <label className="form-label col-form-label col-xl-3 text-nowrap">Grade(s)</label>
                <div className="col-form-label col-xl-9">
                  {grades === undefined && <p className="col-form-label"><i className="fa-solid fa-spin fa-spinner"></i> Loading grades for project...</p>}
                  {grades && <div className="form-check form-switch mb-2">
                    <input className="form-check-input" type="checkbox" id="allGrades" checked={allGrades} onChange={() => setAllGrades(!allGrades)} />
                    {allGrades ? <label className="form-check-label" htmlFor="allGrades">All Grades</label> : <label className="form-check-label" htmlFor="allGrades">Selected Grade(s)</label>}
                  </div>}

                  {!allGrades && grades?.map((grade) => <div key={grade.grade} className="form-row my-1">
                    <div className="form-check ms-auto mb-0">
                      <input type="checkbox" id={`grade-${grade.grade}`} className="form-check-input" checked={selectedGrades?.includes(grade.grade) ?? true} onChange={() => toggleSelectedGrade(grade)} />
                      <label className="form-check-label" htmlFor={`grade-${grade.grade}`}>{labels.grades[grade.grade]}</label>
                    </div>
                  </div>)}
                </div>
              </div>}
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end">
            <button className="btn btn-sm btn-success" disabled={!canGenerateReport} onClick={onGenerateReport}>Generate Report</button>
          </div>
        </div>
      </div>}
      <div className={hideSelector ? "col-md-12" : "col-md-8"}>
        {domains && <Panel isLoading={isLoading}>
          <PanelHeader>Heatmap Report</PanelHeader>
          <PanelBody>
            {domains?.map((domain) => <div key={`heatmap-${domain.id?.toString()}`} className="row" id={domain.id?.toString()}>
              {domain && project && <HeatmapForDomain domain={domain} project={project} grades={allGrades ? grades.map(g => g.grade) : selectedGrades!} />}
            </div>)}
          </PanelBody>
        </Panel>}
      </div>
    </div>
  </div>);
}

type HeatmapForDomainProps = {
  domain: Tag;
  project: Project;
  grades: GradeTypes[];
};
function HeatmapForDomain(props: HeatmapForDomainProps) {
  const labels = useAppSelector(selectLabels);
  const { domain, project, grades } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isHidden, setIsHidden] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();

  highchartsHeatmap(Highcharts);

  useEffect(() => {
    if (domain && project) {
      api.reports.heatmap(project.id!).heatmap(domain).then((heatmap) => {
        setChartOptions({
          title: {
            text: domain.label,
          },
          subtitle: {
            text: 'Count of resources by topic by grade',
          },
          colors: ['red', 'black', 'green', 'blue', 'purple', 'brown'],
          xAxis: {
            opposite: true,
            title: {
              text: 'Grade',
            },
            labels: {
              y: -15,
            },
            //margin: 40,
            categories: grades.map((grade) => labels.grades[grade])
          },
          yAxis: {
            type: 'category',
            categories: heatmap.topics.map(t => t.label!),
            //title: null,
            title: {
              text: 'Topic',
            },
            reversed: true,
          },
          // accessibility: {
          //   point: {
          //     descriptionFormat: '{(add index 1)}. ' +
          //       '{series.xAxis.categories.(x)} sales ' +
          //       '{series.yAxis.categories.(y)}, {value}.'
          //   }
          // },
          colorAxis: {
            min: 0,
            max: 5,
            minColor: '#FFFFFF',
            maxColor: Highcharts.getOptions().colors![0]
          },
          plotOptions: {
            heatmap: {
              allowPointSelect: true,
            },
          },
          chart: {
            // inverted: true,
            events: {
              drillup: (e) => {
                console.log('drillup', e);
                const chart = e.target; //this as Highcharts.Chart;
                chart.yAxis[0].update({
                  title: {
                    text: "Topic"
                  },
                  categories: heatmap.topics.map(t => t.label!),
                });
              },
              drilldown: async (e) => {
                console.log('drilldown', e);
                if (!e.seriesOptions) {
                  const chart = e.target; //this as Highcharts.Chart;
                  const domain = heatmap.topics[e.category!];
                  console.log('load drilldown for domain', domain);

                  // Show the loading label
                  if (e.point) {
                    const drilldownPoint = e.point;
                    console.log('drilldown point', drilldownPoint);
                    if (drilldownPoint.y !== undefined) {
                      const tagForPoint = heatmap.topics[drilldownPoint.y];
                      console.log('drilldown tag', tagForPoint);
                      chart.showLoading('Loading drilldown...');
                      const heatmapDrilldownData = await api.reports.heatmap(project.id!).heatmap(tagForPoint);
                      chart.yAxis[0].update({
                        title: {
                          text: "Sub-Topic"
                        },
                        categories: heatmapDrilldownData.topics.map(t => t.label!),
                      });
                      chart.addSeriesAsDrilldown(drilldownPoint, {
                        id: drilldownPoint.name,
                        type: 'heatmap',
                        allAreas: true,
                        dataLabels: {
                          enabled: true,
                          color: '#000000'
                        },
                        name: tagForPoint.label,
                        data: grades.map((grade, x) => {
                          // todo , get sub-topics
                          return heatmapDrilldownData.topics.map((subtopic, y) => {
                            const resourceCount = heatmapDrilldownData.resourceCounts.find((rc) => rc.grade === grade && rc.topicId === subtopic.id);
                            return {
                              x,
                              y,
                              value: resourceCount?.resources.length ?? 0,
                              //description: `${x}-${y}`,
                              description: resourceCount?.resources.map(r => r.name).join("<br /> "),
                            };
                          });
                        }).reduce((a, b) => a.concat(b), []),
                      });
                      chart.hideLoading();
                    }
                  }
                }
              },
            },
          },
          legend: {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'top',
            y: 25,
            symbolHeight: 280
          },
          tooltip: {
            format: '<b>Grade</b> {series.xAxis.categories.(point.x)}<br>' +
              '<b>{series.yAxis.categories.(point.y)}</b><br />' +
              '{point.description}'
          },
          series: [{
            type: 'heatmap',
            allAreas: true,
            allowPointSelect: true,
            name: domain.label,
            borderWidth: 1,
            data: grades.map((grade, x) => {
              return heatmap.topics.map((topic, y) => {
                const resourceCount = heatmap.resourceCounts.find((rc) => rc.grade === grade && rc.topicId === topic.id);
                return {
                  x,
                  y,
                  value: resourceCount?.resources.length ?? 0,
                  //drilldown: `${x}-${y}`,
                  description: resourceCount?.resources.map(r => r.name).join("<br /> "),
                  drilldown: (resourceCount?.resources.length ?? 0) > 0 ? `topic-${topic.id}` : undefined,
                  //drilldown: true,
                };
              });
            }).reduce((a, b) => a.concat(b), []),
            dataLabels: {
              enabled: true,
              color: '#000000'
            }
          }],
          drilldown: {
            // allowPointDrilldown: false,
            series: [],
            breadcrumbs: {
              relativeTo: "plotBox",
              // buttonTheme: {
              //   fill: '#f7f7f7',
              //   padding: 8,
              //   stroke: '#cccccc',
              //   'stroke-width': 1
              // },
              floating: false,
              position: {
                align: 'left'
              },
              showFullPath: true
            },
            // series: heatmap.topics.map((topic, y) => {
            //   return {
            //     name: ``,
            //   } as Highcharts.SeriesHeatmapOptions;
            // }),
          },
          // drilldown: {
          //   series: grades.map((grade, x) => {
          //     return heatmap.topics.map((topic, y) => {
          //       const resourceCount = heatmap.resourceCounts.find((rc) => rc.grade === grade && rc.topicId === topic.id);
          //       return {
          //         id: `${x}-${y}`,
          //         type: 'heatmap',
          //         name: `Grade ${labels.grades[grade]}`,
          //         // type: 'heatmap',
          //         data: resourceCount?.resources.map((rc, rcX) => {
          //           console.log('heatmap drilldown', rc);
          //           return {
          //             name: rc.name,
          //             x: rcX,
          //             y: 0,
          //             value: 1,
          //           };
          //         }),
          //       } as Highcharts.SeriesHeatmapOptions;
          //     });
          //   }).reduce((a, b) => a.concat(b), []),
          // },
        });

        setIsLoading(false);
      });

    }
    else {
      setChartOptions(undefined);
    }
  }, [domain, grades, labels.grades, project]);

  return <>
    {/* <h3>{domain.label}</h3> */}
    {isLoading && <p><i className="fa-solid fa-spin fa-spinner"></i> Loading Heatmap of {domain.label}...</p>}
    {/* {isHidden && <button className="btn bt-link no-print">Show Heatmap of {domain.label}</button>} */}
    {!isHidden && chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}
    {/* {!isHidden && chartOptions && <button className="btn bt-link">Hide Heatmap of {domain.label}</button>} */}
  </>;
}