import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'; 
import './App.css';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { Outlet, useParams } from 'react-router-dom';
import { shared } from './store/sessionSlice';
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import ShareHeader from './components/header/share-header';
import SidebarMinifyBtn from './components/sidebar/sidebar-minify-btn';
import SidebarNav, { NavItem } from './components/sidebar/sidebar-nav';

type ShareAppParams = {
  shareKey: string;
}

function ShareApp() {
  const { shareKey } = useParams<ShareAppParams>();

  const dispatch = useAppDispatch();
  // const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<String>();
  const session = useAppSelector(state => state.session);

  useEffect(() => {    
    setError(undefined);
    //return (<Navigate to={"/login?return=" + encodeURI(window.location.href)} replace />);
    dispatch(shared(shareKey!));
  }, [dispatch, session.isLoggedIn, shareKey]);

  return (<div className={'app app-header-fixed app-sidebar-fixed'}>
    <ShareHeader />
      <div id="sidebar" className={'app-sidebar '}>
        <PerfectScrollbar className="app-sidebar-content" options={{ suppressScrollX: true }}>
        <div className="menu">
          <div className="menu-header">Navigation</div>
          {session.shared?.menuItems?.map((menu, i) => (
            <NavItem key={i} menu={menu} isExpanded={true} />
          ))}
        </div>
          
        </PerfectScrollbar>
      </div>
      <div className="app-sidebar-bg"></div>
    <div className="">

    </div>
    <div className="app-content">
    {session.loading && <p><i className="fa-solid fa-spin fa-spinner"></i> Checking access...</p>}
    {error && <p><i className="fa-solid fa-exclamation-triangle text-danger"></i> ERROR: {error}</p>}
    {session.isLoggedIn && 
      <Outlet />
    }
    </div>
  </div>);
}

export default ShareApp;
