import { format } from "../../helpers/format";
import EntityTypes from "../../models/entityTypes";
import Review from "../../models/review";
import { selectConfig } from "../../store/configSlice";
import { useAppSelector } from "../../store/hooks";

type ReviewListGroupProps = {
  reviews: Review[];
  onUseReview?: (review: Review) => void;
}
export default function ReviewListGroup(props: ReviewListGroupProps) {
  // const labels = useAppSelector(selectLabels);
  const config = useAppSelector(selectConfig);
  const attributes = config.attributes[EntityTypes.Review];

  const { reviews, onUseReview } = props;

  return <ul className="list-group list-group-flush">
    {reviews.map((review) => <li key={review.id} className="list-group-item">
      <li className="d-flex flex-row">
        <div className="flex-grow-1">
          <div className="row">
            <div className="col-md-6">
              <i className="fa-light fa-file-contract"></i> {review.projectName}
            </div>
            <div className="col-md-6" title="User">
              <i className="fa-light fa-user"></i> {review.userName}
            </div>
            {review.attributes?.map((reviewAttribute) => {
              const attribute = attributes?.find((attribute) => attribute.id === reviewAttribute.id)
              if (attribute && attribute.isShownDefault) {
                return <div key={reviewAttribute.id} className="col-md-6" title={attribute.name}>
                  {reviewAttribute.dateValue && <><i className="fa-light fa-calendar"></i> {attribute.name} {format.date(reviewAttribute.dateValue)}</>}
                  {reviewAttribute.stringValue && <><i className="fa-light fa-note"></i> {attribute.name} {reviewAttribute.stringValue}</>}
                </div>;
              }
              return <></>;
            })}
          </div>
        </div>
        {onUseReview  && <div className="">
          <button className="btn btn-outline-warning" onClick={() => onUseReview(review)}>Use</button>
        </div>}
      </li>
    </li>)}
  </ul>;
}