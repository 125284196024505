import { Link } from "react-router-dom";
import { selectLabels } from "../../store/labelsSlice";
import { useAppSelector } from "../../store/hooks";
import { api } from "../../store/api";
import { useEffect, useState } from "react";
import { selectSession } from "../../store/sessionSlice";
import Roles from "../../models/roles";
import { useDocumentTitle } from "../../util/useDocumentTitle";

export function Dashboard() {
	const session = useAppSelector(selectSession);
	const labels = useAppSelector(selectLabels);
	const numberFormat = Intl.NumberFormat("en-US", {
		useGrouping: true,
		maximumFractionDigits: 0,
	});
	const [activePartners, setActivePartners] = useState<number>();
	const [activeProjects, setActiveProjects] = useState<number>();
	const [activeResources, setActiveResources] = useState<number>();
	const [totalReviews, setTotalReviews] = useState<number>();
	
	useDocumentTitle('Todo');

	useEffect(() => {
		if (session.roles?.find(r => r === Roles.Analyst)) {
			api.partners.count().then((num) => setActivePartners(num));
			api.projects.count().then((num) => setActiveProjects(num));
			api.resources.count().then((num) => setActiveResources(num));
			api.reviews.count().then((num) => setTotalReviews(num));
		}
	}, [session.roles]);

	return (
		<div>
			<ol className="breadcrumb float-xl-end">
				<li className="breadcrumb-item"><Link to="/">Home</Link></li>
				<li className="breadcrumb-item active">Dashboard</li>
			</ol>
			<h1 className="page-header">Dashboard </h1>

			<div className="row">
				{activePartners &&
					<div className="col-xl-3 col-md-6">
						<div className="widget widget-stats bg-blue">
							<div className="stats-icon"><i className="fa fa-building"></i></div>
							<div className="stats-info">
								<h4>Total {labels.partner.plural} </h4>
								<p>{numberFormat.format(activePartners)}</p>
							</div>
							<div className="stats-link">
								<Link to="/partners">View {labels.partner.plural} <i className="fa fa-arrow-alt-circle-right"></i></Link>
							</div>
						</div>
					</div>}
				{activeProjects && <div className="col-xl-3 col-md-6">
					<div className="widget widget-stats bg-info">
						<div className="stats-icon"><i className="fa fa-file-contract"></i></div>
						<div className="stats-info">
							<h4>Total {labels.project.plural}</h4>
							<p>{numberFormat.format(activeProjects)}</p>
						</div>
						<div className="stats-link">
							<Link to="/projects">View {labels.project.plural} <i className="fa fa-arrow-alt-circle-right"></i></Link>
						</div>
					</div>
				</div>}
				{activeResources && <div className="col-xl-3 col-md-6">
					<div className="widget widget-stats bg-orange">
						<div className="stats-icon"><i className="fa fa-books"></i></div>
						<div className="stats-info">
							<h4>Total {labels.resource.plural}</h4>
							<p>{numberFormat.format(activeResources)}</p>
						</div>
						<div className="stats-link">
							<Link to="/resources">View {labels.resource.plural} <i className="fa fa-arrow-alt-circle-right"></i></Link>
						</div>
					</div>
				</div>}
				{/* {(totalReviews ?? 0) > 0 && <div className="col-xl-3 col-md-6">
					<div className="widget widget-stats bg-green">
						<div className="stats-icon"><i className="fa-duotone fa-magnifying-glass-chart"></i></div>
						<div className="stats-info">
							<h4>Total {labels.review.plural}</h4>
							<p>{numberFormat.format(totalReviews!)}</p>
						</div>
						<div className="stats-link">
							<Link to="/reviews">View {labels.review.plural} <i className="fa fa-arrow-alt-circle-right"></i></Link>
						</div>
					</div>
				</div>} */}
			</div>
		</div>
	);
}