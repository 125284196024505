//import { parseDate } from "../helpers/serviceHelpers";
import { parseDate } from "../helpers/serviceHelpers";
import AttributeValue from "./attributeValue";
import GradeTypes from "./gradeTypes";
import GradeUnit from "./gradeUnit";
import Project from "./project";
import Resource from "./resource";

export enum ProjectResourceStatus {
  Any = 0,
  NeedsReview = 1,
}

export default interface ProjectResource {
  id?: number;
  gradeUnitId?: number;
  gradeUnit?: GradeUnit;

  //projectId?: number;
  project?: Project;
  //projectName?: string;

  resourceId?: number;
  resource?: Resource;
  name?: string; // Resource name

  attributes?: AttributeValue[];
  isActive?: boolean;
  isAnchor?: boolean;
  // grade?: GradeTypes;
  // unit?: string;

  reviews?: number;

  created?: Date;
}


export function ProjectResourceFromApi(api: ProjectResource): ProjectResource {
  return {
    ...api,
    created: parseDate(api.created),
    // executionDate: parseDate(api.executionDate),
    // rentCommencementDate: parseDate(api.rentCommencementDate),
    // originalTermEndDate: parseDate(api.originalTermEndDate),
    // terminationDate: parseDate(api.terminationDate),
  };
}