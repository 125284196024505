export function parseDate(str: string | Date | undefined): Date | undefined {
    if (str !== undefined && str !== null) {
        console.log('parseDate', str);
        return new Date(str);
    }
    return undefined;
}
export function fetchResponseToJson(response: any) {

    // const deserialize = <T extends Object>(c: new () => T, props: any): T =>
    //     Object.assign(new c(), props);
    // const obj = deserialize(Thing, JSON.parse(json));
    function logout() {
        localStorage.removeItem("session");
        console.error("authentication required");
    }
    
    function parseWithDate(key: any, value: any): any {
        var reDateDetect = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/; // startswith: 2015-04-29T22:06:55
        if (typeof key == "string" && typeof value == "string" && reDateDetect.exec(value)) {
            return new Date(Date.parse(value));
        }
        return value;
    }
    return response.text().then((text: string) => {
        // const data = text && JSON.parse(text);
        const data = text && JSON.parse(text, parseWithDate);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}