import { generatePath, useNavigate } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import { api } from "../../store/api";
import RecordsListScreen from "../recordsListScreen";
import User, { IUser } from "../../models/user";
import { UsersEditRoute } from "./userEditScreen";
import { UserDetailsRoute } from "./userDetailsScreen";

export const UsersRoute = "/settings/users";

const filters = [
  { key: 'all', label: 'All', api: api.users.search },
];

export default function UsersScreen() {
  const navigate = useNavigate();

  return <RecordsListScreen<User>
    type={EntityTypes.User}
    showName={false}
    columns={[
      { label: "First Name", callback: (record: User)  => <td>{record.firstName}</td>, },
      { label: "Last Name", callback: (record: User) => <td>{record.lastName}</td>, },
      { label: "Email", callback: (record) => <td>{record.email}</td>, },
      { label: "Is Active?", callback: (record) => <td>{record.isActive ? 'Yes': 'No'}</td>, },
    ]}
    filters={filters}
    actions={(record) => <>
      <button className="btn btn-sm btn-outline-primary mx-1" onClick={() => navigate(generatePath(UserDetailsRoute, { id: `${record.id}` }))}>Details</button>
      <button className="btn btn-sm btn-outline-secondary mx-1" onClick={() => navigate(generatePath(UsersEditRoute, { id: `${record.id}` }))}>Edit</button>
      </>}
     />;
}