import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { Modal } from "react-bootstrap";
import { FormProvider, SubmitHandler, useController, useForm } from "react-hook-form";
import { Panel, PanelBody } from "../panel/panel";
import { api } from "../../store/api";
import Tag from "../../models/tag";
import KnowledgeMapTypes, { AllKnowledgeMapTypes } from "../../models/knowledgeMapTypes";
import { selectLabels } from "../../store/labelsSlice";

type QuckAddTagProps = {
  show: boolean;
  onCancel?: () => void;
  onSuccess?: (tag: Tag) => void;
  parentTag: Tag;
  initialData?: Tag;
}

export default function QuckAddTag(props: QuckAddTagProps) {
  const labels = useAppSelector(selectLabels);
  const parentTag = props.parentTag;
  //const attributes = config.attributes[props.entityType];
  //useEffect(() => { dispatch(fetchAttributes(props.entityType)) }, [dispatch, props.entityType]);

  const [defaultValue, setDefaultValue] = useState<Tag | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<String>();

  const form = useForm<Tag>({
    defaultValues: {},
  });
  const { register, handleSubmit, reset, formState: { errors }, control, setValue } = form;

  useEffect(() => {
    // console.error('QuckAddTag.reset', parentTag.knowledgeMapTypes);
    reset({
      isActive: true,
      ...defaultValue,
      ...props.initialData,
      knowledgeMapTypes: parentTag.knowledgeMapTypes,
    });
    
    setIsLoading(false);
  }, [defaultValue, parentTag.knowledgeMapTypes, props.initialData, reset, setValue]);

  const onSubmit: SubmitHandler<Tag> = async (data: Tag) => {
    const request = {
      ...data,
      parentTag: parentTag,
      parentTagId: parentTag.id,
    };
    console.log('QuickAddTag.onSubmit', request);
    setIsLoading(true);
    setError(undefined);
    api.settings.tags.create(request)
      .then((created) => {
        console.log('QuickAddTag.created', created);
        if (props.onSuccess) {
          props.onSuccess(created);
        }
        reset();
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });

    return {};
  };

  const { field: knowledgeMapTypesField } = useController({
    name: `knowledgeMapTypes` as const,
    control,
    defaultValue: parentTag.knowledgeMapTypes,
  });

  // useEffect(() => {
  //   console.log('quickAddModal knowledgeMapTypesField', knowledgeMapTypesField.value);
  // }, [knowledgeMapTypesField.value]);

  return <Modal show={props.show} onHide={() => props.onCancel && props.onCancel()} size="lg">
    <FormProvider {...form}>
      <form key={`quick-add-tag`}>
        <Modal.Header closeButton>
          <Modal.Title>Add New {labels.tag.singular}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Panel className="card border-0" isLoading={isLoading}>
              <PanelBody className="card-body">
                {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
                {parentTag && <div className="row mb-15px">
                  {parentTag?.label && <h6 className="col-md-3 text-muted">Parent {labels.tag.singular}</h6>}
                  {parentTag?.label && <p className="col-md-9">{parentTag?.label}</p>}
                  {parentTag.description && <h6 className="col-md-3 text-muted">Description</h6>}
                  {parentTag.description && <p className="col-md-9">
                    {parentTag.description}
                  </p>}
                  <h6 className="col-md-3 text-muted">Parent Knowledge Map Type(s)</h6>
                  <div className="col-md-9">
                    {AllKnowledgeMapTypes.filter((kmType) => (kmType & (parentTag.knowledgeMapTypes ?? KnowledgeMapTypes.Undefined)) === kmType).map((kmType) => <div key={kmType}>{labels.knowledgeMapTypes[kmType]}</div>)}
                  </div>
                </div>}
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Label</label>
                  <div className="col-md-9">
                    <input type="text" className={"form-control mb-5px " + (errors.label ? 'is-invalid' : '')} {...register("label", { required: true })} />
                    {errors.label && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Description</label>
                  <div className="col-md-9">
                    <textarea rows={4} className={"form-control mb-5px " + (errors.description ? 'is-invalid' : '')} {...register("description")} />
                    {errors.description && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Knowledge Map Type(s)</label>
                  <div className="col-md-9 pt-2">
                    {AllKnowledgeMapTypes.map((kmType) => <div key={kmType} className="form-check mb-2 form-check-inline">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={kmType}
                          checked={((knowledgeMapTypesField.value ?? KnowledgeMapTypes.Undefined) & kmType) === kmType}
                          onChange={(e) => {
                            const valueCopy = (e.target.checked)
                              ? (knowledgeMapTypesField.value ?? KnowledgeMapTypes.Undefined) | kmType
                              : (knowledgeMapTypesField.value ?? KnowledgeMapTypes.Undefined) ^ kmType; // XOR
                            // console.log('knowledgeMapTypesField', knowledgeMapTypesField.value, valueCopy);
                            knowledgeMapTypesField.onChange(valueCopy);
                          }}
                        />
                        {labels.knowledgeMapTypes[kmType]}
                      </label>
                    </div>)}
                  </div>
                </div>

              </PanelBody>
            </Panel>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary w-100px me-5px" disabled={isLoading} onClick={handleSubmit(onSubmit)}>Save</button>
        </Modal.Footer>
      </form>
    </FormProvider>
  </Modal>
}