import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import { api, SearchProps } from "../../store/api";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import Project from "../../models/project";
import { ProjectDetailsRoute } from "./projectDetailsScreen";
import { ProjectEditRoute } from "./projectEditScreen";
import RecordsListScreen from "../recordsListScreen";
import useLabel from "../../util/useLabel";
import KnowledgeMapTypes from "../../models/knowledgeMapTypes";
import { useEffect, useState } from "react";
import { RecordFilter } from "../../components/records/recordsList";

export const ProjectsRoute = "/projects";



export default function ProjectsScreen() {
  const navigate = useNavigate();
  const labels = useAppSelector(selectLabels);
  const [filters, setFilters] = useState<RecordFilter<Project>[]>([]);
  const projectLabel = useLabel(EntityTypes.Project);
  const [searchParams] = useSearchParams();
  const view = searchParams.get('view');

  useEffect(() => {
    const filterActive = { key: 'active', label: `Active ${projectLabel.plural}`, api: api.projects.search };
    const filterAll = { key: 'all', label: `All ${projectLabel.plural}`, api: (props: SearchProps) => api.projects.search({...props, active: null}) };
    const filterInactive = { key: 'inactive', label: `Inactive ${projectLabel.plural}`, api: (props: SearchProps) => api.projects.search({ ...props, active: false }) };
    const filterMine = { key: 'my', label: `My ${projectLabel.plural}`, api: api.projects.searchMine };
    const filterRecent = { key: 'recent', label: `Recent ${projectLabel.plural}`, api: api.projects.searchRecent };

    switch (view) {
      case 'recent':
        setFilters([filterRecent]);
        break;
      case 'all':
        setFilters([
          filterAll,
          filterActive,
          filterInactive,
        ]);
        break;
      case 'mine':
        setFilters([filterMine]);
        break;
      default:
        setFilters([
          filterActive,
          filterMine,
        ]);
        break;
    }
  
  }, [projectLabel.plural, view]);

  return filters && <RecordsListScreen
    type={EntityTypes.Project}
    filters={filters}
    detailsRoute={ProjectDetailsRoute}
    columns={[
      {
        label: 'Partner',
        beforeAttributes: true,
        callback: (record: Project) => <td>{record.partner?.name}</td>
      },
      {
        label: 'Type',
        //headerClassNames: 'text-end',
        callback: (record: Project) => <td>{labels.knowledgeMapTypes[record.knowledgeMapType ?? KnowledgeMapTypes.Undefined]}</td>
      },
    ]}
    actions={(record) => <>
      <button className="btn btn-sm btn-outline-primary mx-1" onClick={() => navigate(generatePath(ProjectDetailsRoute, { id: `${record.id}` }))}>Details</button>
      <button className="btn btn-sm btn-outline-secondary mx-1" onClick={() => navigate(generatePath(ProjectEditRoute, { id: `${record.id}` }))}>Edit</button>
    </>}
  />;


}