import { Link, createSearchParams, generatePath } from "react-router-dom";
import Project from "../../models/project";
import GradeTypes from "../../models/gradeTypes";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import ProjectGrade from "../../models/projectGrade";
import { Fragment, useState } from "react";
import { ProjectResourcesRoute } from "../../screens/projects/projectResourcesScreen";
import { RequiresPermission } from "../role/requiresRole";
import { PermissionTypes } from "../../models/permissionTypes";

type ProjectResourceSummaryProps = {
  project: Project;
  projectSummary: ProjectGrade[];
  onAddReviewers?: (grade: GradeTypes) => void;
}


export function ProjectResourceSummary(props: ProjectResourceSummaryProps) {
  const labels = useAppSelector(selectLabels);
  const [gradeDetail, setGradeDetail] = useState<GradeTypes>();

  const onAddReviewers = (grade: GradeTypes) => {
    if (props.onAddReviewers !== undefined) {
      props.onAddReviewers(grade);
    }
  };

  return <table className="table table-sm table-responsive table-hover">
    <thead>
      <tr>
        <th>Grade</th>
        <th>Unit(s)</th>
        <th>
          {labels.reviewer.plural}
        </th>
        <th className="text-end">{labels.resource.plural}</th>
      </tr>
    </thead>
    <tbody>
      {props.projectSummary.map(gradeSummary => <Fragment key={`grade-${gradeSummary.grade}`}>
        {/* Summary */}
        <tr onClick={(e) => setGradeDetail((cur) => cur === gradeSummary.grade ? undefined : gradeSummary.grade)} className={gradeSummary.grade === gradeDetail ? "bg-lime" : ""}>
          <td rowSpan={gradeSummary.grade === gradeDetail ? gradeSummary.units.length + 1 : 1}>{labels.grades[gradeSummary.grade]}</td>
          <td>{gradeSummary.units.map(unitSummary => 
            <Link key={`unit-${unitSummary.unit}`} className="btn btn-sm btn-info px-1 py-0 me-2 mb-1" to={{ pathname: generatePath(ProjectResourcesRoute, { projectId: `${props.project.id}` }), search: createSearchParams({ grade: gradeSummary.grade.toString(), unit: unitSummary.unit }).toString() }}>{unitSummary.unit}</Link>
            //<button className="btn btn-sm btn-info px-1 py-0 me-2" key={`unit-${unit.unit}`}>{unit.unit}</button>
            )}</td>
          <td>
            {gradeSummary.reviewers?.map(reviewer => <span key={`reviewer-${reviewer.id}`} className="badge rounded-pill me-2 bg-lime">{reviewer.initials}</span>)}
            <RequiresPermission permission={PermissionTypes.ProjectReviewersAssign}>
              <button className="btn btn-sm btn-outline-secondary px-1 py-0 me-2" onClick={(e) => { e.stopPropagation(); onAddReviewers(gradeSummary.grade); }}><i className="fa fa-plus"></i></button>
            </RequiresPermission>
          </td>
          <td className="text-end">{gradeSummary.units.reduce((prev, cur) => prev + cur.countResources, 0)}</td>
        </tr>
        {/* Detailed Breakdown */}
        {gradeSummary.grade === gradeDetail && gradeSummary.units.map(unitSummary => <tr>
          
          <td><Link key={`unit-${unitSummary.unit}`} className="btn btn-sm btn-info px-1 py-0 me-2" to={{ pathname: generatePath(ProjectResourcesRoute, { projectId: `${props.project.id}` }), search: createSearchParams({ grade: gradeSummary.grade.toString(), unit: unitSummary.unit }).toString() }}>Unit {unitSummary.unit}</Link></td>
          <td></td>
          <td className="text-end">{unitSummary.countResources}</td>
        </tr>)}
      </Fragment>)}
    </tbody>
    <tfoot>
      <tr>
        <th>Total</th>
        <th></th>
        <th></th>
        <th className="text-end">{props.projectSummary.reduce((prev, cur) => prev + cur.units.reduce((unitsPrev, unitsCur) => unitsPrev + unitsCur.countResources, 0), 0)}</th>
      </tr>
    </tfoot>
  </table>;
}