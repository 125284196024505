import { Link, generatePath, useParams } from "react-router-dom";
import { SearchProps, api } from "../../store/api";
import { ReactNode, useEffect, useState } from "react";
import Tag, { tagOrAncestorIsUserManaged } from "../../models/tag";
import { TagsRoute } from "./tagsScreen";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import KnowledgeMapTypes, { AllKnowledgeMapTypes } from "../../models/knowledgeMapTypes";
import { TagEditRoute } from "./tagEditScreen";
import RecordsList, { RecordFilter } from "../../components/records/recordsList";
import EntityTypes from "../../models/entityTypes";
import { selectTags } from "../../store/configSlice";
import { RequiresPermission } from "../../components/role/requiresRole";
import { TagExportRoute } from "./tagExport";
import tagBreadcrumb from "../../components/tags/tagBreadcrumb";
import TagDetailsCard from "../../components/tags/tagDetailsCard";
import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from "react-beautiful-dnd";
import { Panel, PanelBody } from "../../components/panel/panel";

export const TagDetailsRoute = "/settings/tags/:id";

interface TagDetailsRouteParams {
  id?: string;
}

export default function TagDetailsScreen() {
  const labels = useAppSelector(selectLabels);
  const allTags = useAppSelector(selectTags);
  const label = labels.tag;
  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState<Tag>();
  const [error, setError] = useState<String>();
  const params = useParams<keyof TagDetailsRouteParams>();
  //const [subTagsFilter, setSubTagsFilter] = useState<RecordFilter<Tag>[]>([]);
  const [subTags, setSubTags] = useState<Tag[]>();
  const [isUserManaged, setIsUserManaged] = useState<boolean>(false);

  useEffect(() => {
    document.title = 'Tags';
  }, []);

  // useEffect(() => {
  //   if (record) {
  //     setSubTagsFilter([
  //       { key: `sub-tags-${record?.id}`, label: `Sub-${label.plural} (Active)`, api: (search: SearchProps) => api.settings.tags.search({ ...search, parentTagId: record?.id }) },
  //       { key: `sub-tags-${record?.id}-all`, label: `Sub-${label.plural} (All)`, api: (search: SearchProps) => api.settings.tags.search({ ...search, parentTagId: record?.id, includeDisabled: true }) },
  //     ]);
  //   }
  // }, [label.plural, record]);

  useEffect(() => {
    // Load the attribute and set the form with the current values
    setIsLoading(true);

    api.settings.tags.get(parseInt(params.id!))
      .then((record) => {
        setRecord(record);
        document.title = `${record.label} - ${labels.tag.plural}`;
        api.settings.tags.subtags(record.id!).then((response) => {
          setSubTags(response);
          setIsLoading(false);
        })
          .catch((reason) => {
            console.error(reason);
            setError("Unable to load sub-" + labels.tag.singular + ` #${params.id}`);
          });

      })
      .catch((reason) => {
        console.error(reason);
        setError("Unable to load " + labels.tag.singular + ` #${params.id}`);
      });
  }, [labels.review.singular, labels.tag.plural, labels.tag.singular, params.id]);

  useEffect(() => {
    //tagOrAncestorIsUserManaged(tag)
    if (allTags && record) {
      setIsUserManaged(tagOrAncestorIsUserManaged(allTags, record));
    }
  }, [allTags, record]);


  function handleDrag(result: DropResult, provided: ResponderProvided): void {
    console.log('handleDrag', result);
    if (result.destination) {
      const newItems = [...subTags!];
      const [removed] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, removed);
      setIsLoading(true)
      api.settings.tags.sort(newItems!)
        .then((result) => {
          setSubTags(newItems);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
      //move(result.source.index, result.destination.index);
    }
  }
  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">Settings</li>
          <li className="breadcrumb-item"><Link to={TagsRoute}>{labels.tag.plural}</Link></li>
          {tagBreadcrumb(record)}
        </ul>
      </div>
      <div className="ms-auto">
        <Link to={'create'} className="btn btn-success btn-rounded px-4 rounded-pill"><i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Create Sub-{label?.singular}</Link>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      <div className="col-md-4">
        {record && <TagDetailsCard record={record} showExport={true} isUserManaged={isUserManaged} />}
      </div>
      <div className="col-md-8">
        <Panel isLoading={isLoading} className="table-responsive mb-3">
          <PanelBody>
            {((subTags?.length ?? 0) > 0) && (
              <DragDropContext onDragEnd={handleDrag}>
                <Droppable droppableId="fields">
                  {(provided, snapshot) => (
                    <table className="table table-hover table-panel text-nowrap align-middle mb-0" ref={provided.innerRef} {...provided.droppableProps}>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Label</th>
                          <th>Type(s)</th>
                          <th>Management</th>
                          <th>Action(s)</th>
                        </tr>
                      </thead>
                      <tbody {...provided.droppableProps} ref={provided.innerRef}>
                        {subTags?.map((subtag, index) => <Draggable key={`test[${index}]`}
                          draggableId={`item-${index}`}
                          index={index}>
                          {(provided, snapshot) => <tr ref={provided.innerRef} {...provided.draggableProps}>
                            <td><div className="btn btn-sm"
                              {...provided.dragHandleProps}>
                              <i className="fa-solid fa-grip-dots-vertical"></i> {index + 1}
                            </div></td>
                            {/* {label: 'Type(s)', callback: (record) => <td key={'type'}>{AllKnowledgeMapTypes.filter((kmType) => (kmType & (record.knowledgeMapTypes ?? KnowledgeMapTypes.Undefined)) === kmType).map((kmType) => <div key={kmType}>{labels.knowledgeMapTypes[kmType]}</div>)}</td>, },
                        {
                          label: 'Management', callback: (record) => <td key={'management'}>
                          {record.usersCanAddSubTags && <span title="Users can add sub-tags" className="badge bg-warning"><i className="fas fa-user-pen"></i></span>}
                          {!record.usersCanAddSubTags && allTags && tagOrAncestorIsUserManaged(allTags, record) && <span title="Users can add sub-tags (via ancestor)" className="badge bg-secondary"><i className="fas fa-user-pen"></i></span>}
                          {!record.isActive && <span className="badge bg-danger mx-1">DISABLED</span>}
                        </td>,
            }, */}

                            <td><Link to={generatePath(TagDetailsRoute, { id: `${subtag.id}` })}>{subtag.label}</Link></td>
                            <td>
                              {AllKnowledgeMapTypes.filter((kmType) => (kmType & (subtag.knowledgeMapTypes ?? KnowledgeMapTypes.Undefined)) === kmType).map((kmType) => <div key={kmType}>{labels.knowledgeMapTypes[kmType]}</div>)}
                            </td>
                            <td>
                              {subtag.usersCanAddSubTags && <span title="Users can add sub-tags" className="badge bg-warning"><i className="fas fa-user-pen"></i></span>}
                              {!subtag.usersCanAddSubTags && allTags && tagOrAncestorIsUserManaged(allTags, subtag) && <span title="Users can add sub-tags (via ancestor)" className="badge bg-secondary"><i className="fas fa-user-pen"></i></span>}
                              {!subtag.isActive && <span className="badge bg-danger mx-1">DISABLED</span>}
                            </td>
                            <td>
                              <Link to={generatePath(TagEditRoute, { id: `${subtag.id}` })} className="btn btn-sm btn-outline-primary">Edit</Link>
                            </td>
                          </tr>}</Draggable>)}
                        {provided.placeholder}
                      </tbody>
                    </table>)}
                </Droppable>
              </DragDropContext>
            )}

          </PanelBody>
        </Panel>

      </div>
    </div>
  </div>);
}

