import { useEffect, useState } from "react";
import { Link, generatePath, useParams } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import Project from "../../models/project";
import { api } from "../../store/api";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import { PartnerDetailsRoute } from "../partners/partnerDetailsScreen";
import RecordDetailsCard from "../../components/records/recordDetailsCard";
import KnowledgeMapTypes from "../../models/knowledgeMapTypes";
import { setTitle } from "../../util/useDocumentTitle";
import { usePermissions } from "../../util/usePermissions";
import { ProjectDetailsRoute } from "./projectDetailsScreen";
import FileBlob from "../../models/fileBlob";


export const ProjectShareRoute = "/projects/:id/share";

interface ProjectFlatFileRouteParams {
  id?: string;
}

export default function ProjectShareScreen() {
  const labels = useAppSelector(selectLabels);

  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState<Project>();
  const [error, setError] = useState<String>();
  const [shareUrl, setShareUrl] = useState<string>();

  const { hasPermission } = usePermissions();

  const params = useParams<keyof ProjectFlatFileRouteParams>();
  useEffect(() => {
    // Load the attribute and set the form with the current values
    setIsLoading(true);
    api.projects.get(parseInt(params.id!))
      .then((record) => {
        setRecord(record);
        setTitle([`${labels.project.singular} Shareable Link`, record.name!]);
      })
      .catch((reason) => {
        console.error(reason);
        setError("Unable to load " + labels.project.singular + ` #${params.id}`);
      });
  }, [labels.project.singular, params.id]);

  useEffect(() => {
    if (record?.id) {
      api.project(record.id).share().then((response) => {
        setShareUrl(`${window.location.protocol}://${window.location.host}/share/${encodeURIComponent(response.token)}/project`);
        setIsLoading(false);
      });
    }
    else {
      setShareUrl(undefined);
    }
  }, [record]);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">Share {labels.project.singular}</h1>
        <ul className="breadcrumb">
          <li className="breadcrumb-item"><Link to={generatePath(PartnerDetailsRoute, { id: `${record?.partner?.id}` })}>{record?.partner?.name}</Link></li>
          <li className="breadcrumb-item"><Link to={generatePath(ProjectDetailsRoute, { id: `${record?.id}` })}>{record?.name}</Link></li>
          <li className="breadcrumb-item active">Share {labels.project.singular}</li>
        </ul>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      <div className="col-md-4">
        {record && <RecordDetailsCard
          record={record}
          type={EntityTypes.Project}
          // editRoute={ProjectEditRoute}
          // footer={<>
          //   <RequiresPermission permission={PermissionTypes.ProjectEdit}>
          //     <Link to={generatePath(ProjectResourcesRoute, { projectId: `${record?.id}` })} className="ms-auto text-decoration-none text-gray-500"><i className="fa fa-edit fa-lg me-2 ms-n2"></i> Add</Link>
          //   </RequiresPermission>
          // </>}
          displayAttributes={[
            { label: labels.partner.singular, callback: (record: Project) => <Link to={generatePath(PartnerDetailsRoute, { id: `${record?.partnerId}` })}>{record?.partner?.name}</Link>, },
            { label: 'Knowledge Map Type', callback: (record: Project) => <>{labels.knowledgeMapTypes[record.knowledgeMapType ?? KnowledgeMapTypes.Undefined]}</>, },
            { label: labels.unitevaluation.plural, callback: (record: Project) => <>{record.unitEvaluations === true ? 'Yes' : 'No'}</>, },
          ]}
        />}
      </div>
      <div className="col-md-8">
        <div className="card border-0 mb-4">
          <div className="card-body">
            <p>
              Anyone with the link below will be able to access reports on this project, such as the Heatmap and Unit reports. They will be able to do so
              without having to login or verify their identity.
            </p>
            {isLoading && <p><i className="fa-solid fa-spin fa-spinner"></i> Generating shareable link...</p>}
            {shareUrl && <div className="d-flex flex-row justify-content-around">
              <input type="text" readOnly={true} value={shareUrl} className="form-control form-control-sm" />
            </div>}
          </div>
        </div>
      </div>
    </div>
  </div>);
}
