import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Routes from './routes';

// bootstrap
import 'bootstrap';

// css
import '@fortawesome/fontawesome-pro/css/all.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/react.scss';
// import React from 'react';
import { hydrate } from './store/sessionSlice';

//const root = ReactDOM.createRoot(
//	document.getElementById('root') as HTMLElement
//);
const container = document.getElementById('root')!;
const root = createRoot(container);

// function AppRoutes() {
// 	let element = useRoutes(Routes);

// 	return element;
// }
const router = createBrowserRouter(Routes);

const getSessionLocalStorage = () => {
	try {
		const persistedState = localStorage.getItem('session')
		if (persistedState)
			return JSON.parse(persistedState)
	}
	catch (e) {
		console.log(e)
	}
}

const persistedSession = getSessionLocalStorage();
if (persistedSession) {
	store.dispatch(hydrate(persistedSession));
}

root.render(

	<Provider store={store}>
		<RouterProvider router={router} />
	</Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
