import { Link, generatePath } from "react-router-dom";
import { selectLabels } from "../../store/labelsSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { api } from "../../store/api";
import { useEffect, useState } from "react";
import { selectSession } from "../../store/sessionSlice";
import Roles from "../../models/roles";
import RecordsList from "../../components/records/recordsList";
import EntityTypes from "../../models/entityTypes";
import { ProjectDetailsRoute } from "../projects/projectDetailsScreen";
import TodoProjectSummary from "../../models/todoProjectSummary";
import { useDocumentTitle } from "../../util/useDocumentTitle";


export function TodoReviewsScreen() {
	const session = useAppSelector(selectSession);
	const labels = useAppSelector(selectLabels);
	
	useDocumentTitle('Todo - Projects');
	
	const filters = [
		{ key: 'todo', label: labels.project.plural, api: api.todo.projects }
	];

	const numberFormat = Intl.NumberFormat("en-US", {
		useGrouping: true,
		maximumFractionDigits: 0,
	});
	const [activePartners, setActivePartners] = useState<number>();
	const [activeProjects, setActiveProjects] = useState<number>();
	const [activeResources, setActiveResources] = useState<number>();
	const [totalReviews, setTotalReviews] = useState<number>();

	useEffect(() => {
		if (session.roles?.find(r => r === Roles.Analyst)) {
			api.partners.count({ mineOnly: true }).then((num) => setActivePartners(num));
			api.projects.count({ mineOnly: true }).then((num) => setActiveProjects(num));
			api.resources.count({ mineOnly: true }).then((num) => setActiveResources(num));
			api.reviews.count({ mineOnly: true }).then((num) => setTotalReviews(num));
		}
	}, [session.roles]);

	return (
		<div>
			<ol className="breadcrumb float-xl-end">
				<li className="breadcrumb-item"><Link to="/">Home</Link></li>
				<li className="breadcrumb-item">Dashboard</li>
				<li className="breadcrumb-item active">My Work</li>
			</ol>
			<h1 className="page-header">My Work </h1>

			<div className="row">
				{activePartners &&
					<div className="col-xl-3 col-md-6">
						<div className="widget widget-stats bg-blue">
							<div className="stats-icon"><i className="fa fa-building"></i></div>
							<div className="stats-info">
								<h4>My {labels.partner.plural} </h4>
								<p>{numberFormat.format(activePartners)}</p>
							</div>
							<div className="stats-link">
								<Link to="/partners">View {labels.partner.plural} <i className="fa fa-arrow-alt-circle-right"></i></Link>
							</div>
						</div>
					</div>}
				{activeProjects && <div className="col-xl-3 col-md-6">
					<div className="widget widget-stats bg-info">
						<div className="stats-icon"><i className="fa fa-file-contract"></i></div>
						<div className="stats-info">
							<h4>My {labels.project.plural}</h4>
							<p>{numberFormat.format(activeProjects)}</p>
						</div>
						<div className="stats-link">
							<Link to="/projects">View {labels.project.plural} <i className="fa fa-arrow-alt-circle-right"></i></Link>
						</div>
					</div>
				</div>}
				{activeResources && <div className="col-xl-3 col-md-6">
					<div className="widget widget-stats bg-orange">
						<div className="stats-icon"><i className="fa fa-books"></i></div>
						<div className="stats-info">
							<h4>My {labels.resource.plural}</h4>
							<p>{numberFormat.format(activeResources)}</p>
						</div>
						<div className="stats-link">
							<Link to="/resources">View {labels.resource.plural} <i className="fa fa-arrow-alt-circle-right"></i></Link>
						</div>
					</div>
				</div>}
			</div>
			<RecordsList
				type={EntityTypes.Project}
				filters={filters}
				showName={false}
				columns={[
					{
						beforeAttributes: true,
						label: labels.project.singular,
						sortKey: 'name',
						callback: (record: TodoProjectSummary) => <td>
							<Link key={`viewProject-${record.id}`} className="" to={generatePath(ProjectDetailsRoute, { id: `${record.id}` })}>{record.name}</Link>
						</td>,
					},
					{
						label: labels.review.plural + ' Completed',
						headerClassNames: 'text-end',
						sortKey: 'complete',
						callback: (record: TodoProjectSummary) => <td className="text-end">{record.completed}</td>,
					},
					{
						label: labels.review.plural + ' To Do',
						sortKey: 'todo',
						headerClassNames: 'text-end',
						callback: (record: TodoProjectSummary) => <td className="text-end">{(record.total ?? 0)-(record.completed ?? 0)}</td>,
					},
					{
						label: labels.unitevaluation.plural + ' To Do',
						sortKey: 'units',
						headerClassNames: 'text-end',
						callback: (record: TodoProjectSummary) => <td className="text-end">{record.unitsToEvaluate}</td>,
					},
				]}
				actions={(record) => <>
					<Link key={`createReview-${record.id}`} className="btn btn-sm btn-outline-primary" to={{pathname: generatePath(ProjectDetailsRoute, { id: `${record.id}` }), hash: 'todo' }}>View Details</Link>
				</>}
			/>
		</div>
	);
}