import { useEffect, useState } from "react";
import EntityTypes from "../../models/entityTypes";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { LabelValue, selectLabels } from "../../store/labelsSlice";
import { Modal } from "react-bootstrap";
import EntityTypeLabel from "../label/entityTypeLabel";
import { FormProvider, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";
import { CustomizableEntities } from "../../models/types";
import { Panel, PanelBody } from "../panel/panel";
import { AttributeValueEditor } from "../attribute/attributeValueEditor";
import Attribute from "../../models/attribute";
import { api, SearchProps } from "../../store/api";
import { fetchAttributes, selectConfig } from "../../store/configSlice";
import ResourceCloneTypeahead from "./resourceCloneTypeahead";
import Resource from "../../models/resource";
import RecordsList from "../records/recordsList";

type QuckAddModalProps<T extends CustomizableEntities> = {
  show: boolean;
  entityType: EntityTypes;
  onCancel?: () => void;
  onSuccess?: (entity: CustomizableEntities) => void;
  initialData?: T;
}

export default function QuckAddModal<T extends CustomizableEntities>(props: QuckAddModalProps<T>) {
  const labels = useAppSelector(selectLabels);

  const dispatch = useAppDispatch();
  const config = useAppSelector(selectConfig);
  const attributes = config.attributes[props.entityType];
  useEffect(() => { dispatch(fetchAttributes(props.entityType)) }, [dispatch, props.entityType]);

  /// Resource-specific functionality
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  const [resourceSearchTerm, setResourceSearchTerm] = useState("");
  const [translatedFrom, setTranslatedFrom] = useState<Resource>();

  const [defaultValue, setDefaultValue] = useState<T | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<String>();

  const fetchRecord = async (): Promise<CustomizableEntities> => new Promise<CustomizableEntities>((resolve) => {
    console.log('fetchRecord', defaultValue);
    resolve({
      ...defaultValue,
      isActive: true,
    });
  });

  const form = useForm<CustomizableEntities>({
    defaultValues: fetchRecord,
  });
  const { register, handleSubmit, reset, formState: { errors }, control } = form;
  const { fields } = useFieldArray({
    name: "attributes",
    keyName: "attributeId",
    control
  });

  useEffect(() => {
    console.error('QuckAddModal.reset', defaultValue, attributes, props.initialData);
    setIsLoading(true);
    reset({
      ...defaultValue,
      attributes: attributes?.map((attrib) => {
        if (defaultValue?.attributes) {
          const attributeValue = defaultValue?.attributes?.find((t) => t?.id === attrib.id);
          if (attributeValue) {
            // console.warn('replacing field', attributeValue);
            return attributeValue;
          }
        }
        return {
          id: attrib.id,
        }
      }),
      ...props.initialData
    });
    setIsLoading(false);
  }, [attributes, defaultValue, props.initialData, reset]);

  const onSubmit: SubmitHandler<CustomizableEntities> = async (data: CustomizableEntities) => {
    // const request = {
    //   ...data,
    //   id: undefined,
    // };
    const request: CustomizableEntities = {
      ...data,
      id: undefined,
      attributes: data.attributes?.map((attrib) => {
        return {
          id: attrib.id,
          boolValue: attrib.boolValue,
          dateValue: attrib.dateValue,
          intValue: attrib.intValue,
          stringValue: attrib.stringValue,
          referencesValue: attrib.referencesValue,
          choicesValue: (attrib.choicesValue ?
            (Array.isArray(attrib.choicesValue) ? attrib.choicesValue : [attrib.choicesValue])
            : undefined
          ),
        };
      })
    };
    console.log('onSubmit', request);
    setIsLoading(true);
    setError(undefined);
    api.create<CustomizableEntities>(props.entityType, request)
      .then(async (created) => {
        console.log('created', created);
        if (props.entityType === EntityTypes.Resource && translatedFrom) {
          // Link new resource to it's translation
          await api.resources.translations(translatedFrom).create(created);
        }
        reset();
        setIsLoading(false);
        if (props.onSuccess) {
          props.onSuccess(created);
        }
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });

    return {};
  };

  const watchAttributes = useWatch({
    control: control,
    name: `attributes`
  });

  const onResourceSelected = (resource: Resource) => {
    api.resources.get(resource.id!).then((resourceDetails) => {
      const { name, ...newResource } = resourceDetails;
      setResourceSearchTerm(resource.name ?? '');
      setDefaultValue({ ...newResource } as T);
      setTranslatedFrom(resourceDetails);
    });
  }

  return <><Modal show={props.show} onHide={() => props.onCancel && props.onCancel()} size="lg">
    <FormProvider {...form}>
      <form key={`quick-add-${props.entityType}`}>
        <Modal.Header closeButton>
          <Modal.Title>Add New <EntityTypeLabel entityType={props.entityType} singlular /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Panel className="card border-0" isLoading={isLoading}>
              <PanelBody className="card-body">
                {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Name</label>
                  <div className="col-md-9">
                    <input type="text" className={"form-control mb-5px " + (errors.name ? 'is-invalid' : '')} {...register("name", { required: true })} />
                    {errors.name && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
                {props.entityType === EntityTypes.Resource && <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Translation of:</label>
                  {translatedFrom === undefined && <div className="col-md-9 d-flex flex-row">
                    <button type="button" className="btn btn-sm text-primary" onClick={() => setShowAdvancedSearch(() => true)}><i className="fa-sharp fa-magnifying-glass-plus"></i></button>
                    <ResourceCloneTypeahead onSelected={(resource) => {
                      console.warn('resetting quick-add', resource);
                      onResourceSelected(resource);
                    }} />
                  </div>}
                  {translatedFrom && <div className="col-md-9 d-flex flex-row">
                    <div className="input-group mb-3">
                      <input type="text" value={translatedFrom.name} disabled={true} className="form-control" />
                      <button className="btn btn-outline-danger" type="button" id="button-addon1" onClick={() => setTranslatedFrom(undefined)}><i className="fa-solid fa-times"></i></button>
                    </div>
                    </div>}
                </div>}
                {fields?.map((field, index) => {
                  const attribute = attributes?.find(attrib => attrib.id === field.id);
                  if (attribute) {
                    //console.log('field', attribute.name, field);
                    return (<div key={field.id} className={"row mb-15px" + (attribute.isRequired ? " required" : "")}>
                      <label className="form-label col-form-label col-md-3">{attribute.name}</label>
                      <div className="col-md-9">
                        <AttributeValueEditor attribute={attribute} errors={errors} index={index} watch={watchAttributes} />
                      </div>
                    </div>
                    );
                  }
                  return <div key={field.id}></div>;
                }
                )}
              </PanelBody>
            </Panel>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary w-100px me-5px" disabled={isLoading} onClick={handleSubmit(onSubmit)}>Save</button>
        </Modal.Footer>
      </form>
    </FormProvider>
  </Modal>
    {props.entityType === EntityTypes.Resource && <Modal fullscreen={true} show={showAdvancedSearch} onHide={() => setShowAdvancedSearch(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Advanced Search</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RecordsList<Resource>
          defaultSearch={resourceSearchTerm}
          type={EntityTypes.Resource}
          filters={[
            { key: 'contains', label: 'Name Contains', api: (search: SearchProps) => api.resources.search({ ...search, active: true }) },
            { key: 'exact', label: 'Name Is Exactly', api: (search: SearchProps) => api.resources.search({ ...search, search: `"${search.search}"`, active: true }) },
            { key: 'startswith', label: 'Name Starts With', api: (search: SearchProps) => api.resources.search({ ...search, search: `"${search.search}`, active: true }) },
          ]}
          actions={(record) => <>
            <button className="btn btn-sm btn-outline-primary mx-1" onClick={() => { onResourceSelected(record); setShowAdvancedSearch(false); }}>Select {labels.resource.singular}</button>
          </>}
        />
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary w-100px me-5px" onClick={() => setShowAdvancedSearch(false)}>Cancel/Close</button>
      </Modal.Footer>
    </Modal>}
  </>
}