import React, { CSSProperties, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'bootstrap';
import { selectSession } from '../../store/sessionSlice';
import { useAppSelector } from '../../store/hooks';
import { ROUTE as AccountsScreenRoute } from "../../screens/accounts/accountsScreen";
import { ROUTE as AccountSettingsScreenRoute } from "../../screens/settings/settingsScreen";

export default function SidebarProfile() {
	const session = useAppSelector(selectSession);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		const myCollapse = document.getElementById('appSidebarProfileMenu')
		if (myCollapse) {
			var bsCollapse = new Collapse(myCollapse, { toggle: false })
			isOpen ? bsCollapse.show() : bsCollapse.hide()
		}
	});
	const handleProfileExpand = (e: React.MouseEvent) => {
		e.preventDefault();
		setIsOpen(!isOpen);
	};

	const menuProfileStyle = ():CSSProperties => {
		// if (session.account?.logoUrl) {
		// 	return {
		// 		backgroundImage: `url(${session.account.logoUrl})`
		// 	};
		// }
		return {
			backgroundImage: "url(/static/media/cover-sidebar-user.7519141823faf5388031.jpg)"
		};
	};

	return (
		<div className="menu">
			<div className={"menu-profile" + (isOpen ? " active" : "")}>
				<Link to="/" onClick={handleProfileExpand} className="menu-profile-link">
					<div className="menu-profile-cover with-shadow" style={menuProfileStyle()}></div>
					<div className="menu-profile-image">

					</div>
					<div className="menu-profile-info">
						<div className="d-flex align-items-center">
							<div className="flex-grow-1">
								{(session.fullName?.length ?? 0) > 1 ? session.fullName : session.email}
							</div>
							<div className="menu-caret ms-auto"></div>
						</div>
						<small>{session.roles?.join(", ")}</small>
					</div>
				</Link>
			</div>
			<div id="appSidebarProfileMenu" className="collapse">
				<div className="menu-item pt-5px">
					<Link to={AccountSettingsScreenRoute} className="menu-link">
						<div className="menu-icon"><i className="fa fa-cog"></i></div>
						<div className="menu-text">Settings</div>
					</Link>
				</div>
				<div className="menu-item pt-5px">
					<Link to={AccountsScreenRoute} className="menu-link">
						<div className="menu-icon"><i className="fa fa-refresh"></i></div>
						<div className="menu-text">Switch Accounts</div>
					</Link>
				</div>
				<div className="menu-divider m-0"></div>
			</div>
		</div>
	);
}
