import { Navigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { ROUTE as AccountsScreenRoute } from "./accounts/accountsScreen";

export const ROUTE = "/";
export const AbsoluteRoute = (accountId: String) => `/`;
export function HomeScreen() {
	const session = useAppSelector(state => state.session);

	// if (!session.account) {
	// 	// Redirect user to select an account screen
	// 	console.warn("account required");
	// 	return (<Navigate to={AccountsScreenRoute} replace />);
	// }

	return <Navigate to={'/todo'} replace />;
}