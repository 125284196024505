import EntityTypes from "../../models/entityTypes";
import useLabel from "../../util/useLabel";

type EntityTypeLabelProps = {
  entityType: EntityTypes;
  singlular?: boolean;
}

export default function EntityTypeLabel(props: EntityTypeLabelProps) {
  const labelValue = useLabel(props.entityType);
  return <>{props.singlular ? labelValue?.singular : labelValue?.plural}</>
}