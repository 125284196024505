import { Link, generatePath } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import { api } from "../../store/api";
import RecordsListScreen from "../recordsListScreen";
import useLabel from "../../util/useLabel";
import Review from "../../models/review";
import { format } from "../../helpers/format";
import { UnitEvaluationsDetailsRoute } from "./unitEvaluationsDetailsScreen";

export const UnitEvaluationsRoute = "/unitEvaluations";
export const UnitEvaluationsCreateRoute = "/unitEvaluations/create";

const filters = [
  { key: 'all', label: 'All', api: api.unitEvaluations.search },
  // { key: 'mine', label: 'Mine', api: (props: SearchProps) => api.unitEvaluations.search(props) },
  //{ key: 'recent', label: 'Recently Added', api: api.reviews.recent },
];

export default function UnitEvaluationsScreen() {
  const projectLabel = useLabel(EntityTypes.Project);
  const resourceLabel = useLabel(EntityTypes.Resource);
  const reviewerLabel = useLabel(EntityTypes.Reviewer);
  const tagLabel = useLabel(EntityTypes.Tag);
  return <RecordsListScreen
    canCreate={false}
    showName={false}
    type={EntityTypes.UnitEvaluation}
    filters={filters}
    sort={'created'}
    columns={[
      // {
      //   label: resourceLabel.singular,
      //   sortKey: 'resource',
      //   callback: (record: Review) => <td><Link to={generatePath(ResourceDetailsRoute, { id: `${record.resourceId}` })}>{record.resourceName}</Link></td>,
      // },
      // {
      //   label: projectLabel.singular,
      //   sortKey: 'project',
      //   callback: (record: Review) => <td><Link to={generatePath(ProjectDetailsRoute, { id: `${record.projectId}`} )}>{record.projectName}</Link></td>,
      // },
      // {
      //   label: reviewerLabel.singular,
      //   sortKey: 'user',
      //   callback: (record: Review) => <td>{record.userName}</td>,
      // },
      // {
      //   label: tagLabel.plural,
      //   sortKey: 'tags',
      //   headerClassNames: 'text-end',
      //   callback: (record: Review) => <td className="text-end">{format.number(record.tags?.length ?? 0)}</td>,
      // },
      {
        label: 'Created',
        sortKey: 'created',
        callback: (record: Review) => <td>{format.ago(record.createdUtc!)}</td>,
      },
      // {
      //   label: 'Grade',
      //   sortKey: 'grade',
      //   callback: (record: Review) => <td>{grades[record.grade ?? GradeTypes.NA]}</td>,
      //  },
    ]}
    actions={(record) => <>
      <Link key={`review-${record.id}`} className="btn btn-sm btn-outline-primary" to={{ pathname: generatePath(UnitEvaluationsDetailsRoute, { id: record.id!.toString() }) }}>Details</Link>
      {/* <Link key={`createReview-${record.id}`} className="btn btn-sm btn-outline-primary" to={{ pathname: generatePath(ReviewsCreateRoute), search: createSearchParams({ projectResourceId: `${record.id}` }).toString() }}>Start <EntityTypeLabel entityType={EntityTypes.Review} singlular /></Link> */}
    </>}
     />;
}