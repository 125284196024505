import { useCallback, useEffect, useState } from "react";
import { generatePath, Link, useLocation, useNavigate } from "react-router-dom"
import Attribute from "../../models/attribute";
import AttributeTypes from "../../models/attributeTypes";
import EntityTypes from "../../models/entityTypes";
import { api } from "../../store/api";
import { EditFieldRoute } from "./fieldScreen";
import EntityTypeLabel from "../../components/label/entityTypeLabel";
import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from "react-beautiful-dnd";
import { Panel, PanelBody } from "../../components/panel/panel";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchAttributes, selectConfig } from "../../store/configSlice";

export const ROUTE = "/settings/fields";
const tabs = [
	{ entity: EntityTypes.Partner, hash: '#Partner' },
	{ entity: EntityTypes.Resource, hash: '#Resource' },
	{ entity: EntityTypes.Project, hash: '#Project' },
	{ entity: EntityTypes.ProjectResource, hash: '#ProjectResource' },
	{ entity: EntityTypes.Contact, hash: '#Contact' },
	{ entity: EntityTypes.User, hash: '#User' },
	{ entity: EntityTypes.Review, hash: '#Review' },
	{ entity: EntityTypes.UnitEvaluation, hash: '#UnitEvaluation' },
];
export function SettingsFieldsScreen() {
	const navigate = useNavigate();
	const location = useLocation();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeTab, setTab] = useState<EntityTypes>();

	const [filteredAttributes, setFilteredAttributes] = useState<Attribute[]>();

	// const dispatch = useAppDispatch();
	//const config = useAppSelector(selectConfig);
	//const attributes = activeTab ? config.attributes[activeTab] : undefined;
	//useEffect(() => { if (activeTab) { dispatch(fetchAttributes(activeTab)); } }, [activeTab, dispatch]);

	useEffect(() => {
		// TODO filter attributes to the current tab!
		console.warn("should filter attributes");
		if (activeTab) {
			api.account.attributes.forEntity(activeTab, true).then((attributes) => {
				setFilteredAttributes(attributes);
			});
		}
		// setFilteredAttributes(attributes
		// 	?.filter(attrib => attrib.entity === activeTab)
		// 	?.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0))
		// 	);
	}, [activeTab]);

	useEffect(() => {
		const tab = tabs.find(tab => tab.hash === location.hash);
		console.log('location.hash', location.hash, tab);
		if (tab) {
			setTab(tab.entity);
		}
		else {
			// Default tab
			setTab(EntityTypes.Partner);
		}
	}, [location.hash]);

	function handleDrag(result: DropResult, provided: ResponderProvided): void {
		console.log('handleDrag', result);
		if (result.destination) {
			const newItems = [...filteredAttributes!];
			const [removed] = newItems.splice(result.source.index, 1);
			newItems.splice(result.destination.index, 0, removed);
			setFilteredAttributes(newItems);
			setIsLoading(true)
			api.account.attributes.sort(newItems!)
				.then((result) => {
					setIsLoading(false);
				})
				.catch((error) => {
					console.error(error);
				});
			//move(result.source.index, result.destination.index);
		}
	}

	return (
		<div>
			<ol className="breadcrumb float-xl-end">
				<li className="breadcrumb-item">Settings</li>
				<li className="breadcrumb-item active">Fields</li>
			</ol>

			<h1 className="page-header">Custom Fields</h1>
			<div className="row">
				<div className="card border-0">
					<div className="d-flex flex-row align-items-center mb-3 border-1 border-bottom">
						<ul className="nav nav-tabs nav-tabs-v2 px-3 border-0">
							{tabs.map(tab => <li className="nav-item me-2" key={tab.entity}>
								<a href={'#' + EntityTypes[tab.entity]} className={"nav-link px-2 " + (tab.entity === activeTab ? "active" : "")} onClick={() => setTab(tab.entity)}>
									<EntityTypeLabel entityType={tab.entity} />
								</a>
							</li>)}
						</ul>
						<div className="ms-auto">
							<Link to="create" className="btn btn-success btn-rounded px-4 rounded-pill"><i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Create Field</Link>
						</div>
					</div>
					<div className="tab-content p-3">
						<div className="tab-pane fade show active" id="activeTab">
							<Panel isLoading={isLoading} className="table-responsive mb-3">
								<PanelBody>
									{(filteredAttributes?.length ?? 0) === 0 && <p className="text-center">
										There are no custom fields for this type.
									</p>}
									{((filteredAttributes?.length ?? 0) > 0) && (
										<DragDropContext onDragEnd={handleDrag}>
											<Droppable droppableId="fields">
												{(provided, snapshot) => (
													<table className="table table-hover table-panel text-nowrap align-middle mb-0" ref={provided.innerRef} {...provided.droppableProps}>
														<thead>
															<tr>
																<th></th>
																<th>Name</th>
																<th>Type</th>
																<th>Required</th>
																<th>Active</th>
																<th></th>
															</tr>
														</thead>
														<tbody {...provided.droppableProps} ref={provided.innerRef}>
															{filteredAttributes?.map((attrib, index) => <Draggable key={`test[${index}]`}
																draggableId={`item-${index}`}
																index={index}>
																{(provided, snapshot) => <tr ref={provided.innerRef} {...provided.draggableProps}>
																	<td><div className="btn btn-sm"
																		{...provided.dragHandleProps}>
																		<i className="fa-solid fa-grip-dots-vertical"></i> {index + 1}
																	</div></td>
																	<td>{attrib.name}</td>
																	<td>{AttributeTypes[attrib.type]}</td>
																	<td>{attrib.isRequired ? 'Required' : 'Optional'}</td>
																	<td>{attrib.isActive ? <span>Active</span> : <span className="badge bg-danger">DISABLED</span>}</td>
																	<td>
																		<button className="btn btn-sm btn-outline-primary" onClick={() => navigate(generatePath(EditFieldRoute, { id: `${attrib.id}` }))}>Edit</button>
																	</td>
																</tr>}</Draggable>)}
															{provided.placeholder}
														</tbody>
													</table>)}
											</Droppable>
										</DragDropContext>
									)}
								</PanelBody>
							</Panel>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}