import { useEffect, useState } from "react";
import Project from "../../models/project";
import { api } from "../../store/api";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import { Link, generatePath, useNavigate } from "react-router-dom";
import { ProjectDetailsRoute } from "../../screens/projects/projectDetailsScreen";
import EntityTypes from "../../models/entityTypes";
import Attribute from "../../models/attribute";
import { AttributeValueSummary } from "../attribute/attributeValueSummary";

type ProjectListProps = {
  partnerId?: number;
  // suiteId?: string;
}

export function ProjectList(props: ProjectListProps) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const labels = useAppSelector(selectLabels);
  const [projects, setProjects] = useState<Project[] | undefined>();
  const [error, setError] = useState<String>();
  const [showColumnPartner, setShowColumnPartner] = useState(false);
  const [attributes, setAttributes] = useState<Attribute[] | undefined>(undefined);

  useEffect(() => {
    api.account.attributes.forEntity(EntityTypes.Project).then((attributes) => {
      setAttributes(attributes
        .filter((attrib) => attrib.isShownDefault && attrib.isActive)
      );
    });
  }, [labels.project.singular]);

  useEffect(() => {
    // Load the leases for the Customer Id
    if (props.partnerId) {
      setIsLoading(true);
      // setShowColumnPartner(true);
      api.projects.forPartner(props.partnerId!)
        .then((leases) => {
          setProjects(leases);
          setIsLoading(false);
        })
        .catch((reason) => {
          console.error(reason);
          setError("Unable to load " + labels.project.plural + ` for customer #${props.partnerId}`);
          setIsLoading(false);
        });
    }
    // else if (props.suiteId) {
    //   setIsLoading(true);
    //   setShowColumnCustomer(true);
    //   api.leases.forSuite(account!.id!, props.suiteId!)
    //     .then((leases) => {
    //       setProjects(leases);
    //       setIsLoading(false);
    //     })
    //     .catch((reason) => {
    //       console.error(reason);
    //       setError("Unable to load " + labels.project.plural + ` for suite #${props.suiteId}`);
    //       setIsLoading(false);
    //     });
    // }
  }, [labels.project.plural, props.partnerId]);

  if (isLoading) {
    return (<div className="widget-list-item">
      <div className="widget-list-content">
        Loading {labels.project.plural}...
      </div>
      <div className="widget-list-action">
        <span className="spinner spinner-sm"></span>
      </div>
    </div>);
  }
  if (error) {
    return (<div className="widget-list-item">
      <div className="widget-list-content">
        {error}
      </div>
      <div className="widget-list-action">

      </div>
    </div>);
  }
  if (projects?.length === 0) {
    return (<div className="widget-list-item">
      <div className="widget-list-content">
        There are no {labels.project.plural} associated with this {labels.partner.singular}.
      </div>
      <div className="widget-list-action">

      </div>
    </div>);
  }

  return (<div className="table-responsive">
    <table className="table table-hover table-panel text-nowrap align-middle mb-0">
      <thead>
        <tr>
          <th>Name</th>
          <th>Active?</th>
          {showColumnPartner && <th>{labels.partner.singular}</th>}
          {attributes?.map((attrib) => <th key={attrib.id}>{attrib.name}</th>)}
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {projects?.map((project) => <tr key={`project-${project.id}`}>
          <td><Link to={generatePath(ProjectDetailsRoute, { id: `${project.id}` } )}>{project.name}</Link></td>
          <td>{project?.isActive ? "Yes" : "No"}</td>
          {showColumnPartner && <td>{project.partner?.name}</td>}
          {attributes?.map((attrib) => <td key={attrib.id}><AttributeValueSummary attribute={attrib} attributeValues={project.attributes} /></td>)}
          <td>
            <button className="btn btn-sm btn-outline-primary me-1" onClick={() => navigate(generatePath(ProjectDetailsRoute, { id: `${project.id}` }))}>Details</button>
          </td>
          </tr>)}
      </tbody>
    </table>
  </div>
  );
}