import { useEffect } from "react";
export const useDocumentTitle = (title: String) => {

  useEffect(() => {
    document.title = `${title} - Knowledge Maps 2.0`;
  }, [title]);

  return null;
};

export const setTitle = (parts: (String | undefined)[]) => {
  document.title = [...parts, "Knowledge Maps 2.0"].filter(p => p !== null && p !== undefined && p.length > 0).join(' - ');
}