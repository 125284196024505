import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { toggleAppSidebarMinify } from '../../store/themeSlice';

export default function SidebarMinifyBtn() {
	const dispatch = useAppDispatch();
	return (
		<div className="menu">
			<div className="menu-item d-flex">
				<button className="app-sidebar-minify-btn ms-auto border-0" onClick={() => dispatch(toggleAppSidebarMinify())}>
					<i className="fa fa-angle-double-left"></i>
				</button>
			</div>
		</div>
	)
}
