import { Link, generatePath } from "react-router-dom";
import { TagEditRoute } from "../../screens/settings/tagEditScreen";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import Tag from "../../models/tag";
import KnowledgeMapTypes, { AllKnowledgeMapTypes } from "../../models/knowledgeMapTypes";
import { TagDetailsRoute } from "../../screens/settings/tagScreen";
import { TagExportRoute } from "../../screens/settings/tagExport";

export interface TagDetailsCardProps {
  record: Tag;
  isUserManaged: boolean;
  showExport?: boolean;
};

export default function TagDetailsCard(props: TagDetailsCardProps) {
  const labels = useAppSelector(selectLabels);
  const label = labels.tag;
  const editRoute = TagEditRoute;
  const { record, isUserManaged, showExport } = props;

  return (<div className="card border-0 mb-4">
    <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">
      {label.singular} Details
      {editRoute && <Link to={generatePath(editRoute, { id: `${record?.id}` })} className="ms-auto text-decoration-none text-gray-500"><i className="fa fa-edit fa-lg me-2 ms-n2"></i> Edit</Link>}
    </div>
    <div className="card-body fw-bold">
      {record?.label && <h6 className="card-subtitle text-muted">Label</h6>}
      {record?.label && <p className="card-text">
        {record?.label}
      </p>}
      <h6 className="card-subtitle text-muted">Status</h6>
      {record?.isActive ? <p className="card-text">Active</p> : <p className="card-text"><span className="badge bg-danger">DISABLED</span></p>}
      <h6 className="card-subtitle text-muted">Description</h6>
      <p className="card-text">
        {record.description}
      </p>
      <h6 className="card-subtitle text-muted">Knowledge Map Type(s)</h6>
      <div className="card-text mb-2">
        {AllKnowledgeMapTypes.filter((kmType) => (kmType & (record.knowledgeMapTypes ?? KnowledgeMapTypes.Undefined)) === kmType).map((kmType) => <div key={kmType}>{labels.knowledgeMapTypes[kmType]}</div>)}
      </div>
      {record?.parentTag?.label && <h6 className="card-subtitle text-muted">Parent {label.singular}</h6>}
      {record?.parentTag?.label && <p className="card-text">
        <Link to={generatePath(TagDetailsRoute, { id: `${record.parentTagId}` })}>{record?.parentTag?.label}</Link>
      </p>}
      {isUserManaged && <h6 className="card-subtitle text-muted">Is User Managed</h6>}
      {isUserManaged && <p className="card-text">Yes</p>}
    </div>
    {showExport && <div className="card-footer">
      <div className="d-flex flex-row justify-content-end">
        <Link to={generatePath(TagExportRoute, { id: `${record?.id}` })} className="ms-auto text-decoration-none"><i className="fa fa-download fa-lg me-2 ms-n2"></i> Export</Link>
      </div>
    </div>}
  </div>);
}