import React from "react";
import { Modal } from "react-bootstrap";
import {
  unstable_Blocker as Blocker,
  unstable_useBlocker as useBlocker,
  unstable_BlockerFunction as BlockerFunction,
} from "react-router-dom";

export const ConfirmNavigation = (props: { hasUnsavedChanges: boolean }) => {
//export function ConfirmNavigation({ blocker }: { blocker: Blocker }) {
  let shouldBlock = React.useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      console.log('shouldBlock?', currentLocation, nextLocation, props.hasUnsavedChanges);
      return props.hasUnsavedChanges && currentLocation.pathname !== nextLocation.pathname },
    [props.hasUnsavedChanges]
  );
  const blocker = useBlocker(shouldBlock);
  const isBlocked = (blocker.state === "blocked");
  
  // if (blocker.state === "proceeding") {
  //   return (
  //     <p style={{ color: "orange" }}>Proceeding through blocked navigation</p>
  //   );
  // }


  // return <p style={{ color: "green" }}>Blocker is currently unblocked</p>;
  return <Modal show={isBlocked} onHide={() => blocker.reset?.()} size="sm">
    <Modal.Header closeButton>
      <Modal.Title>Are you sure?</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Are you sure you want to leave this page without saving?
      </p>
      <div className="row">
        <div className="col text-center">
          <button className="btn btn-sm btn-warning mx-2" onClick={() => blocker.proceed?.()}>Leave without Saving</button>
          <button className="btn btn-sm btn-success mx-2" onClick={() => blocker.reset?.()}>Go Back</button>
        </div>
      </div>
    </Modal.Body>
    </Modal>;
}